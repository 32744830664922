import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const ACTIVITIES_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=Activities`;
const INPUTS_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=ActivityInputs`;
const OUTPUTS_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=ActivityOutputs`;
const GOALS_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=Goals`;
const ALL_INDICATORS_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=Indicators`;
const INDICATORS_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=OutcomeToIndicators`;

const initialState = {
  plan: [],
  status: 'idle',
  error: null
};

export const fetchActivities = createAsyncThunk('activities/fetchActivities', async () => {
  try {
    const response = await axios.get(ACTIVITIES_URL);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchInputs = createAsyncThunk('inputs/fetchInputs', async () => {
  try {
    const response = await axios.get(INPUTS_URL);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchOutputs = createAsyncThunk('outputs/fetchOutputs', async () => {
  try {
    const response = await axios.get(OUTPUTS_URL);
    // console.log('outputs res', response);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchGoals = createAsyncThunk('goals/fetchGoals', async () => {
  try {
    const response = await axios.get(GOALS_URL);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchAllIndicators = createAsyncThunk('allIndicators/fetchAllIndicators', async () => {
  try {
    const response = await axios.get(ALL_INDICATORS_URL);
    // console.log('response', response.data);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchIndicators = createAsyncThunk(
  'indicators/fetchIndicators',
  async (indicators) => {
    try {
      const response = await axios.get(INDICATORS_URL + `&codes=${indicators}`);
      // console.log('axios indicators', response.data);
      return response.data;
    } catch (err) {
      console.log(err.message);
      return err.message;
    }
  }
);

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchActivities.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.activities = action.payload;
        // return action.payload;
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchInputs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInputs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.inputs = action.payload;
        // return action.payload;
      })
      .addCase(fetchInputs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchOutputs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOutputs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.outputs = action.payload;
        // return action.payload;
      })
      .addCase(fetchOutputs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchGoals.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGoals.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.goals = action.payload;
        // return action.payload;
      })
      .addCase(fetchGoals.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchIndicators.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchIndicators.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.indicators = action.payload;
      })
      .addCase(fetchIndicators.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchAllIndicators.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllIndicators.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.allIndicators = action.payload;
        // return action.payload;
      })
      .addCase(fetchAllIndicators.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const selectAllActivities = (state) => state.plan.activities;
export const selectAllInputs = (state) => state.plan.inputs;
export const selectAllOutputs = (state) => state.plan.outputs;
// export const selectAllGoals = (state) => state.plan.goals;
export const allIndicators = (state) => state.plan.allIndicators;
export const selectAllIndicators = (state) => state.plan.indicators;
export const getPlanStatus = (state) => state.plan.status;
export const getError = (state) => state.plan.error;

export default planSlice.reducer;
