import React, { useState } from 'react';
import { Container, Grid, Paper } from '@mui/material';
import Search from '../../components/Search';
import Discover from '../../layouts/Discover';
import styles from './Search.module.scss';

function SearchProgram() {
  const [query, setQuery] = useState('');
  return (
    <Container maxWidth="lg">
      <Grid container spacing={4} mt={2} className={styles['search-wrapper']}>
        <Grid item xs={12}>
          <Paper className={styles['search-card']} elevation={2}>
            <Search query={query} setQuery={setQuery} />
          </Paper>
          <Discover />
        </Grid>
      </Grid>
    </Container>
  );
}

export default SearchProgram;
