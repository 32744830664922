import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, InputBase, Link, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import classes from './Search.module.scss';

function Search({ query, setQuery }) {
  return (
    <>
      <Typography variant="h2" component="h2">
        Search the repository
      </Typography>
      <Typography variant="body1" component="p">
        Search the repository of STEM equity program evaluations
      </Typography>
      <div className={classes['search-form-wrapper']}>
        <form className={classes['search-form']} method="GET" action="/search-programs">
          <InputBase
            id="search-bar"
            className={classes['search-field']}
            label="Search programs"
            variant="outlined"
            placeholder="Search..."
            size="small"
            name="SearchKey"
            fullWidth
            value={query}
            onInput={(e) => setQuery(e.target.value)}
          />
          <IconButton type="submit" aria-label="search" className={classes['search-btn']}>
            <SearchIcon color="primary" />
          </IconButton>
        </form>
        <Box className={classes['adv-btn']}>
          <Link href="/advanced-search">Advanced Search</Link>
        </Box>
      </div>
    </>
  );
}

Search.propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func
};

export default Search;
