import React, { useContext, useState } from 'react';
import { AuthContext } from '../../services/context/AuthProvider';
import { DialogContent, Grid, Button, Typography } from '@mui/material';
import Modal from '.';
import styles from './Modal.module.scss';

const SessionExpiredModal = () => {
  const { logout } = useContext(AuthContext);
  const [open, setOpen] = useState(true);

  const handleSubmit = () => {
    setOpen(false);
    logout();
  };

  return (
    <Modal open={open} onClose={handleSubmit}>
      <DialogContent sx={{ p: 5 }}>
        <Grid container maxWidth="xs">
          <Grid item xs={12} mb={2}>
            <Typography variant="h3" component="h2">
              Session expired!
            </Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              Your session has expired. Please login again.
            </Typography>
          </Grid>

          <Grid item xs={12} textAlign="right">
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={styles['btn']}
              sx={{ color: '#FFFFFF' }}>
              Ok
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default SessionExpiredModal;
