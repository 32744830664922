import React, {
  useState,
  useEffect
  // useContext
} from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  // CircularProgress,
  Container,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import styles from './Search.module.scss';
// import SearchForm from '../../components/Search/SearchForm';
import Search from '../../components/Search';
import EvaluationCard from '../../components/Card/EvaluationCard';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  getStatus,
  getFilteredEvaluations,
  fetchSearchEvaluations
} from '../../services/features/evaluations/evaluationsSlice';
import { useSelector, useDispatch } from 'react-redux';
// import { AuthContext } from '../../services/context/AuthProvider';

const SearchPrograms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const searchQuery = Object.fromEntries([...searchParams]);
  const [query, setQuery] = useState(searchQuery.SearchKey);

  // const { getSession } = useContext(AuthContext);
  // const [email, setEmail] = useState('');

  // useEffect(() => {
  //   getSession().then((data) => {
  //     // setEmail(data.email);
  //   });
  // }, []);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const evaluationStatus = useSelector(getStatus);
  const evaluations = useSelector(getFilteredEvaluations);
  const [evalId, setEvalId] = useState();
  const [bookmarks, setBookmarks] = useState(JSON?.parse(localStorage?.getItem('bookmarks')) || []);

  useEffect(() => {
    if (evaluationStatus === 'idle') {
      dispatch(fetchSearchEvaluations(searchQuery));
    }
  }, [evaluationStatus, dispatch]);

  const toggleDrawer = (anchor, open, evalId) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setEvalId(evalId);
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if (localStorage.getItem('bookmarks') !== undefined) {
      localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
    }
  }, [bookmarks, evaluations]);

  const handleBookmark = (evalId) => () => {
    const selectedEvaluation = evaluations?.find((item) => {
      return item?.UID === evalId;
    });

    setBookmarks([...bookmarks, selectedEvaluation]);
  };

  const removeBookmark = (evalId) => () => {
    const selectedEvaluation = evaluations?.find((item) => {
      return item?.UID === evalId;
    });

    const filteredBookmark = bookmarks.filter((bookmark) => {
      return bookmark.UID !== selectedEvaluation.UID;
    });
    if (localStorage.getItem('bookmarks') !== undefined) {
      localStorage.setItem('bookmarks', JSON.stringify(filteredBookmark));
    }

    setBookmarks(filteredBookmark);
  };

  const list = (anchor) => {
    const selectedEvaluation = evaluations?.find((item) => {
      return item?.UID === evalId;
    });

    // const selectedEmail = evaluations?.find((item) => {
    //   console;
    //   return item?.Email === email && item?.UID === evalId;
    // });

    const programAudiences =
      selectedEvaluation?.ProgramAudiences &&
      selectedEvaluation?.ProgramAudiences.map((item) => item).join('; ');

    const evaluationAudiences =
      selectedEvaluation?.EvaluationAudiences &&
      selectedEvaluation?.EvaluationAudiences.map((item) => item).join('; ');

    const programGoals =
      selectedEvaluation?.Goals &&
      selectedEvaluation?.Goals.map((item, index) => <li key={index}>{item?.Goal}</li>);

    const programIndicators =
      selectedEvaluation?.Goals &&
      selectedEvaluation?.Goals &&
      selectedEvaluation?.Goals.map((item) =>
        item?.Indicators.map((indicators, index) => <li key={index}>{indicators.Indicator}</li>)
      );

    const programInstruments =
      selectedEvaluation?.Goals &&
      selectedEvaluation?.Goals &&
      selectedEvaluation?.Goals.map((item) =>
        item?.Indicators?.map((indicators) =>
          indicators.Instruments?.map((instrument) => {
            return instrument.Instruments;
          })
        )
      );

    const mergedInstruments = programInstruments?.flat(2);
    const uniqueInstruments = [...new Set(mergedInstruments)];

    const relatedPrograms = filteredData.filter((item) => {
      const goalIds = item.Goals.map((goals) => goals.Code);
      const selectedGoals = selectedEvaluation?.Goals.map((goals) => goals.Code);

      return (
        item.UID !== selectedEvaluation?.UID &&
        goalIds?.some((value) => selectedGoals?.indexOf(value) >= 0)
      );
    });

    return (
      <Box
        // key={selectedEvaluation.UID}
        className={styles['evaluation-details-wrapper']}
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 700 }}
        role="presentation"
        onClick={toggleDrawer('right', false)}
        onKeyDown={toggleDrawer('right', false)}>
        <CloseIcon onClick={toggleDrawer('right', false)} className={styles['close-drawer']} />
        <Box className={styles['evaluation-header']}>
          {selectedEvaluation?.ProgressLevel && (
            <Typography
              variant="body1"
              component="p"
              className={
                styles[
                  `${
                    selectedEvaluation?.ProgressLevel === 'In Progress' ? 'incomplete' : 'complete'
                  }`
                ]
              }>
              <span className={styles.indicator}></span>{' '}
              <span>{selectedEvaluation?.ProgressLevel}</span>
            </Typography>
          )}
          {selectedEvaluation?.SSP && (
            <Typography variant="body1" component="p" className={styles['ssp']}>
              <span className={styles.indicator}></span> <span>SSP</span>
            </Typography>
          )}
          <Typography variant="h2" component="h2">
            {selectedEvaluation?.ProgramName}
          </Typography>
        </Box>

        <Box className={styles['evaluation-details']}>
          <Typography variant="body1" component="p">
            <strong>Organisation:</strong> {selectedEvaluation?.Organisation}
          </Typography>
          <Typography variant="body1" component="p">
            <strong>Program Audiences:</strong>{' '}
            {programAudiences ? programAudiences : 'Not available'}
          </Typography>

          <Typography variant="body" component="p">
            <strong>Evaluation Audiences:</strong>{' '}
            {evaluationAudiences ? evaluationAudiences : 'Not available'}
          </Typography>

          <Typography variant="body" component="p">
            <strong>Scale:</strong>{' '}
            {selectedEvaluation?.ProgramScale ? selectedEvaluation?.ProgramScale : 'Not available'}
          </Typography>

          <Typography variant="h4" component="h3" mt={3}>
            Outcomes
          </Typography>
          {selectedEvaluation?.DefineVisibility === true ? (
            programGoals.length !== 0 ? (
              <ul>{programGoals}</ul>
            ) : (
              <Typography variant="body1" component="p" mb={4}>
                Not available.
              </Typography>
            )
          ) : (
            <Typography variant="body1" component="p" mb={4}>
              Outcomes are not publicly available.
            </Typography>
          )}

          <Typography variant="h4" component="h3" mt={3}>
            Indicators
          </Typography>
          {selectedEvaluation?.PlanVisibility === true ? (
            programIndicators[0]?.length !== 0 ? (
              <ul>{programIndicators}</ul>
            ) : (
              <Typography variant="body1" component="p" mb={4}>
                Not available.
              </Typography>
            )
          ) : (
            <Typography variant="body1" component="p" mb={4}>
              Indicators are not publicly available.
            </Typography>
          )}

          <Typography variant="h4" component="h3" mt={3}>
            Instruments
          </Typography>
          {selectedEvaluation?.DesignVisibility === true ? (
            uniqueInstruments?.[0]?.length !== 0 && uniqueInstruments?.[0]?.length !== 0 ? (
              <ul>
                {uniqueInstruments.map((instrument, index) => (
                  <li key={index}>{instrument}</li>
                ))}
              </ul>
            ) : (
              <Typography variant="body1" component="p" mb={4}>
                Not available.
              </Typography>
            )
          ) : (
            <Typography variant="body1" component="p" mb={4}>
              Instruments are not publicly available.
            </Typography>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h4" component="h3" mt={3}>
                Program description
              </Typography>
              <Typography variant="body" component="p">
                {selectedEvaluation?.ProgramDescription
                  ? selectedEvaluation?.ProgramDescription
                  : 'Not available'}
              </Typography>
              <Typography variant="h4" component="h3" mt={3}>
                Findings Summary
              </Typography>
              {selectedEvaluation?.ShareVisibility === true ? (
                <Typography variant="body" component="p">
                  {selectedEvaluation?.ExecutiveSummary &&
                  selectedEvaluation?.ProgressLevel === 'Complete'
                    ? selectedEvaluation?.ExecutiveSummary
                    : 'This evaluation is in progress and findings are not yet available.'}
                </Typography>
              ) : (
                <Typography variant="body1" component="p">
                  Findings Summary is not publicly available
                </Typography>
              )}

              <Box className={styles['btn-action']}>
                {selectedEvaluation?.Email !== undefined && (
                  <Button
                    variant="contained"
                    component="a"
                    rel="noopener noreferrer"
                    href={`mailto:${selectedEvaluation?.Email}`}>
                    Contact us
                  </Button>
                )}

                <Button
                  variant="contained"
                  component={Link}
                  to={`/program/${selectedEvaluation?.UID}`}>
                  Read full evaluation <ArrowForwardIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={styles['related-programs']}>
                <Typography variant="h4" component="h3" my={3}>
                  Related Programs
                </Typography>
                {relatedPrograms?.length !== 0 ? (
                  relatedPrograms?.slice(0, 3).map((related, index) => {
                    const goals = related.Goals.map((item) => item.Goal);
                    return (
                      <EvaluationCard
                        key={index}
                        organisation={related.Organisation}
                        title={related?.ProgramName}
                        outcomes={goals.join('; ')}
                        description={related?.ProgramDescription}
                        readmore
                        onClick={() => navigate(`/program/${related.UID}`)}
                      />
                    );
                  })
                ) : (
                  <Typography variant="body" component="p" pl={1}>
                    There are no related programs.
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const [filteredData, setFilteredData] = useState([]);
  const [sortType, setSortType] = useState('latestPrograms');

  useEffect(() => {
    const filteredEvaluations = evaluations?.filter((evaluation) => evaluation?.Published === true);
    setFilteredData(filteredEvaluations);
  }, [evaluations]);

  const publishedEvaluations =
    filteredData && filteredData.filter((evaluation) => evaluation.Published === true);

  const deletedEvaluations = bookmarks?.filter(
    (evaluation) => !publishedEvaluations?.find((item) => evaluation.UID === item.UID)
  );

  const newBookmarks = bookmarks?.filter(
    (evaluation) =>
      !deletedEvaluations?.find((item) => {
        return evaluation.UID === item.UID;
      })
  );

  if (newBookmarks.length > 0) {
    localStorage.setItem('bookmarks', JSON.stringify(newBookmarks));
  }

  useEffect(() => {
    const sortArray = (type) => {
      const types = {
        latestPrograms: 'latestPrograms',
        ascending: 'ascending',
        descending: 'descending'
      };

      const sortProperty = types[type];

      const sorted =
        filteredData &&
        [...filteredData].sort((a, b) => {
          if (sortProperty === 'ascending') {
            return a.Organisation < b.Organisation ? -1 : 1;
          }

          if (sortProperty === 'descending') {
            return a.Organisation < b.Organisation ? 1 : -1;
          }
          if (sortProperty === 'latestPrograms') {
            const keyA = new Date(a.DateCreated).toLocaleDateString();
            const keyB = new Date(b.DateCreated).toLocaleDateString();

            // Compare the 2 dates
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          }
        });
      setFilteredData(sorted);
    };

    sortArray(sortType);
  }, [sortType]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4} mt={2}>
        <Grid item xs={12} pb={4}>
          <Box className={styles['search-form-wrapper']}>
            {/* <SearchForm searchFilters className={styles['search-filters']} /> */}
            <Search query={query} setQuery={setQuery} />

            <Box className={`${styles['search-filters']}`}>
              <Typography variant="body" component="p">
                {filteredData?.length} results
              </Typography>
              <FormControl className={styles['filter']} size="small">
                <InputLabel id="sort-filter">Sort by</InputLabel>
                <Select
                  labelId="sort-filter"
                  id="program-sort-filters"
                  label="Sort by"
                  value={sortType}
                  onChange={(e) => setSortType(e.target.value)}>
                  <MenuItem value="latestPrograms">Most recent first</MenuItem>
                  <MenuItem value="ascending">Organisation: A - Z</MenuItem>
                  <MenuItem value="descending">Organisation: Z - A</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          {filteredData.length > 0 ? (
            <Box className={styles['search-results']}>
              {(filteredData && Object.values(filteredData))?.map((item, index) => {
                const isFound = bookmarks?.some((element) => {
                  if (element.UID === item?.UID) {
                    return true;
                  }
                  return false;
                });

                const goals = item?.Goals.map((goal) => goal.Goal).join('; ');

                return (
                  <EvaluationCard
                    id={item?.UID}
                    key={index}
                    title={item?.ProgramName}
                    status={item?.ProgressLevel}
                    organisation={item?.Organisation}
                    outcomes={
                      item.DefineVisibility === true
                        ? goals.toString()
                        : 'Outcomes are not publicly available'
                    }
                    description={item?.ProgramDescription}
                    bookmark
                    readmore
                    ssp={item?.SSP}
                    time
                    onClick={toggleDrawer('right', true, item?.UID)}
                    handleBookmark={isFound ? removeBookmark(item?.UID) : handleBookmark(item?.UID)}
                    btnText={isFound ? 'Remove bookmark' : 'Bookmark'}
                    color={!isFound ? 'primary' : 'error'}
                  />
                );
              })}
            </Box>
          ) : (
            <>
              <Box className={styles['loader']}>
                {/* <CircularProgress /> */}
                <Typography>No results found.</Typography>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <Drawer anchor="right" open={state['right']} onClose={toggleDrawer('right', false)}>
        {list('right', evalId)}
      </Drawer>
    </Container>
  );
};

export default SearchPrograms;
