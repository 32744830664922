import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import Modal from '.';
import styles from './Modal.module.scss';

const UnpublishModalModal = ({ open, setOpen, setFormData, formData, onClick, loading }) => {
  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFormData(formData);
  }, [formData]);

  return (
    <Modal open={open} onClose={closeModal}>
      <Dialog
        maxWidth="md"
        onClose={closeModal}
        open={open}
        aria-labelledby="modal-wrapper"
        className={styles['modal-wrapper']}
        // TransitionComponent={Transition}
        transitionDuration={500}>
        <DialogTitle id="responsive-dialog-title">Unpublish evaluation</DialogTitle>
        <DialogContent sx={{ p: 5, pb: 0 }}>
          <Grid container maxWidth="xs">
            <Grid item xs={12} mb={2}>
              {!loading ? (
                <Typography>Are you sure you want to unpublish your evaluation?</Typography>
              ) : (
                <Box className={styles['loading']}>
                  <Typography>This may take a while to unpublish your evaluation.</Typography>
                  <CircularProgress />
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={styles['modal-footer']}>
          <Button
            variant="contained"
            type="submit"
            onClick={onClick}
            className={styles['btn']}
            sx={{ color: '#FFFFFF' }}>
            Yes
          </Button>
          <Button
            variant="outlined"
            type="submit"
            color="error"
            className={styles['outlined-error-btn']}
            onClick={closeModal}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Modal>
  );
};

UnpublishModalModal.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};

export default UnpublishModalModal;
