import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { Box, Button, DialogContent, Grid, Link, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import classes from './Modal.module.scss';

const tooltip1 = (
  <>
    <Typography variant="body1" component="p" className={classes['tooltip-link']}>
      Creating an evaluation uses a simple 5-stage process. Read about it.
    </Typography>
  </>
);

const tooltip2 = (
  <>
    <strong>Don’t have an account?</strong> It only takes 5 minutes to create an account and
    profile. Then you can start evaluating your programs.
  </>
);

const LoginModal = () => {
  // const navigate = useNavigate();

  return (
    <DialogContent className={classes['modal-content']} sx={{ p: 5 }}>
      <Grid container maxWidth="md" spacing={6} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h2" component="h2" className={classes['modal-title']}>
            Log in to create or modify evaluations.
          </Typography>
          <Typography variant="body1" component="p" className={classes['modal-body']}>
            If you don’t have an account yet, create one to start evaluating your programs.
          </Typography>
          <Box className={classes.link}>
            <Link href="https://womeninstem.org.au/national-evaluation-guide/" target="_blank">
              What&apos;s involved in an evaluation?
            </Link>
            <Tooltip title={tooltip1} className={classes.tooltip}>
              <InfoOutlinedIcon sx={{ ml: 1 }} color="primary" />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} justifyContent="center">
          <Box className="">
            <Button
              variant="contained"
              component={Link}
              href="/login"
              fullWidth
              className={classes['btn']}>
              Log in
            </Button>
            <Link href="/register" component="a" className={classes['btn-link']}>
              Create an account
              <Tooltip title={tooltip2} className={classes.tooltip}>
                <InfoOutlinedIcon sx={{ ml: 1 }} color="primary" />
              </Tooltip>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

LoginModal.propTypes = {};

export default LoginModal;
