import React, { useContext, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Tabs,
  Tab,
  Typography
} from '@mui/material';
import evaluationBannerMobile from '../../assets/images/evaluation-banner-mobile.jpg';
import evaluatingBenefitsImage from '../../assets/images/BenefitsOfEvaluating.png';
import publishingBenifitsImage from '../../assets/images/BenefitsOfPublishing.png';
import styles from './EvaluatePrograms.module.scss';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../services/context/AuthProvider';
import TabPanel from '../../components/Tabs/TabPanel';
import YoutubeEmbed from '../../components/Video/YoutubeEmbed';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Discover from '../../layouts/Discover';
import Modal from '../../components/Modal';
import LoginModal from '../../components/Modal/LoginModal';

const EvaluatePrograms = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openModal, setOpenModal] = useState(false);
  // const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <>
      <Box className={styles['banner-wrapper']}>
        <img
          className={styles['mobile-banner']}
          src={evaluationBannerMobile}
          alt="Evaluation portal"
        />
        <Container maxWidth="lg">
          <Grid container spacing={{ md: 0, lg: 4 }} mt={2} className={styles['banner-container']}>
            <Grid item sm={12} md={7}>
              <Typography variant="h2" component="h1">
                Evaluations for your organisation
              </Typography>
              <Typography variant="body2" component="p">
                Learn whether the Evaluation and Reporting tool is right for you.
              </Typography>
              {isAuthenticated ? (
                <Button
                  variant="contained"
                  to="/dashboard"
                  color="primary"
                  size="medium"
                  component={Link}
                  className={styles['evaluation-btn']}
                  disableElevation
                  reloadDocument
                  sx={{
                    mt: 3
                  }}>
                  Begin evaluating your programs
                </Button>
              ) : (
                <Button
                  variant="contained"
                  // onClick={handleOpen}
                  color="primary"
                  size="medium"
                  className={styles['evaluation-btn']}
                  disableElevation
                  data-modal="signin-option"
                  sx={{
                    mt: 3
                  }}>
                  Begin evaluating your programs
                </Button>
              )}
            </Grid>
            {/* <Grid item sm={12} md={5}>
              <img src={bannerImage} />
            </Grid> */}
          </Grid>
        </Container>
      </Box>

      <Box container spacing={4} className={styles['benefits-wrapper']}>
        <Container maxWidth="lg">
          <Grid container item xs={12} spacing={4}>
            <Grid item sm={12} md={6}>
              <Paper className={styles['benefit-item']}>
                <Box className={styles['benefit-info']}>
                  <Typography variant="h3" component="h2" mb={2}>
                    Benefits of evaluating your program
                  </Typography>
                  <img src={evaluatingBenefitsImage} />
                  <Typography variant="body" component="p" mt={2}>
                    By evaluating programs, you can:
                  </Typography>
                  <List component="ol">
                    <ListItem>Understand if your actions are working to create change</ListItem>
                    <ListItem>
                      Bring accountability, transparency and demonstrable impact to your efforts
                    </ListItem>
                    <ListItem>Use evidence to inform your efforts toward equity</ListItem>
                    <ListItem> </ListItem>
                    <ListItem> </ListItem>
                  </List>
                  <Button
                    variant="outlined"
                    to="/evaluate-programs/benefits-of-evaluating-programs"
                    color="primary"
                    size="medium"
                    component={Link}
                    className={styles['benefit-btn']}
                    disableElevation
                    sx={{
                      mt: 3
                    }}>
                    Read more
                  </Button>
                </Box>
              </Paper>
            </Grid>
            <Grid item sm={12} md={6}>
              <Paper className={styles['benefit-item']}>
                <Box className={styles['benefit-info']}>
                  <Typography variant="h3" component="h2" mb={2}>
                    Benefits of publishing your evaluation
                  </Typography>
                  <img src={publishingBenifitsImage} />
                  <Typography variant="body" component="p" mt={2}>
                    By publishing your evaluations, you can:
                  </Typography>
                  <List component="ol">
                    <ListItem>Share your evaluations publicly for others to learn from</ListItem>
                    <ListItem>Contribute evidence of what works to improve equity in STEM</ListItem>
                    <ListItem>
                      Bring accountability, transparency and demonstrable impact to your efforts{' '}
                    </ListItem>
                  </List>
                  <Button
                    variant="outlined"
                    to="/evaluate-programs/benefits-of-publishing-programs"
                    color="primary"
                    size="medium"
                    component={Link}
                    className={styles['benefit-btn']}
                    disableElevation
                    sx={{
                      mt: 3
                    }}>
                    Read more
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" className={styles['tab-wrapper']}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h2" component="h2">
              Explore the basics
            </Typography>
            <Box className={styles['tab-container']}>
              <Tabs value={value} onChange={handleChange} className={styles.tabs}>
                <Tab label="What is evaluation?" />
                <Tab label="Using the evaluation portal" />
                <Tab label="Benefits and value" />
                <Tab label="Example evaluations" />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Typography variant="body" component="p" mb={2}>
                  Evaluation is the collection and analysis of information to assess a
                  program&apos;s strengths, weaknesses and effectiveness to understand what works or
                  doesn&apos;t, how, for whom and why.
                </Typography>
                <Typography variant="body" component="p">
                  Evaluation is not something that happens at the end of the program. It is an
                  integral part of the program and embedded within it from the beginning.
                </Typography>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body" component="p">
                      The Portal has two functions.
                    </Typography>
                    <ol>
                      <li>to search and discover programs and evaluations</li>
                      <li>to evaluate your own equity programs</li>
                    </ol>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography variant="body" component="h4">
                          Searching and discovering programs and evaluations
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body" component="p">
                          Type relevant words in the search bar, such as &apos;workplace
                          culture&apos;, &apos;pay equity&apos;, &apos;primary school program&apos;
                          and &apos;mentorship program&apos;. There&apos;s also an option to filter
                          using the available options. You can view your search results and bookmark
                          program evaluations of interest. The Portal also allows you to connect via
                          email with people running programs.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography variant="body" component="h4">
                          Evaluating your program(s)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body" component="p" mb={2}>
                          To evaluate your program(s), you must create a user profile by entering
                          your details when prompted. After you have pressed &apos;create&apos;, you
                          will receive an email with instructions to verify your identity.
                        </Typography>
                        <Typography variant="body" component="p" mb={2}>
                          After verifying your identity, you can log in to your dashboard and start
                          evaluating your program.
                        </Typography>
                        <Typography variant="body" component="p" mb={2}>
                          Program evaluation is a simple five-step process.
                        </Typography>
                        <Typography variant="body" component="h4">
                          Stage 1
                        </Typography>
                        <Typography variant="body" component="p" mb={2}>
                          The Define stage sets the scene for your program and its evaluation. It
                          prompts you to ask yourself what you want to achieve, why and for whom.
                        </Typography>
                        <Typography variant="body" component="p" mb={2}>
                          Select the options that apply to your program from the dropdown menus. You
                          can also add detail in the text boxes if you want. Once you have filled
                          out all the sections, click next to move to the next stage.
                        </Typography>
                        <Typography variant="body" component="h4">
                          Stage 2
                        </Typography>
                        <Typography variant="body" component="p" mb={2}>
                          The Plan stage builds on the previous step. During this stage, you will
                          use the options available in the dropdown menus to identify the program
                          activities, choose the questions you want your evaluation to answer and
                          decide how to measure your goals.
                        </Typography>
                        <Typography variant="body" component="h4">
                          Stage 3
                        </Typography>
                        <Typography variant="body" component="p" mb={2}>
                          In the Design stage, you choose the type of data you will collect, when
                          and how you will collect it, and how you will store it. This stage will
                          help you choose the right data collection tools based on the goals you
                          selected from the dropdown menus.
                        </Typography>
                        <Typography variant="body" component="h4">
                          Stage 4
                        </Typography>
                        <Typography variant="body" component="p" mb={2}>
                          With your program and evaluation planned and designed, it&apos;s time to
                          put it into action. The Execute stage involves collecting data and
                          analysing it to give it meaning. This step also guides you through how to
                          carry out your evaluation ethically.
                        </Typography>
                        <Typography variant="body" component="h4">
                          Stage 5
                        </Typography>
                        <Typography variant="body" component="p" mb={2}>
                          Finally, it&apos;s time to Share. Here, you can report on the findings of
                          your evaluation. Reflect on what worked, what didn&apos;t, and how to
                          improve future programs. Then, you can publish your evaluation on the
                          Portal for others to learn from. Find out more about the{' '}
                          <a href="/evaluate-programs/benefits-of-publishing-programs">
                            benefits of sharing.
                          </a>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <YoutubeEmbed embedId="rzpjnqgcSsQ" />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Typography variant="body" component="p" mb={2}>
                  If you are serious about creating change, you need to know if your actions are
                  working. The best way to achieve this is through data-driven accountability. Here
                  are six reasons to evaluate.
                </Typography>
                <ol>
                  <li>
                    <strong>Measure project outcomes.</strong> Evaluation is essential to gauge the
                    effectiveness of your program. It helps you know whether your program is doing
                    what it is meant to do.
                  </li>
                  <li>
                    <strong>Use resources efficiently.</strong> Evaluation allows you to justify and
                    account for your program resources and investment. Knowing what works and does
                    not work helps you use resources efficiently.
                  </li>
                  <li>
                    <strong>Attract and keep funding and partners.</strong> Transparently sharing
                    your evaluation can help you attract and keep sponsors and partners. Results
                    show accountability and demonstrate value and worth.
                  </li>
                  <li>
                    <strong>Improve and scale-up.</strong> Evaluation allows you to refine your
                    program to improve it. For Government and sponsors, it informs decisions on what
                    programs should be extended or scaled up.
                  </li>
                  <li>
                    <strong>Generate useful data.</strong> Evaluations can help us understand the
                    issues that affect girls and women in STEM. Evaluation data over time allows us
                    to learn what programs work, for whom and why.
                  </li>
                  <li>
                    <strong>Promote best practice and collaboration.</strong> Evaluation can inform
                    and guide best practice for STEM equity programs.
                  </li>
                </ol>
                Read more{' '}
                <a href="/evaluate-programs/benefits-of-evaluating-programs">
                  benefits of evaluation
                </a>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Typography variant="body" component="p" mb={2}>
                  Take a look at real examples of some of Australia’s STEM equity programs
                  evaluations. They outline the entire process—from the evaluation plans to the
                  completed reports. Each of the examples applies the 5-stages of the Portal:
                  Define, Plan, Design, Execute and Share.
                </Typography>

                <a
                  href="https://womeninstem.org.au/examples-of-program-evaluations/"
                  target="_blank"
                  rel="noreferrer">
                  Example evaluations
                </a>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box className={styles['cta-wrapper']}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Paper className={styles['cta-container']}>
                <Typography variant="h2" component="h2">
                  Understand the process?
                </Typography>
                <Typography variant="body" component="p">
                  Now that you understand the simple 5-step evaluation process, get started
                  evaluating your program.
                </Typography>

                {isAuthenticated ? (
                  <Button
                    variant="contained"
                    to="/evaluation"
                    color="primary"
                    size="medium"
                    component={Link}
                    className={styles['evaluation-btn']}
                    disableElevation
                    sx={{
                      mt: 3
                    }}>
                    Begin evaluating
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    // onClick={handleOpen}
                    color="primary"
                    size="medium"
                    className={styles['evaluation-btn']}
                    disableElevation
                    data-modal="signin-option"
                    sx={{
                      mt: 3
                    }}>
                    Begin evaluating
                  </Button>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Discover />
      </Container>
      <Modal size="md" open={openModal} onClose={handleClose} onClick={handleClose}>
        <LoginModal />
      </Modal>
    </>
  );
};

export default EvaluatePrograms;
