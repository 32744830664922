import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Resources from '../../components/Resources';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import styles from './Evaluation.module.scss';

const ProgramOverview = ({ control, errors, formData, handleFieldChange }) => {
  const tooltip = (
    <p className={styles['tooltip']}>
      The Smoothing STEM Pathways initiative challenges Australian STEM employers to implement
      focused workplace equity programs, measure impact and share outcomes publicly using the STEM
      Equity Evaluation Portal.{' '}
      <a href="https://womeninstem.org.au/" target="_blank" rel="noreferrer">
        Read more about the Smoothing STEM Pathways initiative
      </a>
    </p>
  );

  return (
    <Box>
      <Paper elevation={2} sx={{ p: 4, mb: 5 }}>
        <Typography variant="h3" component="h2" color="primary" mb={4}>
          Program Overview
        </Typography>

        {Object.values(errors).length !== 0 && (
          <Alert variant="filled" severity="error" sx={{ mb: 3 }}>
            Please fill out all required fields.
          </Alert>
        )}

        <Typography variant="subtitle" component="p" mb={2} sx={{ fontSize: '14px' }}>
          * Required information
        </Typography>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ mb: 0 }}>
            <Box component="div">
              <Typography variant="h4" component="h3">
                Program name *
              </Typography>
              <Typography variant="body2" component="p">
                Maximum of 60 characters
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              control={control}
              name="ProgramName"
              defaultValue={formData?.ProgramName}
              rules={{
                required: 'Program name is required',
                maxLength: {
                  value: 60,
                  message: 'Program name should not be greater than 60 characters.'
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small">
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleFieldChange(e, field);
                      field.onChange(e);
                    }}
                    placeholder="Program name"
                    size="small"
                    fullWidth
                    inputProps={{
                      maxLength: 60
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                    required
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Program scale *
              </Typography>
              <Typography variant="body2" component="p">
                Geographical reach
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name="ProgramScale"
              control={control}
              defaultValue={formData?.ProgramScale}
              rules={{ required: 'Program scale is required.' }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <FormControl fullWidth size="small">
                    <InputLabel id="program-scale-label">Program Scale</InputLabel>
                    <Select
                      {...field}
                      value={field.value || ''}
                      onChange={(e) => {
                        handleFieldChange(e, field);
                        field.onChange(e);
                      }}
                      labelId="program-scale-label"
                      id="program-scale"
                      label="Program Scale"
                      renderValue={(selected) => selected}
                      error={!!error}
                      helpertext={error ? error.message : null}>
                      <MenuItem value="Local">Local</MenuItem>
                      <MenuItem value="Regional">Regional (state/territory/province)</MenuItem>
                      <MenuItem value="National">National</MenuItem>
                      <MenuItem value="Global">Global</MenuItem>
                    </Select>
                    {error && <FormHelperText error>Program scale is required.</FormHelperText>}
                  </FormControl>
                </>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={{ mb: 0 }}>
            <Box component="div">
              <Typography variant="h4" component="h3">
                Sponsors / funders / program partners *
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              control={control}
              name="Sponsors"
              defaultValue={formData?.Sponsors}
              rules={{
                required: 'Sponsors is required'
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small">
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleFieldChange(e, field);
                      field.onChange(e);
                    }}
                    multiline
                    rows={4}
                    placeholder="Please enter Sponsors / funders / program partners"
                    size="small"
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                    required
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
            sx={{ mb: 0 }}>
            <Box component="div">
              <Typography variant="h4" component="h3">
                Program description *
              </Typography>
              <Typography variant="body2" component="p">
                Maximum of 150 words
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              control={control}
              name="ProgramDescription"
              defaultValue={formData?.ProgramDescription}
              rules={{
                required: 'Program description is required'
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small">
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleFieldChange(e, field);
                      field.onChange(e);
                    }}
                    multiline
                    rows={4}
                    placeholder="Program description"
                    size="small"
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                    required
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Controller
          name="SSP"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              className={styles['ssp']}
              sx={{ mt: 2, mr: 0 }}
              control={
                <Checkbox
                  {...field}
                  checked={field.value}
                  value={field.value}
                  name="SSP"
                  label={'This program is part of the Smoothing STEM Pathways initiative'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="This program is part of the Smoothing STEM Pathways initiative"
            />
          )}
        />

        {/* <FormControlLabel
          name="SSP"
          className={styles['ssp']}
          sx={{ mt: 2, mr: 0 }}
          control={
            <Checkbox
              name="SSP"
              checked={formData.SSP}
              value={formData.SSP}
              onChange={handleCheckboxChange}
              label={'This program is part of the Smoothing STEM Pathways initiative'}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label="This program is part of the Smoothing STEM Pathways initiative"
        /> */}
        <Tooltip disableFocusListener title={tooltip} sx={{ mt: 2 }}>
          <IconButton sx={{ mt: 2 }}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Paper>
      <Paper elevation={2} sx={{ p: 4, mb: 5 }}>
        <Resources />
      </Paper>
    </Box>
  );
};

ProgramOverview.propTypes = {
  control: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  formErrors: PropTypes.string,
  setFormErrors: PropTypes.func,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  openAlert: PropTypes.func,
  setOpenAlert: PropTypes.func,
  closeAlert: PropTypes.func,
  errors: PropTypes.object,
  ref: PropTypes.func,
  handleWordChange: PropTypes.func,
  word: PropTypes.string,
  wordCount: PropTypes.number,
  limit: PropTypes.number,
  wordCounter: PropTypes.object,
  setWordCounter: PropTypes.func,
  goalIds: PropTypes.array,
  indicatorCode: PropTypes.array,
  handleFieldChange: PropTypes.func
};

export default ProgramOverview;
