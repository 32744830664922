import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import utils from '../../../helpers/utils'

const USERS_URL = `${process.env.REACT_APP_API_URL}/users`;

const initialState = {
  users: [],
  status: 'idle',
  error: null
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const accessToken = await utils.getValidAccessToken();
  try {
    const response = await axios.get(USERS_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response.data;
  } catch (err) {
    return err.message;
  }
});

// export const forgotPassword = createAsyncThunk('users/forgotPassword', async (data) => {
//   const accessToken = localStorage.getItem('token');
//   try {
//     const response = await axios.post(`USERS_URL`, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`
//       }
//     });
//     return response.data;
//   } catch (err) {
//     return err.message;
//   }
// });

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const getAllUsers = (state) => state.users.users;
export const getUserStatus = (state) => state.users.status;
export const getError = (state) => state.users.error;

export default usersSlice.reducer;
