import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal from './ModalPortal';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  //   Fade,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  //   Slide,
  TextField,
  Typography
} from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../services/context/AuthProvider';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './Modal.module.scss';

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const ResetPasswordModal = ({
  closeFn = () => null,
  open = false,
  setModal,
  setFormData,
  formData
}) => {
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  //   const [showPassword, setShowPassword] = useState(false);

  //   const { authenticate } = useContext(AuthContext);
  const {
    control,
    handleSubmit
    // setValue
    // watch,
    // formState: { errors }
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      Email: formData?.email,
      Action: 'Confirm Forgot Password',
      ConfirmationCode: formData?.ConfirmationCode
    }
  });

  const onSubmit = async (data) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/forgotPassword`, {
        ...data,
        Email: formData.email,
        ConfirmationCode: formData.ConfirmationCode
      });
      setError(null);
      setFormData({
        ...formData,
        Email: formData?.email,
        ConfirmationCode: formData?.ConfirmationCode
      });
      setModal('password-reset-successful');

      return res.data;
    } catch (err) {
      setError(err.response?.data?.Message);
      console.log('error', err.response.Message);
      return err.response.data.Message;
    }
  };
  return (
    <Modal open={open} onClose={closeFn}>
      <Dialog
        maxWidth="sm"
        onClose={closeFn}
        open={open}
        aria-labelledby="modal-wrapper"
        className={styles['modal-wrapper']}
        // TransitionComponent={Transition}
        transitionDuration={500}>
        <DialogTitle className={styles['header-title']} onClose={closeFn}>
          Reset your password
          <IconButton
            aria-label="close"
            onClick={closeFn}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={`${styles['modal-content']} ${styles['login-wrapper']}`}
          sx={{ p: 5 }}>
          {error && (
            <Alert
              variant="filled"
              severity="error"
              className={styles['error-message']}
              sx={{ mb: 4 }}>
              {error}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container maxWidth="xs">
              <Grid item xs={12} mb={2}>
                <Controller
                  name="Password"
                  control={control}
                  rules={{
                    required: 'Password is required.'
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <Typography mb={2} textAlign="left">
                        Please enter your new password.
                      </Typography>
                      <TextField
                        {...field}
                        label="New Password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}>
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" fullWidth className={styles['btn']}>
                  Reset my password
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Modal>
  );
};

ResetPasswordModal.propTypes = {
  closeFn: PropTypes.func,
  open: PropTypes.bool,
  setModal: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.object
};

export default ResetPasswordModal;
