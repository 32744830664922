import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Grid container spacing={4} className={styles['main-content']}>
        <Grid item sm={12}>
          <Typography variant="h1" component="h1" mb={2}>
            Privacy Statement
          </Typography>
          <Typography variant="body" component="p">
            The STEM Equity Evaluation Portal (the <strong>Portal</strong>) is a website operated
            and managed by the Office of the Women in STEM Ambassador (the <strong>Office</strong>).
            The Office is funded by an Australian Government Grant and is operated at the University
            of New South Wales, Sydney, Australia (<strong>UNSW</strong>). Accordingly, all personal
            information collected through the Portal is held by UNSW and managed in accordance with
            the Privacy and Personal Information Protection Act 1998 (NSW) (&quot;the Act&quot;) and
            the{' '}
            <a
              href="https://www.unsw.edu.au/content/dam/pdfs/governance/policy/2022-01-policies/privacypolicy.pdf"
              target="_blank"
              rel="noreferrer">
              UNSW Privacy Policy
            </a>
            . This Privacy Statement describes the ways in which the Office and UNSW deals with
            personal information collected through the Portal.
          </Typography>
          <Typography variant="h3" component="h2" mb={2} mt={3}>
            Collection of personal information
          </Typography>
          <Typography variant="body" component="p">
            We collect your personal information when you create a profile or subscribe to our
            mailing list. The types of personal information that we may collect include your name,
            password, email address, location, organisation, position, expertise, photos, and any
            other information that you choose to provide. All profile information can be edited or
            removed by the profile`&apos;s owner. All profiles are reviewed by the Office prior to
            publication on the Portal.
          </Typography>
          <Typography variant="body" component="p">
            The Portal uses cookies to improve your experience. A cookie is a small message given to
            your web browser by our web server. Your browser stores this message and is sent back to
            the Portal website server each time you visit the Portal. Information collected using
            cookies includes your browser’s internet provider (IP) address, the date and time of
            your visit and duration of time for each webpage, the pages you accessed, and documents
            downloaded, and the type of browser you used. You can disable the acceptance of cookies
            by your web browser at any time. This may, however, prevent you from taking full
            advantage of the Portal.
          </Typography>
          <Typography variant="body" component="p">
            External sites that are linked to or from the Portal are not under our control or
            responsibility, and you are advised to review their privacy statement.
          </Typography>
          <Typography variant="h3" component="h2" mb={2} mt={3}>
            Use and disclosure of personal information
          </Typography>
          <Typography variant="body" component="p">
            All profile information, except for password, is made publicly available on the Portal.
            Depending on your privacy selection your email address will either be (1) publicly
            listed on your profile and/or (2) stored by the Office and utilised when a user uses the
            contact form to message a profile owner. Personal information collected in your profile
            is only used for the purpose of maintaining and operating the Portal, including
            contacting you for feedback about the Portal.
          </Typography>
          <Typography variant="body" component="p">
            The Office uses MailChimp to manage mailing lists and event registrations. You can
            access MailChimp&apos;s privacy notice here. When subscribing to the Portal mailing
            list, you will be asked to give your express consent that MailChimp may use your data
            for analytics purposes. Analytics are performed when you click on links in the email, or
            when you download the images in the email. They include which emails you open, which
            links you click, your mail client (e.g. &quot;Outlook 2016&quot; or &quot;iPhone&quot;),
            if your action occurred on &quot;mobile&quot; or &quot;desktop&quot;, and the country
            geolocation of your IP address (the IP address itself is not stored).
          </Typography>
          <Typography variant="body" component="p">
            The information that is automatically collected during each visit to the Portal is used
            to assist the Office to identify patterns of website usage and to improve and maintain
            the service of the Portal website and any services offered. The information is used in
            aggregated form by combining it with information collected from other users. This
            aggregated information may be published by the Office in internal reports in a manner
            that does not identify individual users.
          </Typography>
          <Typography variant="body" component="p">
            We will not otherwise disclose your personal information without your consent unless
            otherwise authorised by law. Personal information collected through the Portal which is
            no longer required by the Office, and which need not be retained by law will be
            destroyed.
          </Typography>
          <Typography variant="body" component="p">
          We may share your personal information with third party service providers that assist us with 
          providing this Website and related products and services. Amazon Web Services (AWS) is used 
          for hosting, backups, web database, file storage, APIs, and log files and as the website’s 
          content management system. As a contracted service provider, AWS are bound by the University’s 
          privacy and security requirements and may only use the personal information for the purpose of 
          providing the services to the University.  Information held by AWS on behalf of the University 
          may be processed outside of New South Wales.
          </Typography>
          <Typography variant="h3" component="h2" mb={2} mt={3}>
            Accessing and deleting your personal information
          </Typography>
          <Typography variant="body" component="p">
            You have a right to access your personal information held by UNSW. You can also request
            amendment to that information if you believe that it is incorrect or make complaints
            about the information handling practices of UNSW. Access and amendment requests,
            complaints and other privacy enquiries should be directed to:
          </Typography>
          <ul className={styles['no-bullets']}>
            <li>Office of the Women in STEM Ambassador</li>
            <li>
              <span>Email:</span>
              <a href="mailto:eval.womeninstem@unsw.edu.au">eval.womeninstem@unsw.edu.au</a>
            </li>
            <li>
              <span>Telephone: </span>
              <span>(02) 9385 5218</span>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrivacyPolicy;
