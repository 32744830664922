import React from 'react';
import PropTypes from 'prop-types';
import Modal from './ModalPortal';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  //   Fade,
  Grid,
  IconButton,
  Link,
  //   Slide,
  Tooltip,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styles from './Modal.module.scss';

const tooltip1 = (
  <>
    <Typography variant="body1" component="p" className={styles['tooltip-link']}>
      Creating an evaluation uses a simple 5-stage process. Read about it.
    </Typography>
  </>
);

const tooltip2 = (
  <>
    <strong>Don’t have an account?</strong> It only takes 5 minutes to create an account and
    profile. Then you can start evaluating your programs.
  </>
);

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const ModalOne = ({ closeFn = () => null, open = false }) => {
  return (
    <Modal open={open} onClose={closeFn}>
      <Dialog
        maxWidth="md"
        onClose={closeFn}
        open={open}
        aria-labelledby="modal-wrapper"
        className={styles['modal-wrapper']}
        // TransitionComponent={Transition}
        transitionDuration={500}>
        <DialogTitle className={styles['header-title']} onClose={closeFn}>
          {/* Modal One */}
          <IconButton
            aria-label="close"
            onClick={closeFn}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles['modal-content']} sx={{ p: 5 }}>
          <Grid container maxWidth="md" spacing={6} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h2" component="h2" className={styles['modal-title']}>
                Log in to create or modify evaluations.
              </Typography>
              <Typography variant="body1" component="p" className={styles['modal-body']}>
                If you don’t have an account yet, create one to start evaluating your programs.
              </Typography>
              <Box className={styles.link}>
                <Link href="https://womeninstem.org.au/national-evaluation-guide/" target="_blank">
                  What&apos;s involved in an evaluation?
                </Link>
                <Tooltip title={tooltip1} className={styles.tooltip}>
                  <InfoOutlinedIcon sx={{ ml: 1 }} color="primary" />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} justifyContent="center">
              <Box className="">
                <Button
                  variant="contained"
                  //   component={Link}
                  //   href="/login"
                  fullWidth
                  className={styles['btn']}
                  data-modal="signin">
                  Log in
                </Button>
                <Link
                  href="#"
                  component="a"
                  className={styles['btn-link']}
                  data-modal="registration">
                  Create an account
                  <Tooltip title={tooltip2} className={styles.tooltip}>
                    <InfoOutlinedIcon sx={{ ml: 1 }} color="primary" />
                  </Tooltip>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Modal>
  );
};

ModalOne.propTypes = {
  closeFn: PropTypes.func,
  open: PropTypes.bool
};

export default ModalOne;
