import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const EVALUATIONS_URL = `${process.env.REACT_APP_API_URL}/evaluations`;

const initialState = {
  evaluation: {},
  status: 'idle',
  error: null
};

export const singleEvaluation = createAsyncThunk('evaluation/singleEvaluation', async (evalId) => {
  try {
    const res = await axios.get(`${EVALUATIONS_URL}/${evalId}`);
    return res.data;
  } catch (err) {
    return err.message;
  }
});

export const evaluationSlice = createSlice({
  name: 'evaluation',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(singleEvaluation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(singleEvaluation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.evaluation = action.payload;
      })
      .addCase(singleEvaluation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const getEvaluation = (state) => state.evaluation.evaluation;
export const getEvaluationStatus = (state) => state.evaluations.status;
export const getError = (state) => state.evaluations.error;

export default evaluationSlice.reducer;
