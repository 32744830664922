import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logo from '../../assets/images/logo.png';
import styles from './Drawer.module.scss';
import { AuthContext } from '../../services/context/AuthProvider';
// import { useNavigate } from 'react-router-dom';

const drawerWidth = 300;

const pages = [
  {
    title: 'Dashboard',
    url: '/dashboard'
  },
  {
    title: 'Create new evaluation',
    url: '/evaluation'
  },
  {
    title: 'Files',
    url: '/files'
  },
  {
    title: 'Contacts',
    url: '/contacts'
  },
  {
    title: 'Help',
    url: '/help'
  },
  {
    title: 'Profile',
    url: '/profile'
  }
];

const DashboardSidebar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const { logout } = useContext(AuthContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      <Divider />
      <List className={styles['drawer-links']}>
        {pages.slice(0, 3).map((page, index) => (
          <Link key={index} to={page.url} reloadDocument>
            {page.title}
          </Link>
        ))}
      </List>
      <Divider />
      <List className={styles['drawer-links']}>
        {pages.slice(4, 6).map((page, index) => (
          <Link key={index} to={page.url} reloadDocument>
            {page.title}
          </Link>
        ))}
        <ListItem disablePadding>
          <ListItemButton onClick={logout}>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <ListItem disablePadding>
        <ListItemButton component="a" href="/">
          <ListItemText primary="Back to website" />
        </ListItemButton>
      </ListItem>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" color="white" id={`${styles['dashboard-header']}`}>
        <Toolbar id="top">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            className={styles.logo}
            sx={{
              mr: 2,
              display: 'flex',
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}>
            <img src={logo} alt="Women in Stem Logo" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Box className={styles['dashbaord-sidebar']} component="nav" aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { md: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            zIndex: '999'
          }}
          className={styles['sidebar']}>
          <Toolbar />

          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
          open>
          <Toolbar />
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

DashboardSidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
  logout: PropTypes.func
};

export default DashboardSidebar;
