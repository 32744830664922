import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import Search from '../../components/Search';
import EvaluationCard from '../../components/Card/EvaluationCard';
import Discover from '../../layouts/Discover';
import styles from './Bookmarks.module.scss';

const Bookmarks = () => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const savedEvaluations = JSON.parse(localStorage.getItem('bookmarks'));

  return (
    <Container>
      <Grid container spacing={4} className={styles['banner-wrapper']}>
        <Grid item sm={12} md={6}>
          <Typography variant="h1" component="h1">
            Bookmarked evaluations
          </Typography>
        </Grid>
        <Grid item sm={12} md={6}>
          {/* <img src={bannerImage} /> */}
        </Grid>
      </Grid>
      <Grid container spacing={4} className={styles['bookmark-wrapper']}>
        <Grid item sm={12}>
          <Paper elevation={0} className={styles['bookmark-container']}>
            <Typography variant="h2" component="h2">
              My saved evaluations
            </Typography>

            {savedEvaluations.length === 0 && (
              <Typography variant="body" component="p" mt={2}>
                No saved evaluations
              </Typography>
            )}
            <Box className={styles['bookmark-items']}>
              {savedEvaluations?.map((evaluation, index) => {
                const goals = evaluation && evaluation.Goals?.map((item) => item.Goal);
                return (
                  <EvaluationCard
                    key={index}
                    title={evaluation.ProgramName}
                    status={evaluation.ProgramLevel}
                    content={evaluation.ProgramDescription}
                    outcomes={goals.join('; ')}
                    className={styles['evaluation-item']}
                    readmore
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/program/${evaluation?.UID}`);
                      navigate(0);
                    }}
                  />
                );
              })}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Grid container className={styles['search-wrapper']}>
        <Grid item sm={12}>
          <Search query={query} setQuery={setQuery} />
        </Grid>
      </Grid>
      <Discover />
    </Container>
  );
};

export default Bookmarks;
