import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const modalRootEl = document.getElementById('modal-root');

const ModalPortal = ({ children, open = false }) => {
  if (!open) return null;

  return ReactDOM.createPortal(children, modalRootEl);
};

ModalPortal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool
};

export default ModalPortal;
