import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Box, IconButton, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import styles from './FileUpload.module.scss';
import utils from '../../helpers/utils';

const FileUpload = ({ url, files, setFiles, removeFile }) => {
  const [error, setError] = useState('');

  const uploadHandler = useCallback((event) => {
    const file = event.target.files[0];
    if (!file) return;
    file.isUploading = true;

    if (
      !file.name.match(
        /\.(jpg|JPG|jpeg|JPEG|jfif|png|PNG|gif|GIF|pdf|PDF|docx|doc|ppt|pptx|xlsx|xls|csv)$/
      )
    ) {
      setError('File uploaded is not supported.');
      return false;
    }

    if (file.size > 2.5e7) {
      setError('Please select file size less than 25MB.');
      return false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const uploadedFile = {
        FileName: file.name,
        FileType: file.type,
        Content: reader.result
      };
      const accessToken = await utils.getValidAccessToken();

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
      axios
        .post(url, uploadedFile, config)
        .then((res) => {
          file.isUploading = false;
          setFiles([...files, file]);
          setError('');
          console.log(res);
          return res.data;
        })
        .catch((err) => {
          // inform the user
          console.error(err.message);
          removeFile(file.name);
          setError(err.message);
          console.log('error', err.message);
          return err.message;
        });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

    // upload file
    const formData = new FormData();
    formData.append(file.name, file, file.name);

  }, []);

  return (
    <Box className={styles['file-card']}>
      <Box className={styles['file-inputs']}>
        <input type="file" accept="image/*, application/*" onChange={uploadHandler} />
        <IconButton>
          <UploadFileIcon />
          Upload file
        </IconButton>
      </Box>
      <Typography component="p" variant="body">
        <strong>Supported files:</strong> <br />
        pdf, docx, doc, ppt, xlsx, xls, csv, png, gif, jpg, jpeg
      </Typography>
      <Typography component="p" variant="body" color="error">
        {error}
      </Typography>
    </Box>
  );
};

FileUpload.propTypes = {
  url: PropTypes.string,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  removeFile: PropTypes.func
};

export default FileUpload;
