import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Toolbar,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import ResponsiveDrawer from '../../components/Drawer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArticleIcon from '@mui/icons-material/Article';
import styles from './Dashboard.module.scss';
import EvaluationCard from '../../components/Card/EvaluationCard';
import { AuthContext } from '../../services/context/AuthProvider';
import {
  getStatus,
  getAllEvaluations,
  fetchEvaluations
} from '../../services/features/evaluations/evaluationsSlice';
import {
  // getEvaluationStatus,
  getEvaluation
  // singleEvaluation
} from '../../services/features/evaluation/evaluationSlice';
import { useSelector, useDispatch } from 'react-redux';
import QuickReferenceGuide from '../../assets/files/Quick-Reference-Evaluation-Guide.pdf';
import NationalEvaluationGuide from '../../assets/files/National-Evaluation-Guide.pdf';
import EvaluationPlanning from '../../assets/files/Evaluation-Planning-Tool.pdf';
import CollaboratorsModal from '../../components/Modal/CollaboratorsModal';
import UnpublishEvaluationModal from '../../components/Modal/UnpublishEvaluationModal';

import utils from '../../helpers/utils';

const drawerWidth = 300;

const Dashboard = () => {
  const dispatch = useDispatch();
  // const [team] = useState(0);
  // const [empty] = useState(false);
  const { getSession, logout } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState('');
  const author = userInfo.given_name + ' ' + userInfo.family_name;
  const [open, setOpen] = useState(false);
  const [unPublish, setUnpublish] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [formData, setFormData] = useState({});
  const [collaborators, setCollaborators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState({
    status: false,
    message: ''
  });

  const evaluationsStatus = useSelector(getStatus);
  // const evaluationStatus = useSelector(getEvaluationStatus);
  const evaluations = useSelector(getAllEvaluations);
  const evaluation = useSelector(getEvaluation);
  // const evalId = localStorage.getItem('evalId');

  useEffect(() => {
    if (evaluationsStatus === 'idle') {
      dispatch(fetchEvaluations());
    }
  }, [evaluationsStatus, dispatch]);

  // useEffect(() => {
  //   if (evaluationStatus === 'idle') {
  //     dispatch(singleEvaluation(evalId));
  //   }
  // }, [evaluationStatus, dispatch]);

  const userEvaluations = evaluations?.filter((evaluation) => {
    return evaluation?.Collaborators?.some((author) => author.Username === userInfo.sub);
  });

  const userTeam = evaluations
    ?.filter((evaluation) => {
      return evaluation?.Collaborators?.some((item) => item.Name === author);
    })
    ?.map((collaborator) => collaborator.Collaborators.map((name) => name.Name))
    ?.flat()
    .filter((unique) => !unique.includes(author))
    .reduce((acc, team) => (acc.includes(team) ? acc : [...acc, team]), []);

  useEffect(() => {
    getSession().then((data) => {
      setUserInfo(data);
    });
    setFormData(evaluation[0]);
  }, []);

  // useEffect(() => {
  //   if (evaluationStatus === 'idle') {
  //     dispatch(singleEvaluation(evalId));
  //   }
  // }, [dispatch, evaluation]);

  // console.log('formData', formData);

  const handleCollaborators = async (e, evalId) => {
    e.preventDefault();
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/evaluations/${evalId}`);
      setSelectedId(evalId);
      setOpen(true);
      setCollaborators(res?.data[0]?.Collaborators);
      localStorage.setItem('evalId', evalId);
      return res.data;
    } catch (err) {
      console.log(err.message);
      return err.message;
    }
  };

  const openUnpublishModal = useCallback(
    async (e, evalId) => {
      e.preventDefault();
      localStorage.setItem('evalId', evalId);
      // setFormData({ ...evaluation[0] });
      setUnpublish(true);
    },
    [evaluation]
  );

  const handleUnpublish = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const accessToken = await utils.getValidAccessToken();
        if (localStorage.getItem('evalId') !== null) {
          const evalId = localStorage.getItem('evalId');
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/evaluations/${evalId}`,
            {
              Published: false,
              Stage: 'PublishStatus'
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            }
          );
          setUnpublish(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);

          setSuccessMessage({ status: true, message: 'Evaluation is successfully unpublished!' });
          return res.data;
        }
      } catch (err) {
        console.log('error', err.message);
        return err.message;
      }
    },
    [formData]
  );

  const handleClose = () => {
    setSuccessMessage({ status: false });
  };

  useEffect(() => {}, [formData]);

  // console.log('dashboard data', formData);

  return (
    <>
      <ResponsiveDrawer logout={logout} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 4,
          ml: 'auto',
          backgroundColor: '#f9f9f9',
          width: { md: `calc(100% - ${drawerWidth}px)` }
        }}>
        <Toolbar />
        <Typography variant="h1" component="h1">
          Hi {userInfo.given_name},
        </Typography>
        {successMessage.status && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={successMessage}
            autoHideDuration={6000}
            onClose={handleClose}>
            <Alert elevation={2} onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              {successMessage.message}
            </Alert>
          </Snackbar>
        )}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box className={styles['dashboard-card']} elevation={2}>
              <Box className={styles['dashboard-card-title']}>
                <Typography variant="h4" component="h2">
                  Program Dashboard
                </Typography>
              </Box>
              <Box className={styles['dashboard-body']}>
                {userEvaluations && userEvaluations.length === 0 && (
                  <Paper className={styles['empty-dashboard']} elevation={2}>
                    <Typography variant="h4" component="h3">
                      It&apos;s empty here!
                    </Typography>
                    <Typography variant="body1" component="p">
                      You haven&apos;t started any publications.
                    </Typography>
                    <Button
                      variant="contained"
                      to="/evaluation"
                      component={Link}
                      endIcon={<ArrowForwardIcon />}>
                      Evaluate a program now
                    </Button>
                  </Paper>
                )}
                {userEvaluations && userEvaluations.length > 0 ? (
                  <>
                    <Paper className={styles['empty-dashboard']} elevation={2}>
                      <Typography variant="h4" component="h3">
                        To evaluate another program, start here.
                      </Typography>
                      <Button
                        variant="contained"
                        to="/evaluation"
                        component={Link}
                        endIcon={<ArrowForwardIcon />}>
                        Evaluate a program now
                      </Button>
                    </Paper>
                    <Paper className={styles['evaluation-items']} elevation={2}>
                      {userEvaluations?.map((item, index) => {
                        const dateUpdated = new Date(item.DateUpdated);
                        return (
                          <EvaluationCard
                            id={item.UID}
                            key={index}
                            title={item.ProgramName}
                            datePublished={`Last modified on ${dateUpdated.toLocaleDateString()}`}
                            status={item.ProgressLevel}
                            content={item.ProgramDescription}
                            className={styles['evaluation-item']}
                            editor
                            collaborators
                            published={item.Published}
                            onClick={(e) => handleCollaborators(e, item.UID)}
                            unpublishEvaluation={(e) => openUnpublishModal(e, item.UID)}
                          />
                        );
                      })}

                      <CollaboratorsModal
                        id={selectedId}
                        open={open}
                        setOpen={setOpen}
                        formData={formData}
                        setFormData={setFormData}
                        collaborators={collaborators}
                        setCollaborators={setCollaborators}
                        setSuccessMessage={setSuccessMessage}
                      />

                      <UnpublishEvaluationModal
                        id={selectedId}
                        open={unPublish}
                        setOpen={setUnpublish}
                        formData={formData}
                        setFormData={setFormData}
                        onClick={handleUnpublish}
                        loading={loading}
                      />
                    </Paper>
                  </>
                ) : (
                  <Box className={styles['loader']}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={styles['dashboard-card']} elevation={2}>
              <Box className={styles['dashboard-card-title']}>
                <Typography variant="h4" component="h2">
                  Quick Links
                </Typography>
              </Box>
              <Box className={styles['dashboard-body']}>
                <Box className={styles['documents']}>
                  <Card className={styles['document-item']}>
                    <CardContent>
                      <ArticleIcon color="primary" fontSize="large" />
                      <Typography variant="h5" component="div">
                        Quick Reference Guide
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="text"
                        size="small"
                        fullWidth
                        href={QuickReferenceGuide}
                        target="_blank"
                        rel="noreferrer">
                        Find out more
                      </Button>
                    </CardActions>
                  </Card>
                  <Card className={styles['document-item']}>
                    <CardContent>
                      <ArticleIcon color="primary" fontSize="large" />
                      <Typography variant="h5" component="div">
                        Evaluation Planning Tool
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="text"
                        size="small"
                        fullWidth
                        href={EvaluationPlanning}
                        target="_blank"
                        rel="noreferrer">
                        Find out more
                      </Button>
                    </CardActions>
                  </Card>
                  <Card className={styles['document-item']}>
                    <CardContent>
                      <ArticleIcon color="primary" fontSize="large" />
                      <Typography variant="h5" component="div">
                        National Evaluation Guide
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="text"
                        size="small"
                        fullWidth
                        href={NationalEvaluationGuide}
                        target="_blank"
                        rel="noreferrer">
                        Find out more
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={styles['dashboard-card']} elevation={2}>
              <Box className={styles['dashboard-card-title']}>
                <Typography variant="h4" component="h2">
                  Your Team
                </Typography>
              </Box>
              <Box className={styles['dashboard-body']}>
                <Box className={styles['team-items']}>
                  {userTeam && userTeam?.length === 0 && (
                    <Card
                      className={styles['team-item']}
                      elevation={0}
                      sx={{ flexBasis: '100% !important' }}>
                      <CardContent>
                        <Typography variant="body2" component="p">
                          No collaborators have been added.
                        </Typography>
                      </CardContent>
                    </Card>
                  )}

                  {userTeam?.map((team, index) => {
                    return (
                      <Card className={styles['team-item']} elevation={0} key={index}>
                        <CardContent>
                          <Avatar
                            alt={team}
                            src="/static/images/avatar/1.jpg"
                            sx={{ width: 56, height: 56 }}
                            className={styles['avatar']}
                          />
                          <Typography variant="h5" component="div">
                            {team}
                          </Typography>
                          {/* <Typography variant="body2" component="p">
                                Evaluator
                              </Typography> */}
                        </CardContent>
                      </Card>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
