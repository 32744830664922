import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import classes from './Discover.module.scss';
import Card from '../../components/Card';
import {
  getStatus,
  getAllEvaluations,
  fetchEvaluations
} from '../../services/features/evaluations/evaluationsSlice';
import { useSelector, useDispatch } from 'react-redux';

function Discover() {
  const dispatch = useDispatch();
  const evaluationStatus = useSelector(getStatus);
  const evaluations = useSelector(getAllEvaluations);

  const latestEvaluations =
    evaluations &&
    [...evaluations]?.sort((a, b) => new Date(b.DateCreated) - new Date(a.DateCreated));
  const filteredEvaluations = latestEvaluations?.filter(
    (evaluation) => evaluation.Published === true
  );

  useEffect(() => {
    if (evaluationStatus === 'idle') {
      dispatch(fetchEvaluations());
    }
  }, [evaluationStatus, dispatch]);
  return (
    <Box className={classes['discover-wrapper']}>
      <Typography variant="h2" component="h2">
        Discover
      </Typography>
      <Typography variant="body1" component="p">
        This week’s most recent programs and updates.
      </Typography>
      <Box className={classes['card-items']}>
        {filteredEvaluations &&
          filteredEvaluations.slice(0, 5).map((evaluation, index) => {
            return (
              <Card
                key={index}
                title={evaluation.ProgramName}
                subtitle={evaluation.Organisation}
                content={evaluation.ProgramDescription}
                url={`/program/${evaluation.UID}`}
                className={classes['card-item']}
              />
            );
          })}
      </Box>
      <Box justifyContent="center" display="flex">
        <Button
          variant="contained"
          color="primary"
          href="/search-programs?SearchKey"
          size="medium"
          disableElevation
          sx={{
            mt: 3,
            color: '#ffffff !important'
          }}>
          View more
        </Button>
      </Box>
    </Box>
  );
}

export default Discover;
