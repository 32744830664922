import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Resources from '../../components/Resources';

const Define = ({
  control,
  goalItems,
  programAudienceItems,
  evaluationAudienceItems,
  setFormData,
  formData,
  setValue,
  getValues,
  errors,
  setChunkedInstruments,
  setChunkedSummary
}) => {
  const [goals, setGoals] = useState(getValues('Goals') || []);
  const [problemItems, setProblemItems] = useState([]);
  const [problems, setProblems] = useState([]);
  const [programAudiences, setProgramAudiences] = useState([]);
  const [evaluationAudiences, setEvaluationAudiences] = useState([]);

  const handleGoalsChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setGoals(value);
    setProblems([]);
    setValue('Problems', []);
    setValue('Indicators', []);
    setValue('Instruments', []);
    for (let i = 0; i < getValues('Indicators')?.length; i++) {
      console.log(`Summary${i}`);
      setValue(`Summary${i}`, '');
    }
    setFormData({
      ...formData,
      [name]: value
    });
    setChunkedInstruments([]);
    setChunkedSummary([]);
  };

  const handleProblemsChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setProblems(value);
    setFormData((formData) => {
      return { ...formData, Problems: value };
    });
  };

  // get filtered problems
  const getProblems = () => {
    const response =
      goalItems &&
      goalItems?.reduce((acc, curr) => {
        if (goals?.some((item) => item === curr?.Value)) {
          acc.push(curr?.MappedProblems);
        }
        return acc;
      }, []);

    const filteredProblem = [].concat.apply([], response);

    const uniqueProblems = [];
    filteredProblem &&
      filteredProblem?.map((problem) => {
        if (!uniqueProblems?.includes(problem)) {
          uniqueProblems?.push(problem);
        }
      });
    setProblemItems(uniqueProblems);
  };

  useEffect(() => {
    getProblems();
  }, [goalItems, goals]);

  const handleProgramAudienceChange = (e) => {
    const { value, name } = e.target;
    setProgramAudiences(value);

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEvaluationAudienceChange = (e) => {
    const { value, name } = e.target;

    setEvaluationAudiences(value);
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <>
      <Paper elevation={2} sx={{ p: 4, mb: 5 }}>
        <Typography variant="h3" component="h2" color="primary" mb={4}>
          Define
        </Typography>
        {Object.values(errors).length !== 0 && (
          <Alert variant="filled" severity="error" sx={{ mb: 3 }}>
            Please fill out all required fields.
          </Alert>
        )}
        <Typography variant="subtitle" component="p" mb={2} sx={{ fontSize: '14px' }}>
          * Required information
        </Typography>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ mb: 0 }}>
            <Box component="div">
              <Typography variant="h4" component="h3">
                Goals | What do you want to achieve? *
                <small>
                  Choose your goal(s) carefully. Some dropdown menu options in the following stages
                  depend on the goals you choose here. If you come back and change your goals later,
                  it will reset some of the other options linked to them. Limit the number of goals
                  you choose—keep your evaluation focused on the main goals of your program. You
                  must select at least one goal.
                </small>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name="Goals"
              control={control}
              defaultValue={[]}
              rules={{ required: 'Goals is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" error={!!error}>
                  <InputLabel id="goal">Select a goal</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      handleGoalsChange(e, field);
                      field.onChange(e);
                    }}
                    labelId="goal"
                    label="Select a goal"
                    multiple
                    renderValue={(selected) => selected.join(', ')}>
                    {goalItems &&
                      goalItems?.map((goal) => (
                        <MenuItem key={goal.Code} value={goal.Value} className="multi-checkboxes">
                          <Checkbox checked={goals && goals?.indexOf(goal.Value) > -1} />
                          <ListItemText primary={goal.Value} />
                        </MenuItem>
                      ))}
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="GoalDetails"
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    placeholder="Please provide details (optional)"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ mb: 0 }}>
            <Box component="div">
              <Typography variant="h4" component="h3">
                Problem | What problem does the program address? *
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name="Problems"
              control={control}
              defaultValue={[]}
              rules={{ required: 'Problems is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" error={!!error}>
                  <InputLabel id="problems">Select a problem</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      handleProblemsChange(e, field);
                      field.onChange(e);
                    }}
                    labelId="problem"
                    label="Select a problem"
                    multiple
                    renderValue={(selected) => selected.join(', ')}>
                    {problemItems &&
                      problemItems?.map((problem) => (
                        <MenuItem key={problem} value={problem} className="multi-checkboxes">
                          <Checkbox checked={problems && problems?.indexOf(problem) > -1} />
                          <ListItemText primary={problem} />
                        </MenuItem>
                      ))}
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="ProblemDetails"
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    placeholder="Please provide details (optional)"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={{ mb: 0 }}>
            <Box component="div">
              <Typography variant="h4" component="h3">
                Audiences | Who are the program and evaluation for?
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" component="h4" mb={2}>
              Program audiences | Who is the program for? *
              <small>
                Program audience(s) is/are the people participating in your program, such as
                employers, STEM professionals, or school students.
              </small>
            </Typography>

            <Controller
              name="ProgramAudiences"
              control={control}
              defaultValue={[]}
              rules={{ required: 'Program audience is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" error={!!error} sx={{ mb: 2 }}>
                  <InputLabel id="program-audiences">Select a program audience</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      handleProgramAudienceChange(e, field);
                      field.onChange(e);
                    }}
                    labelId="program-audiences"
                    label="Select a program audience"
                    multiple
                    renderValue={(selected) => selected.join(', ')}>
                    {programAudienceItems &&
                      programAudienceItems?.map((audience) => (
                        <MenuItem
                          key={audience.Code}
                          value={audience.Value}
                          className="multi-checkboxes">
                          <Checkbox
                            checked={
                              programAudiences && programAudiences?.indexOf(audience.Value) > -1
                            }
                          />
                          <ListItemText primary={audience.Value} />
                        </MenuItem>
                      ))}
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            <Typography variant="h5" component="h4" mb={2}>
              Evaluation audiences | Who is interested in the evaluation? *
              <small>
                Evaluation audience(s) is/are the people interested in the evaluation findings, such
                as program partners, funders, and government.
              </small>
            </Typography>

            <Controller
              name="EvaluationAudiences"
              control={control}
              defaultValue={[]}
              rules={{ required: 'Evaluation audience is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" error={!!error} sx={{ mb: 2 }}>
                  <InputLabel id="evaluation-audiences">Select an evaluation audience</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      handleEvaluationAudienceChange(e, field);
                      field.onChange(e);
                    }}
                    labelId="evaluation-audiences"
                    label="Select an evaluation audience"
                    multiple
                    renderValue={(selected) => selected.join(', ')}>
                    {evaluationAudienceItems &&
                      evaluationAudienceItems?.map((audience) => (
                        <MenuItem
                          key={audience.Code}
                          value={audience.Value}
                          className="multi-checkboxes">
                          <Checkbox
                            checked={
                              evaluationAudiences &&
                              evaluationAudiences?.indexOf(audience.Value) > -1
                            }
                          />
                          <ListItemText primary={audience.Value} />
                        </MenuItem>
                      ))}
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="AudienceDetails"
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    placeholder="Please provide details (optional)"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper elevation={2} sx={{ p: 4, mb: 5 }}>
        <Resources />
      </Paper>
    </>
  );
};

Define.propTypes = {
  control: PropTypes.object,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleComplete: PropTypes.func,
  openAlert: PropTypes.func,
  setOpenAlert: PropTypes.func,
  closeAlert: PropTypes.func,
  errors: PropTypes.object,
  handleWordChange: PropTypes.func,
  word: PropTypes.string,
  goalItems: PropTypes.array,
  programAudienceItems: PropTypes.array,
  evaluationAudienceItems: PropTypes.array,
  setChunkedInstruments: PropTypes.func,
  setChunkedSummary: PropTypes.func
};

export default Define;
