import React from 'react';
import PropTypes from 'prop-types';
import { Link, List, ListItem, Typography } from '@mui/material';
import styles from './Resources.module.scss';
import EvaluationPlanningTool from '../../assets/files/Evaluation-Planning-Tool.pdf';
import NationalEvaluationGuide from '../../assets/files/National-Evaluation-Guide.pdf';
import QuickReferenceGuide from '../../assets/files/Quick-Reference-Evaluation-Guide.pdf';
import DataManagementPlan from '../../assets/files/Data-Management-Plan.pdf';
import ParticipantConsentForm from '../../assets/files/Participant-Consent-Form-Templates.pdf';

const Resources = ({ dataManagementPlan, participantConsentForm }) => {
  return (
    <>
      <Typography variant="h3" component="h2" color="primary" mb={4}>
        Additional Resources
      </Typography>
      <List className={styles['resources-item']}>
        <ListItem
          className={styles['resource-item']}
          secondaryAction={
            <Link
              className={styles['download-link']}
              // href="https://womeninstem.org.au/wp-content/uploads/2020/11/Quick-Reference-Evaluation-Guide.pdf"
              href={QuickReferenceGuide}
              target="_blank"
              rel="noreferrer">
              Download
            </Link>
          }>
          Quick Reference Guide
        </ListItem>
        <ListItem
          className={styles['resource-item']}
          secondaryAction={
            <Link
              className={styles['download-link']}
              href={EvaluationPlanningTool}
              target="_blank"
              rel="noreferrer">
              Download
            </Link>
          }>
          Evaluation Planning Tool
        </ListItem>
        <ListItem
          className={styles['resource-item']}
          secondaryAction={
            <Link
              className={styles['download-link']}
              href={NationalEvaluationGuide}
              target="_blank"
              rel="noreferrer">
              Download
            </Link>
          }>
          National Evaluation Guide
        </ListItem>
        {dataManagementPlan && (
          <ListItem
            className={styles['resource-item']}
            secondaryAction={
              <Link
                className={styles['download-link']}
                href={DataManagementPlan}
                target="_blank"
                rel="noreferrer">
                Download
              </Link>
            }>
            Data Management Plan
          </ListItem>
        )}
        {participantConsentForm && (
          <ListItem
            className={styles['resource-item']}
            secondaryAction={
              <Link
                className={styles['download-link']}
                href={ParticipantConsentForm}
                target="_blank"
                rel="noreferrer">
                Download
              </Link>
            }>
            Participant Consent Form Templates
          </ListItem>
        )}
      </List>
    </>
  );
};

Resources.propTypes = {
  dataManagementPlan: PropTypes.bool,
  participantConsentForm: PropTypes.bool
};

export default Resources;
