import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import styles from './EvaluatePrograms.module.scss';

const PublishingProgram = () => {
  return (
    <Container>
      <Grid container spacing={4} className={styles['main-content']}>
        <Grid item sm={12}>
          <Typography variant="h1" component="h1" mb={2}>
            The benefits of publishing your evaluation on the STEM Equity Evaluation Portal
            repository
          </Typography>
          <Typography variant="body" component="p">
            Australia has hundreds of programs to tackle STEM equity, but are they working?
            Unfortunately, there is little evidence that they do. Few programs are formally
            evaluated. For example, a{' '}
            <a
              href="https://onlinelibrary.wiley.com/doi/abs/10.1002/ajs4.142"
              target="_blank"
              rel="noreferrer">
              study by the Australian National University in 2018
            </a>{' '}
            identified at least 337 programs across Australia. Of those, only seven had publicly
            available evaluation results.
          </Typography>
          <Typography variant="body" component="p">
            The STEM Equity Evaluation Portal (the Portal) has two functions. The first is to
            evaluate equity programs, and the second is as a national database of
            equity programs.
          </Typography>
          <Typography variant="body" component="p">
            “Program evaluation helps us know if we’re being effective in creating change,” says Dr
            Isabelle Kingsley, Research Associate from the Office of the Women in STEM Ambassador
            and creator of the Portal.
          </Typography>
          <Typography variant="body" component="p">
            “Publishing your evaluation on the Portal will help us know what works and allow us to
            learn from each other to improve equity.”
          </Typography>
          <Typography variant="body" component="p">
            What are the benefits for organisations that publish their evaluations? Here are just
            six reasons to publish.
          </Typography>
          <ol>
            <li>
              <strong>Increased accountability</strong>. Publishing your evaluation results and
              findings increases the accountability of your organisation. It also reveals your
              commitment to continuing the work required to improve equity.
            </li>
            <li>
              <strong>Improved learning</strong>. Sharing the results of your evaluation with other
              organisations is true collaboration. Freely exchanging knowledge and a desire to learn
              from others is key to building a culture centred around learning and development.
            </li>
            <li>
              <strong>More funding opportunities</strong>. Transparently sharing your program
              evaluation can help you attract and keep sponsors and program partners. Results show
              accountability and demonstrate value and worth.
            </li>
            <li>
              <strong>Talent retention</strong>. Transparency is appealing to future and current
              employees. It indicates a workplace culture that is open, connected and invested in
              the well-being of its workforce.
            </li>
            <li>
              <strong>One size doesn’t fit all</strong>. Every organisation is at a different stage
              of its equity journey. Sharing evaluations can help them determine which
              programs will achieve change.
            </li>
            <li>
              <strong>Promote best practice and collaboration</strong>. Public evaluations can
              inform and guide best practice for STEM equity programs.
            </li>
          </ol>
          <Typography variant="body" component="p">
            Equity is a shared issue we are all working to solve. The more information we
            share publicly, the easier it will be for organisations to take actions that work to
            improve equity.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PublishingProgram;
