import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import Modal from './ModalPortal';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Modal.module.scss';

const OnboardingScreen = ({ closeFn = () => null, open = false }) => {
  return (
    <Modal open={open} onClose={closeFn}>
      <Dialog
        maxWidth="sm"
        onClose={closeFn}
        open={open}
        aria-labelledby="modal-wrapper"
        className={styles['modal-wrapper']}
        transitionDuration={500}>
        <DialogTitle className={styles['header-title']} onClose={closeFn}>
          {/* Forgot your password? */}
          <IconButton
            aria-label="close"
            onClick={closeFn}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${styles['modal-content']}`} sx={{ p: 5, textAlign: 'center' }}>
          <Grid container maxWidth="xs">
            <Grid item xs={12} mb={2}>
              <Typography variant="h1" component="h2">
                Thanks!
              </Typography>
              <Typography variant="body1" component="p" sx={{ mb: 2 }}>
                We sent a verification link to the email address you used to create your account.
                Please verify your address, then log in to get started.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button onClick={closeFn} variant="contained" className={styles['btn']}>
                Get started
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Modal>

    // <Modal open={true} onClose={handleClose}>
    //   <DialogContent className={styles['modal-content']} sx={{ p: 5 }}>
    //     <Grid container maxWidth="xs">
    //       <Grid item xs={12} mb={2}>
    //         <Typography variant="h1" component="h2">
    //           Thanks!
    //         </Typography>
    //         <Typography variant="body1" component="p" sx={{ mb: 2 }}>
    //           We sent a verification link to the email address you used to create your account.
    //           Please verify your address, then log in to get started.
    //         </Typography>
    //         <Typography variant="body1" component="p">
    //           Our team will also review and approve your account. Until this has been completed, you
    //           can create and save evaluations, but you will not be able to publish, modify or remove
    //           them.
    //         </Typography>
    //       </Grid>

    //       <Grid item xs={12}>
    //         <Button onClick={handleClose} variant="contained" className={styles['btn']}>
    //           Get started
    //         </Button>
    //       </Grid>
    //     </Grid>
    //   </DialogContent>
    // </Modal>
  );
};

OnboardingScreen.propTypes = {
  closeFn: PropTypes.func,
  open: PropTypes.bool,
  setModal: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.object
};

export default OnboardingScreen;
