import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal from './ModalPortal';
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
// import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './Modal.module.scss';
import ImageUpload from '../FileUpload/ImageUpload';
// import { AuthProvider } from '../../services/context/AuthProvider';

// const filter = createFilterOptions();
// const locations = [
//   { name: 'NSW', value: 'NSW' },
//   { name: 'QLD', value: 'QLD' },
//   { name: 'SA', value: 'SA' },
//   { name: 'TAS', value: 'TAS' },
//   { name: 'VIC', value: 'VIC' },
//   { name: 'WA', value: 'WA' },
//   { name: 'ACT', value: 'ACT' },
//   { name: 'NT', value: 'NT' }
// ];

const RegistrationModal = ({
  closeFn = () => null,
  open = false,
  setModal,
  setFormData,
  formData
}) => {
  const [error, setError] = useState('');
  const picture = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [avatar, setAvatar] = useState('');

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
    // ...props
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      Declaration: '',
      Email: '',
      FirstName: '',
      LastName: '',
      OrganisationABN: '',
      OrganisationLocation: '',
      OrganisationName: '',
      OrganisationType: '',
      Password: '',
      Avatar: '',
      CustomLocation: '',
      Website: '',
      FacebookUrl: '',
      TwitterUrl: '',
      LinkedinUrl: '',
      InstagramUrl: ''
    }
  });

  closeFn = () => {
    reset();
    setModal('');
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    setValue('Avatar', avatar);
    try {
      setFormData({
        ...data,
        Avatar: avatar,
        Declaration: data.Declaration ? 'true' : 'false',
        CustomLocation: data.OrganisationLocation === 'Others' ? data.CustomLocation : ''
      });
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/users`,
        {
          ...data,
          Avatar: avatar,
          Declaration: data.Declaration ? 'true' : 'false',
          CustomLocation: data.OrganisationLocation === 'Others' ? data.CustomLocation : ''
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setModal('onboarding');
      setError(null);
      reset();
      // return res.data;
      console.log('res', res.data);
    } catch (err) {
      setError(err.response?.data?.Message);
      // console.log('error', err.response.Message);
      return err.response.data.Message;
    }
  };

  const handleLocationChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal open={open} onClose={closeFn}>
      <Dialog
        maxWidth="sm"
        onClose={closeFn}
        open={open}
        aria-labelledby="modal-wrapper"
        className={styles['modal-wrapper']}
        // TransitionComponent={Transition}
        transitionDuration={500}>
        <DialogTitle className={styles['header-title']} onClose={closeFn}>
          Create account
          <IconButton
            aria-label="close"
            onClick={closeFn}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={`${styles['modal-content']} ${styles['registration-wrapper']}`}
          sx={{ p: 5 }}>
          <Typography variant="body1" component="p" mb={2} sx={{ textAlign: 'left' }}>
            Please provide the relevant information in the following fields. You must be authorised
            to act on behalf of the organisations you list and ensure that all information you
            provide is true and correct.
          </Typography>
          {error && (
            <Alert
              variant="filled"
              severity="error"
              className={styles['error-message']}
              sx={{ mb: 4 }}>
              {error}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={styles['image-upload']}>
                <ImageUpload uploadedImage={picture} setAvatar={setAvatar} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="FirstName"
                  control={control}
                  rules={{
                    required: 'First name is required.'
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="First name"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="LastName"
                  control={control}
                  rules={{
                    required: 'Last name is required.'
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Last name"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="OrganisationType"
                  control={control}
                  type="text"
                  defaultValue={[]}
                  rules={{ required: 'Organisation type is required.' }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <InputLabel id="evaluation-approaches">Organisation Type</InputLabel>
                      <Select {...field} label="Organisation Type" defaultValue="">
                        <MenuItem value="Business">Business</MenuItem>
                        <MenuItem value="Research">Research</MenuItem>
                        <MenuItem value="Education">Education</MenuItem>
                        <MenuItem value="Government">Government</MenuItem>
                        <MenuItem value="Community Group">Community Group</MenuItem>
                        <MenuItem value="Philanthropy">Philanthropy</MenuItem>
                        <MenuItem value="Not for Profir">Not for Profit</MenuItem>
                      </Select>
                      {error && <FormHelperText error>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="OrganisationABN"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Organisation ABN (optional)"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Controller
                  name="OrganisationLocation"
                  control={control}
                  type="text"
                  defaultValue={[]}
                  rules={{ required: 'Organisation type is required.' }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <InputLabel id="evaluation-approaches">Organisation location</InputLabel>
                      <Select
                        {...field}
                        onChange={(e) => handleLocationChange(e, field)}
                        label="Organisation location"
                        defaultValue={[]}>
                        <MenuItem value="NSW">NSW</MenuItem>
                        <MenuItem value="QLD">QLD</MenuItem>
                        <MenuItem value="SA">SA</MenuItem>
                        <MenuItem value="TAS">TAS</MenuItem>
                        <MenuItem value="VIC">VIC</MenuItem>
                        <MenuItem value="WA">WA</MenuItem>
                        <MenuItem value="ACT">ACT</MenuItem>
                        <MenuItem value="NT">NT</MenuItem>
                        <MenuItem value="Others">Outside Australia</MenuItem>
                      </Select>
                      {error && <FormHelperText error>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid> */}
              <Grid item xs={6}>
                {/* <Controller
                  name="OrganisationLocation"
                  control={control}
                  rules={{
                    required: 'Organisation location is required.'
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                          console.log('newValue', newValue);
                          onChange(newValue);
                        }}
                        filterSelectedOptions
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option.name);
                          if (inputValue !== '' && !isExisting) {
                            filtered.push({
                              inputValue,
                              name: `Add "${inputValue}"`
                            });
                          }

                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="organisation-location"
                        options={locations}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        // freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // {...props}
                            label="Organisation location"
                            fullWidth
                            error={!!error}
                            onChange={onChange}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                /> */}
                <Controller
                  name="OrganisationLocation"
                  control={control}
                  type="text"
                  defaultValue=""
                  rules={{ required: 'Organisation location is required.' }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <InputLabel id="org-location">Organisation location</InputLabel>
                      <Select
                        {...field}
                        onChange={(e) => {
                          handleLocationChange(e, field);
                          field.onChange(e);
                        }}
                        label="Organisation location">
                        <MenuItem value="NSW">NSW</MenuItem>
                        <MenuItem value="QLD">QLD</MenuItem>
                        <MenuItem value="SA">SA</MenuItem>
                        <MenuItem value="TAS">TAS</MenuItem>
                        <MenuItem value="VIC">VIC</MenuItem>
                        <MenuItem value="WA">WA</MenuItem>
                        <MenuItem value="ACT">ACT</MenuItem>
                        <MenuItem value="NT">NT</MenuItem>
                        <MenuItem value="Others">Outside Australia</MenuItem>
                      </Select>
                      {error && <FormHelperText error>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>

              {formData?.OrganisationLocation === 'Others' && (
                <Grid item xs={6}>
                  <Controller
                    name="CustomLocation"
                    control={control}
                    defaultValue={formData?.CustomLocation}
                    rules={{
                      required: 'Location is required.'
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl fullWidth size="small" error={!!error}>
                        <TextField
                          {...field}
                          label="Enter location"
                          value={field.value || ''}
                          defaultValue={formData?.CustomLocation}
                          fullWidth
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Controller
                  name="OrganisationName"
                  control={control}
                  rules={{
                    required: 'Organisation name is required.'
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Organisation name"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="Email"
                  control={control}
                  rules={{
                    required: 'Email is required.',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email format.'
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Email"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="Password"
                  control={control}
                  rules={{
                    required: 'Password is required.'
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}>
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="Website"
                  control={control}
                  rules={{
                    pattern: {
                      value:
                        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi, // eslint-disable-line
                      message: 'Invalid website URL format.'
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Website URL (optional)"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="FacebookUrl"
                  control={control}
                  rules={{
                    pattern: {
                      value:
                        /(?:https?:\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i, // eslint-disable-line
                      message: 'Invalid Facebook URL format.'
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Facebook URL (optional)"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="TwitterUrl"
                  control={control}
                  rules={{
                    pattern: {
                      value:
                        /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/, // eslint-disable-line
                      message: 'Invalid Twitter URL format.'
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Twitter URL (optional)"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="LinkedinUrl"
                  control={control}
                  rules={{
                    pattern: {
                      value:
                        /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm, // eslint-disable-line
                      message: 'Invalid LinkedIn URL format.'
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Linkedin URL (optional)"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="InstagramUrl"
                  control={control}
                  rules={{
                    pattern: {
                      value: /(?:http:\/\/)?(?:www\.)?instagram\.com\/[a-z\d-_]{1,255}\s*$/i, // eslint-disable-line
                      message: 'Invalid Instagram URL format.'
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Instagram URL (optional)"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="Declaration"
                  control={control}
                  defaultValue={false}
                  rules={{
                    required: 'Declaration is required.'
                  }}
                  render={({ field }) => (
                    <FormControlLabel
                      className={styles['consent']}
                      sx={{ mt: 2, mr: 0 }}
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          value={field.value}
                          name="Declaration"
                          label={
                            'I hereby declare that I am authorised to act on behalf of the organisations listed above and that all information contained here is true and correct.'
                          }
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label="I hereby declare that I am authorised to act on behalf of the organisations listed above and that all information contained here is true and correct."
                    />
                  )}
                />
                {errors.Declaration && (
                  <FormHelperText error>{errors.Declaration.message}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} mb={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={styles['btn']}
                  // onClick={handleSubmit}
                >
                  Create account
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Modal>
  );
};

RegistrationModal.propTypes = {
  closeFn: PropTypes.func,
  open: PropTypes.bool,
  setModal: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  onChange: PropTypes.func
};

export default RegistrationModal;
