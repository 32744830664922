import React from 'react';
import { Container, Grid, Fab, Link, List, ListItem, Typography } from '@mui/material';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScrollTop from './ScrollTop';
import SocialMedia from './SocialMedia';
import logo from '../../assets/images/logo.png';
import classes from './Footer.module.scss';
// import SessionExpiredModal from '../Modal/SessionExpiredModal';

const Footer = () => {
  return (
    <>
      <div className={classes['footer-wrapper']}>
        <Container maxWidth="xl">
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" noWrap component="a" href="/">
                <img src={logo} alt="Women in Stem Logo" className={classes.logo} />
              </Typography>
              <Typography variant="body2" classes={{ root: classes.citation }}>
                Please cite the use of this website and resource as: <br />
                Kingsley, I. (2022). STEM Equity Evaluation Portal. <br /> Australian Government
                Office of the Women in STEM Ambassador. <br />
                <Link href="https://doi.org/10.17605/OSF.IO/XKYR9">
                  https://doi.org/10.17605/OSF.IO/XKYR9
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="body1"
                component="span"
                classes={{ root: classes['section-heading'] }}>
                Contact
              </Typography>
              <List classes={{ root: classes['footer-menu'] }}>
                <ListItem disableGutters>
                  <Link underline="none" href="mailto:eval.womeninstem@unsw.edu.au">
                    eval.womeninstem@unsw.edu.au
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link underline="none" href="/privacy-policy">
                    Privacy Policy
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link underline="none" href="/online-use-policy">
                    Online use policy
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link underline="none" href="/terms-and-conditions">
                    Terms and conditions
                  </Link>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <SocialMedia />
            </Grid>
          </Grid>
        </Container>
      </div>

      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>

      <Grid container spacing="2" className={classes.copyright}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          &copy; Copyright {new Date().getFullYear()}. Women in STEM Ambassador. All rights
          reserved.
        </Grid>
      </Grid>

      {/* <SessionExpiredModal /> */}
    </>
  );
};

export default Footer;
