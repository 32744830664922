import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const ALL_INSTRUMENTS_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=Instruments`;
const ALL_INDICATORS_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=Indicators`;
const INSTRUMENTS_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=IndicatorToInstruments`;
const DATA_COLLECTION_METHODS_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=DataCollectionMethods`;
const DESIGN_APPROACHES = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=DesignApproaches`;

const initialState = {
  design: [],
  status: 'idle',
  error: null
};

export const fetchAllInstruments = createAsyncThunk(
  'allInstruments/fetchAllIntruments',
  async () => {
    try {
      const response = await axios.get(ALL_INSTRUMENTS_URL);
      // console.log('all instruments', response.data.body);
      return response.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const fetchAllIndicators = createAsyncThunk('allIndicators/fetchAllIndicators', async () => {
  try {
    const response = await axios.get(ALL_INDICATORS_URL);
    // console.log('all instruments', response.data);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchInstruments = createAsyncThunk(
  'instruments/fetchInstruments',
  async (indicators) => {
    try {
      const response = await axios.get(INSTRUMENTS_URL + `&codes=${indicators}`);

      return response.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const fetchDataCollectionMethods = createAsyncThunk(
  'dataCollectionMethods/fetchDataCollectionMethods',
  async () => {
    try {
      const response = await axios.get(DATA_COLLECTION_METHODS_URL);
      // console.log('response', response);
      return response.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const fetchDesignApproaches = createAsyncThunk(
  'designApproaches/fetchDesignApproaches',
  async () => {
    try {
      const response = await axios.get(DESIGN_APPROACHES);
      // console.log('response', response);
      return response.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const designSlice = createSlice({
  name: 'design',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllInstruments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllInstruments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.allInstruments = action.payload;
        // return action.payload;
      })
      .addCase(fetchInstruments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInstruments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.instruments = action.payload;
        // return action.payload;
      })
      .addCase(fetchDataCollectionMethods.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDataCollectionMethods.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.dataCollectionMethods = action.payload;
        // return action.payload;
      })
      .addCase(fetchDesignApproaches.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDesignApproaches.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.designApproaches = action.payload;
        // return action.payload;
      });
  }
});

export const selectAllInstruments = (state) => state.design.allInstruments;
export const selectAllIndicators = (state) => state.design.allIndicators;
export const getInstruments = (state) => state.design.instruments;
export const selectAllDataCollectionMethods = (state) => state.design.dataCollectionMethods;
export const selectAllDesignApproaches = (state) => state.design.designApproaches;
export const getDesignStatus = (state) => state.design.status;
export const getError = (state) => state.design.error;

export default designSlice.reducer;
