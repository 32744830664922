import React, { useState, useContext } from 'react';
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  TextField,
  Typography
} from '@mui/material';
import { AuthContext } from '../../services/context/AuthProvider';
import styles from './Profile.module.scss';
// import PasswordField from '../../components/Form/PasswordField';
import { useForm, Controller } from 'react-hook-form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ChangePassword = () => {
  const { getSession } = useContext(AuthContext);
  const [passwordData, setPasswordData] = useState(null);
  const [error, setError] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const handleClickShowPassword = (e, name) => {
    e.preventDefault();
    if (name === 'currentPassword') {
      setShowCurrentPassword(!showCurrentPassword);
    }

    if (name === 'newPassword') {
      setShowNewPassword(!showNewPassword);
    }
  };
  // const handleMouseDownPassword = (e, name) => {
  //   e.preventDefault();
  //   setShowPassword(!showPassword[name]);
  // };

  const {
    control,
    handleSubmit
    // setValue
    // ...props
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: passwordData
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMessage(false);
  };

  // const handlePasswordChange = (event) => {
  //   const { name, value } = event.target;
  //   setPassword({ ...password, [name]: value });
  // };

  const changePassword = (data, event) => {
    event.preventDefault();
    console.log('data', data);
    setPasswordData(data);
    getSession().then(({ user }) => {
      user.changePassword(data.currentPassword, data.newPassword, (err, result) => {
        if (err) {
          if (err.name === 'NotAuthorizedException') {
            setError('Incorrect old password.');
          } else {
            setError(err.message);
          }
          console.log(err.name);
        } else {
          console.log(result);
          setSuccessMessage(true);
          setError('');
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
    });
  };

  // useEffect(() => {
  //   // reset form with user data
  //   reset(passwordData);
  // }, []);

  return (
    <Paper elevation={2} sx={{ p: 4, my: 4 }}>
      <Typography variant="body" component="h2">
        Change Password
      </Typography>
      <Box className={styles['profile-wrapper']}>
        <Grid container spacing={0}>
          <Grid item xs={12} mb={3}>
            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}
            {successMessage && (
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={successMessage}
                autoHideDuration={1000}
                onClose={handleClose}>
                <Alert
                  elevation={2}
                  onClose={handleClose}
                  severity="success"
                  sx={{ width: '100%' }}>
                  Password is successfully updated!
                </Alert>
              </Snackbar>
            )}
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(changePassword)}>
              <Grid item xs={12}>
                <Controller
                  name="currentPassword"
                  control={control}
                  rules={{
                    required: 'Current password is required.'
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error} sx={{ mb: 2 }}>
                      <TextField
                        {...field}
                        label="Current password"
                        variant="outlined"
                        type={showCurrentPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                id="currentPassword"
                                aria-label="toggle password visibility"
                                onClick={(e) => handleClickShowPassword(e, field.name)}
                                onMouseDown={(e) => handleClickShowPassword(e, field.name)}>
                                {showCurrentPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="newPassword"
                  control={control}
                  rules={{
                    required: 'New password is required.'
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error} sx={{ mb: 2 }}>
                      <TextField
                        {...field}
                        label="New password"
                        variant="outlined"
                        type={showNewPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                id="newPassword"
                                aria-label="toggle password visibility"
                                onClick={(e) => handleClickShowPassword(e, field.name)}
                                onMouseDown={(e) => handleClickShowPassword(e, field.name)}>
                                {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />

                {/* <Controller
                name="newPassword"
                control={control}
                rules={{
                  required: 'New password is required.'
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth size="small" error={!!error} sx={{ mb: 2 }}>
                    <PasswordField
                      {...field}
                      label="New Password"
                      name="newPassword"
                      value={field.value}
                      onChange={handlePasswordChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  </FormControl>
                )}
              /> */}
              </Grid>
              <Grid item xs={12} mb={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={styles['btn']}
                  onClick={changePassword}>
                  Change Password
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ChangePassword;
