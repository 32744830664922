import React from 'react';
import PropTypes from 'prop-types';
import SigninOptionModal from './SigninOptionModal';
import SigninModal from './SigninModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import ConfirmationModal from './ConfirmationModal';
import ResetPasswordModal from './ResetPasswordModal';
import PasswordResetSuccessfulModal from './PasswordResetSuccessfulModal';
import RegistrationModal from './RegistrationModal';
import OnboardingScreen from './OnboardingScreen';
import UnpublishEvaluationModal from './UnpublishEvaluationModal';

const ModalManager = ({ closeFn, modal = '', setModal, setFormData, formData }) => {
  return (
    <>
      <SigninOptionModal
        closeFn={closeFn}
        open={modal === 'signin-option'}
        setModal={setModal}
        setFormData={setFormData}
        formData={formData}
      />

      <SigninModal
        closeFn={closeFn}
        open={modal === 'signin'}
        setModal={setModal}
        setFormData={setFormData}
        formData={formData}
      />

      <ForgotPasswordModal
        closeFn={closeFn}
        open={modal === 'forgot-password'}
        setModal={setModal}
        modal="forgot-password"
        setFormData={setFormData}
        formData={formData}
      />

      <ConfirmationModal
        closeFn={closeFn}
        open={modal === 'confirmation'}
        setModal={setModal}
        modal="confirmation"
        setFormData={setFormData}
        formData={formData}
      />

      <ResetPasswordModal
        closeFn={closeFn}
        open={modal === 'reset-password'}
        setModal={setModal}
        modal="reset-password"
        setFormData={setFormData}
        formData={formData}
      />

      <PasswordResetSuccessfulModal
        closeFn={closeFn}
        setModal={setModal}
        modal="password-reset-successful"
        open={modal === 'password-reset-successful'}
      />

      <RegistrationModal
        closeFn={closeFn}
        open={modal === 'registration'}
        setModal={setModal}
        modal="registration"
        setFormData={setFormData}
        formData={formData}
      />

      <OnboardingScreen
        closeFn={closeFn}
        open={modal === 'onboarding'}
        setModal={setModal}
        modal="onboarding"
        setFormData={setFormData}
        formData={formData}
      />

      <UnpublishEvaluationModal
        // id={id}
        closeFn={closeFn}
        open={modal === 'unpublish'}
        setModal={setModal}
        modal="unpublish"
        setFormData={setFormData}
        formData={formData}
      />
    </>
  );
};

ModalManager.propTypes = {
  closeFn: PropTypes.func,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.object
};

export default ModalManager;
