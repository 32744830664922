import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ResponsiveDrawer from '../../components/Drawer';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography
} from '@mui/material';
import styles from './Evaluation.module.scss';
import ProgramOverview from './ProgramOverview';
import Define from './Define';
import Plan from './Plan';
import Design from './Design';
import Execute from './Execute';
import Share from './Share';
import {
  getDefineStatus,
  fetchGoals,
  fetchProgramAudiences,
  fetchEvaluationAudiences,
  selectAllGoals,
  selectAllProgramAudiences,
  selectAllEvaluationAudiences
} from '../../services/features/define/defineSlice';
import {
  getPlanStatus,
  fetchActivities,
  fetchInputs,
  fetchOutputs,
  selectAllActivities,
  selectAllInputs,
  selectAllOutputs,
  selectAllIndicators,
  fetchAllIndicators
} from '../../services/features/plan/planSlice';
import {
  getDesignStatus,
  fetchAllInstruments,
  fetchDataCollectionMethods,
  fetchDesignApproaches,
  getInstruments,
  selectAllDataCollectionMethods,
  selectAllDesignApproaches
} from '../../services/features/design/designSlice';
import {
  getStatus,
  selectAnalysisApproaches,
  fetchAnalysisApproaches
} from '../../services/features/execute/executeSlice';
import {
  getEvaluationStatus,
  getEvaluation,
  singleEvaluation
} from '../../services/features/evaluation/evaluationSlice';
import { updateEvaluation } from '../../services/features/evaluations/evaluationsSlice';
import { AuthContext } from '../../services/context/AuthProvider';

const drawerWidth = 300;
const steps = ['Define', 'Plan', 'Design', 'Execute', 'Share'];

const Evaluation = () => {
  const dispatch = useDispatch();
  const { evalId } = useParams();

  if (localStorage.getItem('evalId') === null) {
    localStorage.setItem('evalId', evalId);
  }

  const { getSession } = useContext(AuthContext);
  const [author, setAuthor] = useState('');
  const [authorIds, setAuthorIds] = useState([]);
  const [organisation, setOrganisation] = useState('');
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [publish, setPublish] = useState(false);

  useEffect(() => {
    getSession().then((data) => {
      setAuthor(data.given_name + ' ' + data.family_name);
      setAuthorIds(data.sub);
      setOrganisation(data['custom:organisation_name']);
      setEmail(data.email);
    });
  }, [author]);

  const handleCloseSnackbar = () => {
    setSuccessMessage(false);
  };

  const [activeStep, setActiveStep] = useState(0);
  const [stage] = useState({
    0: 'Program Overview',
    1: 'Define',
    2: 'Plan',
    3: 'Design',
    4: 'Execute',
    5: 'Share'
  });
  const [completed, setCompleted] = useState({});
  const [formData, setFormData] = useState({});

  const evaluationStatus = useSelector(getEvaluationStatus);
  const evaluation = useSelector(getEvaluation);

  const defineStatus = useSelector(getDefineStatus);
  const planStatus = useSelector(getPlanStatus);
  const designStatus = useSelector(getDesignStatus);
  const executeStatus = useSelector(getStatus);

  const goalItems = useSelector(selectAllGoals);
  const programAudienceItems = useSelector(selectAllProgramAudiences);
  const evaluationAudienceItems = useSelector(selectAllEvaluationAudiences);

  const activityItems = useSelector(selectAllActivities);
  const inputItems = useSelector(selectAllInputs);
  const outputItems = useSelector(selectAllOutputs);
  const indicatorItems = useSelector(selectAllIndicators);

  const instrumentItems = useSelector(getInstruments);
  const dataCollectionMethodItems = useSelector(selectAllDataCollectionMethods);
  const designApproachItems = useSelector(selectAllDesignApproaches);

  const analysisApproaches = useSelector(selectAnalysisApproaches);

  const [submittedData, setSubmittedData] = useState([]);

  useEffect(() => {
    if (evaluationStatus === 'idle') {
      dispatch(singleEvaluation(evalId));
    }
  }, [evaluationStatus, dispatch]);

  useEffect(() => {
    if (defineStatus === 'idle') {
      dispatch(fetchGoals());
      dispatch(fetchProgramAudiences());
      dispatch(fetchEvaluationAudiences());
    }
  }, [defineStatus, dispatch]);

  useEffect(() => {
    if (planStatus === 'idle') {
      dispatch(fetchActivities());
      dispatch(fetchInputs());
      dispatch(fetchOutputs());
      dispatch(fetchAllIndicators());
    }
  }, [planStatus, dispatch]);

  useEffect(() => {
    if (designStatus === 'idle') {
      dispatch(fetchAllInstruments());
      dispatch(fetchDataCollectionMethods());
      dispatch(fetchDesignApproaches());
    }
  }, [designStatus, dispatch]);

  useEffect(() => {
    if (executeStatus === 'idle') {
      dispatch(fetchAnalysisApproaches());
    }
  }, [executeStatus, dispatch]);

  // Get array object of selected goals
  const filteredGoals =
    goalItems &&
    goalItems
      ?.filter((goal) => {
        return formData?.Goals?.find((item) => {
          return goal.Value === item;
        });
      })
      ?.sort((a, b) => {
        return a.Code > b.Code ? 1 : -1;
      });

  // Get goals id
  const goalIds =
    filteredGoals &&
    filteredGoals?.map((id) => {
      return id?.Code;
    });

  // Array of indicators
  const mappedIndicators =
    indicatorItems &&
    Object.keys(indicatorItems)?.map((goal) => {
      return indicatorItems[goal]?.map((item) => {
        return item;
      });
    });

  const mergedIndicators = [].concat.apply([], mappedIndicators);

  const filteredIndicators =
    mergedIndicators &&
    mergedIndicators?.filter((indicator) => {
      return formData?.Indicators?.find((item) => {
        return indicator.Value === item;
      });
    });

  const allIndicators =
    indicatorItems &&
    Object.keys(indicatorItems)?.map((goal) => {
      return indicatorItems[goal]?.map((item) => {
        return item.Value || item;
      });
    });

  // Get indicators code
  const indicatorCode =
    filteredIndicators &&
    filteredIndicators?.map((id) => {
      return id.Code;
    });

  const selectedIndicators =
    allIndicators && allIndicators?.map((a) => a?.filter((b) => formData?.Indicators?.includes(b)));

  const indicatorsCount = selectedIndicators?.map((item) => item?.length);

  const mappedInstruments =
    instrumentItems &&
    Object.values(instrumentItems)?.map((instrument) => {
      return Object.values(instrument)?.map((item) => {
        return { Value: item.Value, Description: item.Description };
      });
    });

  const allInstruments =
    instrumentItems &&
    Object.keys(instrumentItems)?.map((instrument) => {
      return (
        instrumentItems &&
        instrumentItems[instrument]?.map((item) => {
          return item.Value || item;
        })
      );
    });

  if (allInstruments != undefined) {
    for (var instrumentList of allInstruments) {
      instrumentList.push('Other');
    }
  }

  const selectedInstruments =
    allInstruments &&
    allInstruments?.map((a) => a?.filter((b) => formData?.Instruments?.includes(b)));

  const summaryFindings = Object.entries(formData)
    ?.filter(([key]) => {
      const summary = key?.startsWith('Summary');
      return summary;
    })
    ?.map((item) => item[1]);

  const [count, setCount] = useState(0);
  const [dataType, setDataType] = useState({
    dataType1: true,
    dataType2: false,
    dataType3: false
  });

  const handleDataType = () => {
    if (count < 2) {
      setCount(Number(count) + 1);
    }

    if (count === 0) {
      setDataType({ ...dataType, dataType2: true });
    }
    if (count === 1) {
      setDataType({ ...dataType, dataType3: true });
    }
  };

  const totalSteps = () => {
    return steps.length + 1;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    resetField,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: formData
  });

  const goalsData = evaluation[0]?.Goals.map((goals) => goals.Goal);
  const indicatorsData = evaluation[0]?.Goals.map((goals) =>
    goals.Indicators.map((indicators) => indicators.Indicator)
  );

  const mergedIndicatorsData = [].concat.apply([], indicatorsData);
  const instrumentsData = evaluation[0]?.Goals.map((goals) =>
    goals?.Indicators.map((indicators) =>
      indicators?.Instruments.map((instruments) => instruments?.Instruments)
    )
  );
  const mergedInstrumentsData = [].concat.apply([], instrumentsData);
  const SummaryFindingsData = evaluation[0]?.Goals.map((goals) =>
    goals?.Indicators.map((indicators) => indicators?.Summary)
  );

  const mergedSummary = [].concat.apply([], SummaryFindingsData);
  const individualSummary = mergedSummary.reduce((prev, curr, i) => {
    return { ...prev, ['Summary' + i]: curr };
  }, {});

  const [chunkedInstruments, setChunkedInstruments] = useState([]);
  const [chunkedSummary, setChunkedSummary] = useState([]);

  useEffect(() => {
    setFormData({
      ...evaluation[0],
      ...formData,
      Goals: goalsData || formData?.Goals,
      Indicators: mergedIndicatorsData || formData?.Indicators,
      Instruments: mergedInstrumentsData?.flat() || formData?.Instruments,
      DataType1: evaluation[0]?.DataManagementPlan?.[0]?.DataType,
      DataStored1: evaluation[0]?.DataManagementPlan?.[0]?.DataStored,
      DataAccess1: evaluation[0]?.DataManagementPlan?.[0]?.DataAccess,
      DataDuration1: evaluation[0]?.DataManagementPlan?.[0]?.DataDuration,
      DataType2: evaluation[0]?.DataManagementPlan?.[1]?.DataType,
      DataStored2: evaluation[0]?.DataManagementPlan?.[1]?.DataStored,
      DataAccess2: evaluation[0]?.DataManagementPlan?.[1]?.DataAccess,
      DataDuration2: evaluation[0]?.DataManagementPlan?.[1]?.DataDuration,
      DataType3: evaluation[0]?.DataManagementPlan?.[2]?.DataType,
      DataStored3: evaluation[0]?.DataManagementPlan?.[2]?.DataStored,
      DataAccess3: evaluation[0]?.DataManagementPlan?.[2]?.DataAccess,
      DataDuration3: evaluation[0]?.DataManagementPlan?.[2]?.DataDuration,
      Recruit:
        evaluation[0]?.Consent?.Recruit && evaluation[0]?.Consent?.Recruit === true ? 'Yes' : 'No',
      RecruitmentMethod: evaluation[0]?.Consent?.RecruitmentMethod,
      RecruitmentConsent: evaluation[0]?.Consent?.RecruitmentConsent,
      ConsentRequirementsFollowed:
        evaluation[0]?.Consent?.Recruit === true
          ? evaluation[0]?.Consent?.ConsentRequirementsFollowed &&
            evaluation[0]?.Consent?.ConsentRequirementsFollowed === true
            ? 'Yes'
            : 'No'
          : '',
      AcademicJournal:
        evaluation[0]?.Ethics?.AcademicJournal && evaluation[0]?.Ethics?.AcademicJournal === true
          ? 'Yes'
          : 'No',
      HumanEthicsGuidelinesFollowed:
        evaluation[0]?.Ethics?.AcademicJournal === true
          ? evaluation[0]?.Ethics?.HumanEthicsGuidelinesFollowed &&
            evaluation[0]?.Ethics?.HumanEthicsGuidelinesFollowed === true
            ? 'Yes'
            : 'No'
          : '',
      HumanEthicsApprovalObtained:
        evaluation[0]?.Ethics?.AcademicJournal === true
          ? evaluation[0]?.Ethics?.HumanEthicsApprovalObtained &&
            evaluation[0]?.Ethics?.HumanEthicsApprovalObtained === true
            ? 'Yes'
            : 'No'
          : '',
      HumanEthicsApprovalNumber:
        evaluation[0]?.Ethics?.HumanEthicsApprovalNumber &&
        evaluation[0]?.Ethics?.HumanEthicsApprovalNumber,
      EvaluationFindings: evaluation[0]?.EvaluationFindings,
      ...individualSummary,
      ProgressLevel: evaluation[0]?.ProgressLevel
    });
    setChunkedInstruments(instrumentsData);
    setChunkedSummary(SummaryFindingsData);
  }, [evaluation[0]]);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  const handleNext = () => {
    // setFormData((formData) => {
    //   return { ...formData, ...data };
    // });
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    setActiveStep(newActiveStep);
    handleScrollTop();
    // editEvaluation(data);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleScrollTop();
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const [loading, setLoading] = useState(false);
  const [downloadBtn, setDownloadBtn] = useState(true);

  const editEvaluation = (data) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        ...data
      };
    });
    setLoading(true);

    dispatch(
      updateEvaluation({
        ...formData,
        ...data,
        Stage: stage[activeStep],
        UID: evalId ? evalId : null,
        Author: author,
        Collaborators: [{ Username: authorIds, Name: author }],
        Email: email,
        Organisation: organisation,
        Goals: filteredGoals?.map((goal, goalIndex) => {
          return {
            ['Goal']: goal.Value,
            Indicators: selectedIndicators?.[goalIndex]?.map((indicator, indicatorIndex) => {
              return {
                Indicator: indicator,
                Instruments: chunkedInstruments?.[goalIndex]?.[indicatorIndex],
                Summary: chunkedSummary?.[goalIndex]?.[indicatorIndex]
              };
            })
          };
        }),
        DataManagementPlan: {
          0: {
            DataType: data?.DataType1,
            DataAccess: data?.DataAccess1,
            DataStored: data?.DataStored1,
            DataDuration: data?.DataDuration1
          },
          1: {
            DataType: data?.DataType2,
            DataAccess: data?.DataAccess2,
            DataStored: data?.DataStored2,
            DataDuration: data?.DataDuration2
          },
          2: {
            DataType: data?.DataType3,
            DataAccess: data?.DataAccess3,
            DataStored: data?.DataStored3,
            DataDuration: data?.DataDuration3
          }
        },
        Consent: {
          Recruit: data?.Recruit === 'Yes' ? true : false,
          RecruitmentMethod: data?.Recruit === 'Yes' ? data?.RecruitmentMethod : '',
          RecruitmentConsent: data?.Recruit === 'Yes' ? data?.RecruitmentConsent : '',
          ConsentRequirementsFollowed: data?.ConsentRequirementsFollowed === 'Yes' ? true : false
        },
        Ethics: {
          AcademicJournal: data?.AcademicJournal === 'Yes' ? true : false,
          HumanEthicsGuidelinesFollowed:
            data?.HumanEthicsGuidelinesFollowed === 'Yes' ? true : false,
          HumanEthicsApprovalObtained: data?.HumanEthicsApprovalObtained === 'Yes' ? true : false,
          HumanEthicsApprovalNumber:
            data?.AcademicJournal === 'Yes' ? data?.HumanEthicsApprovalNumber : ''
        },
        EvaluationFindings: data?.EvaluationFindings === 'Yes' ? data?.EvaluationFindings : 'No',
        ExecutiveSummary: data?.ExecutiveSummary,
        ProgramOutputs: data?.ProgramOutputs,
        ProgramOutcomes: data?.ProgramOutcomes,
        ProgramProblems: data?.ProgramProblems,
        ProgramImpact: data?.ProgramImpact,
        ProgramBenefits: data?.ProgramBenefits,
        ProgramResults: data?.ProgramResults,
        LessonsLearnt: data?.LessonsLearnt,
        Conclusions: data?.Conclusions,
        Recommendations: data?.Recommendations,
        ProgressLevel:
          data?.EvaluationFindings === 'Yes' &&
          data?.ExecutiveSummary &&
          data?.ProgramOutputs &&
          data?.ProgramProblems &&
          data?.ProgramImpact &&
          data?.ProgramBenefits &&
          data?.ProgramResults &&
          data?.LessonsLearnt &&
          data?.Conclusions &&
          data?.Recommendations
            ? 'Complete'
            : 'In Progress'
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        setLoading(false);
        setSuccessMessage(true);
        if (stage[activeStep] === 'Share') {
          setDownloadBtn(false);
        }
        return originalPromiseResult;
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        return rejectedValueOrSerializedError;
      });
  };

  const submitEvaluation = (data, e) => {
    e.preventDefault();
    handleComplete();
    setSubmittedData({
      ...data,
      Goals: filteredGoals?.map((goal, goalIndex) => {
        return {
          ['Goal']: goal.Value,
          Indicators: selectedIndicators?.[goalIndex]?.map((indicator, indicatorIndex) => {
            return {
              Indicator: indicator,
              Instruments: chunkedInstruments?.[goalIndex]?.[indicatorIndex],
              Summary: chunkedSummary?.[goalIndex]?.[indicatorIndex]
            };
          })
        };
      })
    });
    editEvaluation(data);
  };

  const handlePublishChange = (data, e) => {
    e.preventDefault();
    setFormData({ ...formData, data, Author: author, Email: email, Published: true });
    setLoading(true);
    dispatch(
      updateEvaluation({
        ...data,
        Stage: stage[activeStep],
        UID: evalId ? evalId : null,
        Author: author,
        Email: email,
        Collaborators: [{ Username: authorIds, Name: author }],
        Organisation: organisation,
        Goals: filteredGoals?.map((goal, goalIndex) => {
          return {
            ['Goal']: goal.Value,
            Indicators: selectedIndicators?.[goalIndex]?.map((indicator, indicatorIndex) => {
              return {
                Indicator: indicator,
                Instruments: chunkedInstruments?.[goalIndex]?.[indicatorIndex],
                Summary: chunkedSummary?.[goalIndex]?.[indicatorIndex]
              };
            })
          };
        }),
        DataManagementPlan: {
          0: {
            DataType: data?.DataType1,
            DataAccess: data?.DataAccess1,
            DataStored: data?.DataStored1,
            DataDuration: data?.DataDuration1
          },
          1: {
            DataType: data?.DataType2,
            DataAccess: data?.DataAccess2,
            DataStored: data?.DataStored2,
            DataDuration: data?.DataDuration2
          },
          2: {
            DataType: data?.DataType3,
            DataAccess: data?.DataAccess3,
            DataStored: data?.DataStored3,
            DataDuration: data?.DataDuration3
          }
        },
        Consent: {
          Recruit: data?.Recruit === 'Yes' ? true : false,
          RecruitmentMethod: data?.Recruit === 'Yes' ? data?.RecruitmentMethod : '',
          RecruitmentConsent: data?.Recruit === 'Yes' ? data?.RecruitmentConsent : '',
          ConsentRequirementsFollowed: data?.ConsentRequirementsFollowed === 'Yes' ? true : false
        },
        Ethics: {
          AcademicJournal: data?.AcademicJournal === 'Yes' ? true : false,
          HumanEthicsGuidelinesFollowed:
            data?.HumanEthicsGuidelinesFollowed === 'Yes' ? true : false,
          HumanEthicsApprovalObtained: data?.HumanEthicsApprovalObtained === 'Yes' ? true : false,
          HumanEthicsApprovalNumber:
            data?.AcademicJournal === 'Yes' ? data?.HumanEthicsApprovalNumber : ''
        },
        EvaluationFindings: data?.EvaluationFindings === 'Yes' ? data?.EvaluationFindings : 'No',
        ExecutiveSummary: data?.ExecutiveSummary,
        ProgramOutputs: data?.ProgramOutputs,
        ProgramOutcomes: data?.ProgramOutcomes,
        ProgramProblems: data?.ProgramProblems,
        ProgramImpact: data?.ProgramImpact,
        ProgramBenefits: data?.ProgramBenefits,
        ProgramResults: data?.ProgramResults,
        LessonsLearnt: data?.LessonsLearnt,
        Conclusions: data?.Conclusions,
        Recommendations: data?.Recommendations,
        ...individualSummary,
        ProgressLevel:
          data?.EvaluationFindings === 'Yes' &&
          data?.ExecutiveSummary &&
          data?.ProgramOutputs &&
          data?.ProgramProblems &&
          data?.ProgramImpact &&
          data?.ProgramBenefits &&
          data?.ProgramResults &&
          data?.LessonsLearnt &&
          data?.Conclusions &&
          data?.Recommendations
            ? 'Complete'
            : 'In Progress',
        Published: true
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        setLoading(false);
        setPublish(true);
        setSuccessMessage(true);
        if (stage[activeStep] === 'Share') {
          setDownloadBtn(false);
        }
        return originalPromiseResult;
      })
      .catch((rejectedValueOrSerializedError) => {
        return rejectedValueOrSerializedError;
      });
  };

  const handleFieldChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <>
      <ResponsiveDrawer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          ml: 'auto',
          backgroundColor: '#f9f9f9',
          width: { md: `calc(100% - ${drawerWidth}px)` }
        }}>
        <Toolbar />
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" component="h1">
                Your Progress
              </Typography>
              <Typography
                variant="body2"
                component="a"
                href="https://womeninstem.org.au/wp-content/uploads/2020/11/Evaluating-STEM-Gender-Equity-Programs-Guide-Dec-2020.pdf"
                target="_blank"
                color="primary"
                sx={{ color: '#1EABBA !important' }}>
                Read about the 5-stage evaluation process
              </Typography>

              {publish === true ? (
                <>
                  <Paper elevation={2} sx={{ p: 4, mt: 6, mb: 5 }}>
                    <Typography
                      variant="h1"
                      component="h2"
                      color="primary"
                      textAlign="center"
                      mb={4}>
                      Congratulations!
                    </Typography>
                    <Typography variant="body1" component="p" mb={2}>
                      You published your evaluation on the Portal.
                    </Typography>
                    <Typography variant="body1" component="p" mb={2}>
                      If you don’t have findings yet, please come back and report on your evaluation
                      findings later. In the meantime, publishing your evaluation plan allows others
                      to know what you are doing and how you will measure success.
                    </Typography>
                    <Typography variant="body1" component="p">
                      If you reported on your findings, your published evaluation will allow others
                      to learn what works, and what doesn’t to improve equity in STEM.
                    </Typography>
                  </Paper>
                  <Paper elevation={2} sx={{ p: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    {loading ? (
                      <Box className={styles['loading']}>
                        <CircularProgress size={30} />
                        <Typography>Publishing...</Typography>
                      </Box>
                    ) : (
                      <Link className={styles['btn-primary']} to={'/dashboard'} reloadDocument>
                        Go to dashboard
                      </Link>
                    )}
                  </Paper>
                </>
              ) : (
                <>
                  {activeStep !== 0 ? (
                    <>
                      <Alert
                        variant="filled"
                        severity="info"
                        color="primary"
                        className={styles['notification-bar']}
                        sx={{ my: 5 }}>
                        <AlertTitle>
                          You are at the <strong>{steps[activeStep - 1]}</strong> Stage
                        </AlertTitle>
                        {steps[activeStep - 1] === 'Define' && (
                          <>
                            <Typography variant="body2" component="p">
                              The Define stage sets the scene for the program and its evaluation. It
                              prompts you to ask yourself:
                            </Typography>

                            <List
                              component="ul"
                              className={styles['list-items']}
                              sx={{ pl: 3, listStyle: 'disc' }}>
                              <ListItem component="li">What do you want to achieve?</ListItem>
                              <ListItem component="li">
                                What is the problem that your program addresses?
                              </ListItem>
                              <ListItem component="li">
                                Who are the programs and its evaluation for?
                              </ListItem>
                            </List>
                            <Typography variant="body2" component="p">
                              Articulating these key elements will allow you to have a clear idea of
                              what you want to achieve, why and for whom.
                            </Typography>
                          </>
                        )}
                        {steps[activeStep - 1] === 'Plan' && (
                          <>
                            <Typography variant="body2" component="p">
                              The Plan stage is the most important part of the program and
                              evaluation process. It allows you to embed evaluation into the program
                              from the very beginning. In this stage, you will choose the program
                              activities and plan the evaluation.
                            </Typography>

                            <Typography variant="body2" component="p">
                              The activities and evaluation must align with the goals and audiences
                              so that they perform properly together. Think about the goal(s) that
                              you defined in the previous stage and ask yourself:
                            </Typography>

                            <List
                              component="ul"
                              className={styles['list-items']}
                              sx={{ pl: 3, listStyle: 'disc' }}>
                              <ListItem component="li">What will you do to achieve it</ListItem>
                              <ListItem component="li">
                                How will you measure what is achieved?
                              </ListItem>
                            </List>
                          </>
                        )}
                        {steps[activeStep - 1] === 'Design' && (
                          <Typography variant="body2" component="p">
                            The Design stage determines how you will carry out the evaluation. You
                            will base your design on the priorities, key questions and indicators
                            that you determined in the previous section. First, you will decide on
                            the type of data you will collect, when and how you will collect it, and
                            how you will store it. Then, you will develop and test the tool that
                            will collect the data.
                          </Typography>
                        )}
                        {steps[activeStep - 1] === 'Execute' && (
                          <>
                            <Typography variant="body2" component="p">
                              With your program and evaluation already planned and designed,
                              it&apos;s time to put it into action. The Execute stage will involve
                              collecting data, then summarising and analysing it to interpret and
                              give it meaning.
                            </Typography>
                            <Typography variant="body2" component="p">
                              Executing your evaluation may involve recruiting participants. If so,
                              there are a few important considerations.
                            </Typography>
                          </>
                        )}
                        {steps[activeStep - 1] === 'Share' && (
                          <>
                            <Typography variant="body2" component="p">
                              The Share stage is a crucial step in the evaluation process. In this
                              stage, you can share your evaluation plan and/or findings. If you do
                              have findings yet, share your evaluation plan so that people can find
                              out about your program and how you are evaluating it. When you have
                              evaluation findings, come back to share them so that people can learn
                              what worked, what didn&apos;t and how to improve future programs.
                            </Typography>
                          </>
                        )}
                      </Alert>

                      <Paper elevation={2} sx={{ py: 4, px: 2 }}>
                        <Stepper
                          activeStep={activeStep - 1}
                          alternativeLabel
                          className={styles.stepper}>
                          {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                              <StepLabel
                                color="inherit"
                                // onClick={handleStep(label, index)}
                                className={styles['step-name']}>
                                {label}
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Paper>
                    </>
                  ) : null}
                  <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={successMessage}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={2000}>
                    <Alert onClose={handleCloseSnackbar} severity="success">
                      Evaluation is successfully updated!
                    </Alert>
                  </Snackbar>
                  <form>
                    <Box sx={{ mt: 6 }}>
                      {activeStep === 0 && (
                        <ProgramOverview
                          control={control}
                          setFormData={setFormData}
                          formData={formData}
                          errors={errors}
                          goalIds={goalIds}
                          indicatorCode={indicatorCode}
                          handleFieldChange={(e) => handleFieldChange(e)}
                        />
                      )}

                      {activeStep === 1 && (
                        <Define
                          control={control}
                          setFormData={setFormData}
                          formData={formData}
                          errors={errors}
                          goalItems={goalItems}
                          goalIds={goalIds}
                          indicatorCode={indicatorCode}
                          programAudienceItems={programAudienceItems}
                          evaluationAudienceItems={evaluationAudienceItems}
                          setValue={setValue}
                          getValues={getValues}
                          resetField={resetField}
                          setChunkedInstruments={setChunkedInstruments}
                          setChunkedSummary={setChunkedSummary}
                          handleFieldChange={(e) => handleFieldChange(e)}
                        />
                      )}
                      {activeStep === 2 && (
                        <Plan
                          control={control}
                          setFormData={setFormData}
                          formData={formData}
                          errors={errors}
                          activityItems={activityItems}
                          inputItems={inputItems}
                          outputItems={outputItems}
                          filteredGoals={filteredGoals}
                          mappedIndicators={mappedIndicators}
                          goalIds={goalIds}
                          indicatorCode={indicatorCode}
                          setChunkedInstruments={setChunkedInstruments}
                          setChunkedSummary={setChunkedSummary}
                          setValue={setValue}
                          getValues={getValues}
                          resetField={resetField}
                          handleFieldChange={(e) => handleFieldChange(e)}
                        />
                      )}
                      {activeStep === 3 && (
                        <Design
                          control={control}
                          setFormData={setFormData}
                          formData={formData}
                          errors={errors}
                          indicatorCode={indicatorCode}
                          filteredIndicators={filteredIndicators}
                          mappedInstruments={mappedInstruments}
                          dataCollectionMethodItems={dataCollectionMethodItems}
                          designApproachItems={designApproachItems}
                          selectedInstruments={selectedInstruments}
                          indicatorsCount={indicatorsCount}
                          setChunkedInstruments={setChunkedInstruments}
                          onClick={handleDataType}
                          dataType={dataType}
                          setDataType={setDataType}
                          count={count}
                          setCount={setCount}
                          resetField={resetField}
                          handleFieldChange={(e) => handleFieldChange(e)}
                          getValues={getValues}
                          setValue={setValue}
                        />
                      )}
                      {activeStep === 4 && (
                        <Execute
                          control={control}
                          setFormData={setFormData}
                          formData={formData}
                          errors={errors}
                          analysisApproaches={analysisApproaches}
                          handleFieldChange={(e) => handleFieldChange(e)}
                          getValues={getValues}
                          setValue={setValue}
                        />
                      )}
                      {activeStep === 5 && (
                        <Share
                          control={control}
                          setFormData={setFormData}
                          formData={formData}
                          errors={errors}
                          filteredIndicators={filteredIndicators}
                          indicatorsCount={indicatorsCount}
                          summaryFindings={summaryFindings}
                          setChunkedSummary={setChunkedSummary}
                          downloadBtn={downloadBtn}
                          setDownloadBtn={setDownloadBtn}
                          handlePublishEvaluation={handleSubmit(handlePublishChange)}
                          handleFieldChange={(e) => handleFieldChange(e)}
                          submittedData={submittedData}
                        />
                      )}
                    </Box>

                    <Paper elevation={2} sx={{ p: 4, display: 'flex', justifyContent: 'flex-end' }}>
                      {!loading ? (
                        <>
                          {activeStep === 0 ? (
                            <Button
                              type="button"
                              variant="contained"
                              sx={{ color: '#FFFFFF' }}
                              onClick={handleSubmit(handleNext)}>
                              Continue
                            </Button>
                          ) : (
                            <>
                              <Button
                                type="button"
                                color="inherit"
                                onClick={handleBack}
                                sx={{ mr: 1 }}>
                                Back
                              </Button>
                              <Box sx={{ flex: '1 1 auto' }} />
                              {activeStep === 5 ? null : (
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={handleSubmit(handleNext)}
                                  sx={{ color: '#FFFFFF' }}>
                                  Next
                                </Button>
                              )}
                            </>
                          )}
                          {activeStep === 5 && (
                            <Button
                              type="button"
                              onClick={handleSubmit(submitEvaluation)}
                              variant="contained"
                              sx={{ color: '#FFFFFF' }}>
                              Save
                            </Button>
                          )}
                        </>
                      ) : (
                        <Box className={styles['loading']}>
                          <CircularProgress size={30} />
                          <Typography>Saving...</Typography>
                        </Box>
                      )}
                    </Paper>
                  </form>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Evaluation;
