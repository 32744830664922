import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, Paper } from '@mui/material';
import Search from '../../components/Search/AdvancedSearch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './Search.module.scss';

const AdvancedSearch = () => {
  const [query, setQuery] = useState('');
  const [programAudiences, setProgramAudiences] = useState([]);
  const [goals, setGoals] = useState([]);
  const [programScales, setProgramScales] = useState([]);
  const [progressLevel, setProgressLevel] = useState([]);
  const [designApproaches, setDesignApproaches] = useState([]);
  const [methodology, setMethodology] = useState([]);
  return (
    <Container maxWidth="lg">
      <Grid container spacing={4} mt={2} className={styles['search-wrapper']}>
        <Grid item xs={12}>
          <Paper className={styles['search-card']} elevation={2}>
            <Search
              query={query}
              setQuery={setQuery}
              programAudiences={programAudiences}
              setProgramAudiences={setProgramAudiences}
              goals={goals}
              setGoals={setGoals}
              programScales={programScales}
              setProgramScales={setProgramScales}
              progressLevel={progressLevel}
              setProgressLevel={setProgressLevel}
              designApproaches={designApproaches}
              setDesignApproaches={setDesignApproaches}
              methodology={methodology}
              setMethodology={setMethodology}
            />
            <Box className={styles['back-btn']}>
              <Link to="/search-programs?SearchKey=">
                <ArrowBackIcon />
                Back to basic search
              </Link>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdvancedSearch;
