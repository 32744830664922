import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material';
import ResponsiveDrawer from '../../components/Drawer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Help.module.scss';

const Help = () => {
  return (
    <>
      <ResponsiveDrawer />
      <Box component="main" className={`dashboard-container ${styles['main-content']}`}>
        <Typography variant="h1" component="h1" mb={4}>
          Frequently asked questions (FAQs)
        </Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography variant="h4" component="h2">
              What is the STEM Equity Evaluation Portal?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              The STEM Equity Evaluation Portal (the Portal) is an online evaluation tool and
              repository for equity programs and their evaluations.
            </Typography>
            <Typography variant="body" component="p" mb={2}>
              The Portal has two functions.
            </Typography>
            <ol>
              <li>to search and discover programs and evaluations</li>
              <li>to evaluate your own equity programs.</li>
            </ol>
            <Typography variant="body" component="p" mb={2}>
              People can use the Portal to discover existing equity programs and their efficacy
              using the search functions. Learn what works, what doesn&apos;t, and how to improve
              future programs. Connect with the people running an equity program of interest by
              contacting them via email. Collaborate on programs to create stronger cohesion and
              consolidate efforts and resources.
            </Typography>
            <Typography variant="body" component="p" mb={2}>
              Use the Portal plan and evaluate your equity program. The Portal is an extension of
              the National Evaluation Guide for STEM equity programs. It breaks evaluation
              down into five simple steps. Define, Plan, Design, Execute and Share. Use the simple
              online form with dropdown menus to plan your evaluation and report on your findings.
              Then, share your program evaluation to the repository for others to learn from.
            </Typography>
            <Typography variant="body" component="p" mb={2}>
              Find out how to use the Portal in this short
              <a href="https://www.youtube.com/embed/rzpjnqgcSsQ" target="_blank" rel="noreferrer">
                {' '}
                3-minute video.
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Typography variant="h4" component="h2">
              What is evaluation?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              Evaluation is the collection and analysis of information to assess a program&apos;s
              strengths, weaknesses and effectiveness to understand what works or doesn&apos;t work,
              how, for whom and why.
            </Typography>
            <Typography variant="body" component="p" mb={2}>
              Evaluation is not something that happens at the end of the program. It is an integral
              part of the program and embedded within it from the beginning.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header">
            <Typography variant="h4" component="h2">
              Why is evaluation important?{' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              If you don&apos;t measure it, you can&apos;t improve it. Evaluation helps us to know
              what works, how to improve what doesn&apos;t and what to invest money, time and energy
              into. The end goal of STEM equity programs is to have a diverse and
              gender-balanced STEM workforce to tackle big world issues. With such important
              outcomes at stake, we need to know if our actions are working.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header">
            <Typography variant="h4" component="h2">
              How was the National Evaluation Guide developed?{' '}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              Developing the National Evaluation Guide for STEM equity programs was a very
              open and collaborative process. We spoke to program leaders who described evaluation
              as daunting, overwhelming, complicated, expensive and time-consuming. They wanted a
              resource that would break down evaluation into easy steps.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header">
            <Typography variant="h4" component="h2">
              Where is the National Evaluation Guide available?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              The{' '}
              <a
                href="https://womeninstem.org.au/wp-content/uploads/2022/10/National-Evaluation-Guide_Oct-2022.pdf"
                target="_blank"
                rel="noreferrer">
                National Evaluation Guide
              </a>{' '}
              for STEM equity programs is freely available. All associated resources are
              available on the{' '}
              <a
                href="https://womeninstem.org.au/national-evaluation-guide/"
                target="_blank"
                rel="noreferrer">
                Women in STEM Ambassador website
              </a>
              .
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header">
            <Typography variant="h4" component="h2">
              Why is equity in STEM important?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              Australia and the world are facing unprecedented skills shortages in critical areas
              required to address the economic, environmental, and technological challenges ahead.
              Future careers across all sectors will rely heavily on 21st-century science,
              technology, engineering, and mathematics (STEM) skills -{' '}
              <a
                href="https://www.education.gov.au/australian-curriculum/national-stem-education-resources-toolkit/introductory-material-what-stem/why-stem-important"
                target="_blank"
                rel="noreferrer">
                critical thinking, creativity, collaboration, and problem-solving
              </a>
              . Demand for these skills is high, but the lack of diversity in STEM means we have a
              workforce that is limited in size and missing a broad range of perspectives.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header">
            <Typography variant="h4" component="h2">
              Do I need to be an experienced researcher to use the Portal?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              No. The Portal is designed to make evaluation easy for anyone, especially those who
              are not experienced researchers. It breaks evaluation down into five simple steps. The
              Portal includes links to resources with additional advice and guidance for those who
              want to know more.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header">
            <Typography variant="h4" component="h2">
              What are the benefits of evaluating?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              There&apos;s always room for improvement, and the benefits of evaluation can help your
              organisation thrive in an increasingly competitive world.
            </Typography>
            <Typography variant="body" component="p" mb={2}>
              Here are just six reasons to evaluate.
            </Typography>
            <ol>
              <li>
                Measure project outcomes. Evaluation is essential to gauge the effectiveness of your
                program. It helps you know whether your program is doing what it is meant to do.
              </li>
              <li>
                Use resources efficiently. Evaluation allows you to justify and account for your
                program resources and investment. Knowing what works and does not work helps you use
                resources efficiently.
              </li>
              <li>
                Attract and keep funding and partners. Transparently sharing your evaluation can
                help you attract and keep sponsors and partners. Results show accountability and
                demonstrate value and worth.
              </li>
              <li>
                Improve and scale up. Evaluation allows you to refine your program to improve it.
                For Government and sponsors, it informs decisions on what programs should be
                extended or scaled up.
              </li>
              <li>
                Generate useful data. Evaluations can help us understand the issues that affect
                underrepresented groups in STEM. Evaluation data over time allows us to learn what programs
                work, for whom and why.
              </li>
              <li>
                Promote best practice and collaboration. Evaluation can inform and guide best
                practice for STEM equity programs.
              </li>
            </ol>
            <Typography variant="body" component="p" mb={2}>
              <a
                href="evaluate-programs/benefits-of-evaluating-programs"
                target="_blank"
                rel="noreferrer">
                Read the article about the benefits of evaluating.
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9a-content"
            id="panel9a-header">
            <Typography variant="h4" component="h2">
              Who does the evaluation?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              You and/or your organisation do the evaluation. Create a profile on the Portal, then
              start creating your evaluation plan. Then, when you have finished your evaluation
              according to your plan, come back to the Portal to report on your findings and share
              your completed evaluation.
            </Typography>
            <Typography variant="body" component="p" mb={2}>
              You need adequate resources to undertake your evaluation. Consider the resources you
              will need: time (how long things will take); human (staff, volunteers); financial
              (budget); material (consumables, infrastructure) resources.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10a-content"
            id="panel10a-header">
            <Typography variant="h4" component="h2">
              Who pays for the evaluation?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              You and/or your organisation pay for the evaluation. However, you can use the STEM
              Equity Evaluation Portal and associated resources for free.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11a-content"
            id="panel11a-header">
            <Typography variant="h4" component="h2">
              How long does the evaluation take?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              The time it takes to conduct an evaluation depends on several factors. How long does
              your program run? When and how often will you collect data? Do you have reporting
              deadlines for funders, sponsors and partners? These considerations will influence how
              long it takes to conduct your evaluation.
            </Typography>
            <Typography variant="body" component="p" mb={2}>
              For example, if you run a 1-week program for school students and collect survey
              responses from the students before and after the program, your evaluation may take a
              few weeks. However, if you implement a new parental leave policy in your organisation,
              you may collect monitoring data every six months over two years. In this case, your
              evaluation will take two years to complete.
            </Typography>
            <Typography variant="body" component="p" mb={2}>
              There is no deadline. However, your organisation&apos;s best interest is to ensure the
              evaluation is done promptly and doesn&apos;t drag out too long.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12a-content"
            id="panel12a-header">
            <Typography variant="h4" component="h2">
              What happens with the evaluation results?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body" component="p" mb={2}>
              You can share the results of your evaluation on the Portal for others to learn from.
              Openly sharing your findings is a crucial step in the evaluation process. It makes you
              accountable to your funders, partners and other stakeholders. It&apos;s also a way to
              celebrate your achievements.
            </Typography>
            <Typography variant="body" component="p" mb={2}>
              Do not cherry-pick the results you share. Present everything and be transparent and
              honest. The purpose of the evaluation is to understand what works or does not work,
              how, for whom, and why. If you only report the good, others cannot assess the program
              or learn from it. Reporting negative results is as important as reporting the
              positive. Maybe even more important. Negative results can help us identify what
              doesn&apos;t work. Then, we can either avoid those things or try to improve them.
              Negative results don&apos;t reflect poorly on your program or organisation. Instead,
              they show leadership and initiative—you tried and tested something new.
            </Typography>
            <Typography variant="body" component="p" mb={2}>
              There is also the option to keep the findings private in your Portal account if this
              option is more suitable for you.
            </Typography>
            <Typography variant="body" component="p" mb={2}>
              <a
                href="evaluate-programs/benefits-of-publishing-programs"
                target="_blank"
                rel="noreferrer">
                Read the article on the benefits of sharing your evaluation.
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default Help;
