import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const ANALYSIS_APPROACHES_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=AnalysisApproaches`;

const initialState = {
  execute: [],
  status: 'idle',
  error: null
};

export const fetchAnalysisApproaches = createAsyncThunk(
  'execute/fetchAnalysisApproaches',
  async () => {
    try {
      const response = await axios.get(ANALYSIS_APPROACHES_URL);
      return response.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const executeSlice = createSlice({
  name: 'execute',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAnalysisApproaches.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAnalysisApproaches.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.analysisApproaches = action.payload;
      })
      .addCase(fetchAnalysisApproaches.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const selectAnalysisApproaches = (state) => state.execute.analysisApproaches;
export const getStatus = (state) => state.execute.status;
export const getError = (state) => state.execute.error;

export default executeSlice.reducer;
