import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import styles from './OnlineUsePolicy.module.scss';

const OnlineUsePolicy = () => {
  return (
    <Container>
      <Grid container spacing={4} className={styles['main-content']}>
        <Grid item sm={12}>
          <Typography variant="h1" component="h1" mb={2}>
            Online use policy
          </Typography>
          <Typography variant="body" component="p">
            The STEM Equity Evaluation Portal (the Portal) aims to provide a safe environment for
            the STEM Equity community to share opportunities through open communication and
            collaboration (<strong>Purpose</strong>).
          </Typography>
          <Typography variant="body" component="p">
            Your access and use of the Portal must align with this purpose and is subject to
            compliance with the <a href="/terms-and-conditions">Terms and Conditions</a>,{' '}
            <a href="/privacy-policy">Privacy Statement</a> and all applicable policies and
            procedures. We have the right to take down any profile or content from the Portal and
            deny access to you for violation of these at our sole discretion and without notice.
          </Typography>
          <Typography variant="body" component="p">
            Please contact{' '}
            <a href="mailto:eval.womeninstem@unsw.edu.au">eval.womeninstem@unsw.edu.au</a> to give
            feedback or to report violations of this online use policy.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OnlineUsePolicy;
