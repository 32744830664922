import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import {
  Alert,
  Box,
  // CircularProgress,
  Snackbar,
  Typography
} from '@mui/material';
import ResponsiveDrawer from '../../components/Drawer';
import FileUpload from '../../components/FileUpload';
import FileList from '../../components/FileUpload/FileList';
import { AuthContext } from '../../services/context/AuthProvider';
import styles from './Files.module.scss';
import utils from '../../helpers/utils';

const Files = () => {
  const { getSession } = useContext(AuthContext);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // const [authorId, setAuthorId] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSession().then((data) => {
      // setAuthorId(data.sub);
      localStorage.setItem('authorId', data.sub);
    });
  }, []);

  const authorId = localStorage.getItem('authorId');

  const removeFile = (filename) => {
    console.log('filename', filename);
    setSuccessMessage(true);
    setFiles(files.filter((file) => file.name !== filename));
  };

  const loadFiles = useCallback(async () => {
    const accessToken = await utils.getValidAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'multipart/form-data'
      }
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/${authorId}/uploads`,
        config
      );
      setUploadedFiles(res.data);
      return res.data;
    } catch (err) {
      console.error(err.message);
      console.log('error', err.message);
      return err.message;
    }
  }, []);

  useEffect(() => {
    loadFiles();
  }, [loadFiles, files]);

  // useEffect(() => {}, [uploadedFiles]);

  const handleClose = () => {
    setSuccessMessage(false);
  };

  return (
    <>
      <ResponsiveDrawer />
      <Box component="main" className="dashboard-container">
        <Typography variant="h1" component="h1">
          Files
        </Typography>
        {successMessage && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={successMessage}
            autoHideDuration={6000}
            onClose={handleClose}>
            <Alert elevation={2} onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              File is successfully deleted!
            </Alert>
          </Snackbar>
        )}
        <FileUpload
          url={`${process.env.REACT_APP_API_URL}/users/${authorId}/uploads`}
          files={files}
          setFiles={setFiles}
          removeFile={removeFile}
        />
        <FileList files={uploadedFiles} fileType="UserFile" removeFile={removeFile} />

        {uploadedFiles && uploadedFiles.length === 0 && (
          <Box className={styles['loading']}>
            {/* <CircularProgress /> */}
            <Typography>No uploaded files.</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Files;
