import React from 'react';
import PropTypes from 'prop-types';
import Modal from './ModalPortal';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  //   Fade,
  Grid,
  IconButton,
  //   Slide,
  Typography
} from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../services/context/AuthProvider';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Modal.module.scss';

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const PasswordResetSuccessfullModal = ({ closeFn = () => null, open = false }) => {
  return (
    <Modal open={open} onClose={closeFn}>
      <Dialog
        maxWidth="sm"
        onClose={closeFn}
        open={open}
        aria-labelledby="modal-wrapper"
        className={styles['modal-wrapper']}
        // TransitionComponent={Transition}
        transitionDuration={500}>
        <DialogTitle className={styles['header-title']} onClose={closeFn}>
          Password reset successful!
          <IconButton
            aria-label="close"
            onClick={closeFn}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={`${styles['modal-content']} ${styles['login-wrapper']}`}
          sx={{ p: 5 }}>
          <Grid container maxWidth="xs">
            <Grid item xs={12} mb={2}>
              <Typography mb={2} textAlign="left">
                Awesome! Your password has been updated successfully. Please login with the updated
                password.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                className={styles['btn']}
                data-modal="modal-two">
                Login
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Modal>
  );
};

PasswordResetSuccessfullModal.propTypes = {
  closeFn: PropTypes.func,
  open: PropTypes.bool,
  setModal: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.object
};

export default PasswordResetSuccessfullModal;
