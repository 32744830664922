import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Modal.module.scss';

const CustomizedDialogs = ({ size, onClose, open, modalTitle, children }) => {
  return (
    <Dialog
      maxWidth={size}
      onClose={onClose}
      aria-labelledby="modal-wrapper"
      className={styles['modal-wrapper']}
      open={open}>
      <DialogTitle className={styles['header-title']} onClose={onClose}>
        {modalTitle}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

CustomizedDialogs.propTypes = {
  open: PropTypes.bool,
  size: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
  modalTitle: PropTypes.string
};

export default CustomizedDialogs;
