import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal from './ModalPortal';
import {
  Alert,
  //   Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  //   Fade,
  FormControl,
  Grid,
  IconButton,
  //   InputLabel,
  //   InputAdornment,
  //   Slide,
  TextField,
  Typography
  //   Typography
  //   Typography
} from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../services/context/AuthProvider';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Modal.module.scss';

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const ForgotPassword = ({
  closeFn = () => null,
  open = false,
  setModal,
  setFormData,
  formData
}) => {
  const [error, setError] = useState('');
  // const [formData, setFormData] = useState([]);

  //   const { authenticate } = useContext(AuthContext);
  const {
    control,
    handleSubmit
    // watch,
    // formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      Email: '',
      Action: 'Send Confirmation Code'
    }
  });

  const onSubmit = async (data) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/forgotPassword`, data);
      console.log(res);
      setError(null);
      // setFormData(data);
      setFormData({ ...formData, email: data.Email });
      setModal('confirmation');
      return res.data;
    } catch (err) {
      setError(err.response?.data?.Message);
      console.log('error', err.response.Message);
      return err.response.data.Message;
    }

    // event.preventDefault();
    // console.log(data);
    // authenticate(data.Email, data.Password)
    //   .then((data) => {
    //     console.log('Logged in!', data);
    //     navigate('/dashboard');
    //   })
    //   .catch((err) => {
    //     console.error('Failed to login', err.name);
    //     if (err.name === 'InvalidParameterException') {
    //       setError('Please fill out all required fields');
    //     }
    //     if (err.name === 'NotAuthorizedException') {
    //       setError('Incorrect email or password.');
    //     }
    //     if (err.name === 'UserNotFoundException') {
    //       setError('User account does not exist.');
    //     }
    //     // setError(err.message);
    //   });
  };

  return (
    <Modal open={open} onClose={closeFn}>
      <Dialog
        maxWidth="sm"
        onClose={closeFn}
        open={open}
        aria-labelledby="modal-wrapper"
        className={styles['modal-wrapper']}
        // TransitionComponent={Transition}
        transitionDuration={500}>
        <DialogTitle className={styles['header-title']} onClose={closeFn}>
          Forgot your password?
          <IconButton
            aria-label="close"
            onClick={closeFn}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={`${styles['modal-content']} ${styles['login-wrapper']}`}
          sx={{ p: 5 }}>
          {error && (
            <Alert
              variant="filled"
              severity="error"
              className={styles['error-message']}
              sx={{ mb: 4 }}>
              {error}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container maxWidth="xs">
              <Grid item xs={12} mb={2}>
                <Controller
                  name="Email"
                  control={control}
                  rules={{
                    required: 'Email is required.',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email format.'
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <Typography mb={2} textAlign="left">
                        Enter your email address to receive a confirmation code.
                      </Typography>
                      <TextField
                        {...field}
                        label="Email"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" fullWidth className={styles['btn']}>
                  Continue
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Modal>
  );
};

ForgotPassword.propTypes = {
  closeFn: PropTypes.func,
  open: PropTypes.bool,
  setModal: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.object
};

export default ForgotPassword;
