import React from 'react';
import PropTypes from 'prop-types';
import FileItem from './FileItem';
import axios from 'axios';
import styles from './FileUpload.module.scss';
import utils from '../../helpers/utils'

const FileList = ({ fileType, files, removeFile }) => {
  const deleteFileHandler = async (uploadType, fileContext, objectId) => {
    const accessToken = await utils.getValidAccessToken();
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/uploads`,
        {
          UploadType: uploadType,
          FileContext: fileContext,
          ObjectId: objectId
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then((res) => {
        removeFile(uploadType, fileContext, objectId);
        return res.data;
      })
      .catch((err) => console.error(err));
  };
  return (
    <ul className={styles['file-list']}>
      {files &&
        files?.map((file) => (
          <FileItem
            key={file.name}
            file={file}
            fileType={fileType}
            deleteFile={deleteFileHandler}
          />
        ))}
    </ul>
  );
};

FileList.propTypes = {
  fileType: PropTypes.string,
  files: PropTypes.array,
  removeFile: PropTypes.func
};

export default FileList;
