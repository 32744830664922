import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import axios from 'axios';
import Modal from './ModalPortal';
import {
  Alert,
  //   Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  //   Fade,
  FormControl,
  Grid,
  IconButton,
  //   InputLabel,
  //   InputAdornment,
  //   Slide,
  TextField,
  Typography
  //   Typography
  //   Typography
} from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../services/context/AuthProvider';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Modal.module.scss';

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const ConfirmationModal = ({
  closeFn = () => null,
  open = false,
  setModal,
  formData,
  setFormData
}) => {
  const [error, setError] = useState('');
  //   const [showPassword, setShowPassword] = useState(false);

  //   const { authenticate } = useContext(AuthContext);
  const {
    control,
    handleSubmit,
    setValue
    // watch,
    // formState: { errors }
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      Email: formData.email,
      Action: 'Confirm Forgot Password',
      ConfirmationCode: ''
    }
  });

  const onSubmit = async (data) => {
    try {
      // const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/forgotPassword`, data);
      // console.log(res);
      setError(null);
      setValue('Email', formData.email);
      setFormData({ ...formData, Email: formData.email, ConfirmationCode: data.ConfirmationCode });
      setModal('reset-password');
      // return res.data;
    } catch (err) {
      setError(err.response?.data?.Message);
      console.log('error', err.response.Message);
      return err.response.data.Message;
    }
  };
  return (
    <Modal open={open} onClose={closeFn}>
      <Dialog
        maxWidth="sm"
        onClose={closeFn}
        open={open}
        aria-labelledby="modal-wrapper"
        className={styles['modal-wrapper']}
        // TransitionComponent={Transition}
        transitionDuration={500}>
        <DialogTitle className={styles['header-title']} onClose={closeFn}>
          Account verification
          <IconButton
            aria-label="close"
            onClick={closeFn}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={`${styles['modal-content']} ${styles['login-wrapper']}`}
          sx={{ p: 5 }}>
          {error && (
            <Alert
              variant="filled"
              severity="error"
              className={styles['error-message']}
              sx={{ mb: 4 }}>
              {error}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container maxWidth="xs">
              <Grid item xs={12} mb={2}>
                <Controller
                  name="ConfirmationCode"
                  control={control}
                  rules={{
                    required: 'Confirmation code is required.',
                    minLength: {
                      value: 6,
                      message: 'Confirmation code should not be less than 6.'
                    },
                    maxLength: {
                      value: 6,
                      message: 'Confirmation code should not be greater than 6.'
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <Typography mb={2} textAlign="left">
                        Please confirm your account by entering the confirmation code sent to your
                        email.
                      </Typography>
                      <TextField
                        {...field}
                        label="Confirmation Code"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" fullWidth className={styles['btn']}>
                  Verify
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  closeFn: PropTypes.func,
  open: PropTypes.bool,
  setModal: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.object
};

export default ConfirmationModal;
