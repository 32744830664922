import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1EABBA'
    },
    secondary: {
      main: '#29807F'
    },
    grey: {
      main: '#7A7A7A'
    },
    dark: {
      main: '#000000'
    },
    white: {
      main: '#FFFFFF'
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Montserrat',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 700
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 700
    },
    body1: {
      fontSize: '1rem'
    },
    body2: {
      fontSize: '0.9rem'
    }
  },
  components: {
    // Name of the component
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '16px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '16px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '16px'
        }
      }
    }
  }
});

theme.typography.h1 = {
  fontSize: '2rem',
  '@media (min-width:600px)': {
    fontSize: '2rem'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem'
  }
};

theme.typography.h2 = {
  fontSize: '1.5rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem'
  }
};

theme.typography.h3 = {
  fontSize: '1.3rem',
  '@media (min-width:600px)': {
    fontSize: '1.3rem'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem'
  }
};

theme.typography.h4 = {
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1rem'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem'
  }
};

// theme.typography.body1 = {
//   fontSize: '0.8rem',
//   '@media (min-width:600px)': {
//     fontSize: '0.8rem'
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '1rem'
//   }
// };

// theme.typography.body2 = {
//   fontSize: '0.7rem',
//   '@media (min-width:600px)': {
//     fontSize: '0.7rem'
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '0.8rem'
//   }
// };

export default theme;
