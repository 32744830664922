import React from 'react';
import { Box, Typography } from '@mui/material';
import ResponsiveDrawer from '../../components/Drawer';

const Contacts = () => {
  return (
    <>
      <ResponsiveDrawer />
      <Box component="main" className="dashboard-container">
        <Typography variant="h1" component="h1">
          Contacts
        </Typography>
      </Box>
    </>
  );
};

export default Contacts;
