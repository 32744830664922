import React, { useState, useEffect, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  NativeSelect,
  TextField,
  Tooltip,
  Typography,
  Grid,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import Resources from '../../components/Resources';
import styles from './Evaluation.module.scss';
import { fetchInstruments } from '../../services/features/design/designSlice';
import chunk from '../../helpers/arrayChunk';

const Design = ({
  control,
  setFormData,
  formData,
  errors,
  indicatorCode,
  filteredIndicators,
  mappedInstruments,
  designApproachItems,
  dataCollectionMethodItems,
  selectedInstruments,
  indicatorsCount,
  setChunkedInstruments,
  onClick,
  dataType,
  setDataType,
  count,
  setCount,
  handleFieldChange,
  getValues,
  setValue
}) => {
  const approachTooltip = (
    <p className={styles['tooltip']}>
      <strong>POSTTEST:</strong> Collect data after the program. <br />
      <strong>PRETEST-POSTTEST:</strong> Collect data before and after the program.
      <br />
      <strong>TIME SERIES:</strong> Collect data over regular intervals of time.
      <br />
      <strong>RETROSPECTIVE PRETEST-POSTTEST:</strong> Collect data retrospectively (pretest) and
      also after the program (posttest).
      <br />
      <strong>TRACKING AND METRICS:</strong> Numerical data about a particular process, environment,
      or activity.
      <br />
      Read more about{' '}
      <a
        href="/static/media/National-Evaluation-Guide.1d2e6cf1fa612edd8156.pdf"
        target="_blank"
        rel="noreferrer">
        evaluation approaches
      </a>
    </p>
  );

  const collectionTooltip = (
    <p className={styles['tooltip']}>
      <strong>QUANTITATIVE:</strong> Uses numerical data that can be analysed using statistics.{' '}
      <br />
      <strong>QUALITATIVE:</strong> Use data in the form of text, images, audio and video
      recordings.
      <br />
      <strong>MIXED:</strong> Uses both quantitative and qualitative data collection methods.
      <br />
      Read more about{' '}
      <a
        href="/static/media/National-Evaluation-Guide.1d2e6cf1fa612edd8156.pdf"
        target="_blank"
        rel="noreferrer">
        data types and collection methods
      </a>
    </p>
  );

  const dispatch = useDispatch();

  const [instruments, setInstruments] = useState(getValues('Instruments') || []);
  const [indicators, setIndicators] = useState(getValues('Indicators') || []);
  const [collectionMethods, setCollectionMethods] = useState(
    getValues('DataCollectionMethods') || []
  );
  const [designApproaches, setDesignApproaches] = useState(getValues('EvaluationApproaches') || []);

  useEffect(() => {
    dispatch(fetchInstruments(indicatorCode.toString()));
  }, [dispatch]);

  const mergedIndicators = [].concat.apply([], filteredIndicators);

  useEffect(() => {
    setIndicators(mergedIndicators);
  }, [mergedIndicators, indicators]);

  const handleInstrumentsChange = (e) => {
    const { value, name } = e.target;
    setInstruments(value);
    setValue('Instruments', value);
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const chunkInstruments = useCallback(() => {
    const instruments = chunk(selectedInstruments, indicatorsCount);
    setChunkedInstruments(instruments);
  }, [instruments, selectedInstruments, indicatorsCount]);

  useEffect(() => {
    chunkInstruments();
  }, [chunkInstruments]);

  const handleCollectionMethodsChange = (e) => {
    const { value, name } = e.target;
    setCollectionMethods(value);

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDesignApproachChange = (e) => {
    const { value, name } = e.target;
    setDesignApproaches(value);

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDataTypeChange = (e) => {
    const { value, name } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value
    }));
  };

  useEffect(() => {
    if (formData?.DataType2) {
      setDataType({ ...dataType, dataType2: true });
      setCount(1);
    }
    if (formData?.DataType2 && formData?.DataType3) {
      setDataType({ ...dataType, dataType2: true, dataType3: true });
      setCount(2);
    }
  }, []);

  // Remove form fields and reset values to empty string
  const hideDataType = (e, id) => {
    e.preventDefault();
    if (id === 'dataType2') {
      setDataType({ ...dataType, dataType2: false });
      setCount(Number(count) - 1);
      setFormData((formData) => ({
        ...formData,
        DataType2: '',
        DataStored2: '',
        DataAccess2: '',
        DataDuration2: ''
      }));
      setValue('DataType2', '');
      setValue('DataStored2', '');
      setValue('DataAccess2', '');
      setValue('DataDuration2', '');
    } else if (id === 'dataType3') {
      setDataType({ ...dataType, dataType3: false });
      setCount(Number(count) - 1);
      setFormData((formData) => ({
        ...formData,
        DataType3: '',
        DataStored3: '',
        DataAccess3: '',
        DataDuration3: ''
      }));
      setValue('DataType3', '');
      setValue('DataStored3', '');
      setValue('DataAccess3', '');
      setValue('DataDuration3', '');
    } else {
      setDataType(...dataType);
    }
  };

  return (
    <>
      <Paper elevation={2} sx={{ p: 4, mb: 5 }}>
        <Typography variant="h3" component="h2" color="primary" mb={4}>
          Design
        </Typography>

        {Object.values(errors).length !== 0 && (
          <Alert variant="filled" severity="error" sx={{ mb: 3 }}>
            Please fill out all required fields.
          </Alert>
        )}
        <Typography variant="subtitle" component="p" mb={2} sx={{ fontSize: '14px' }}>
          * Required information
        </Typography>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ mb: 0 }}>
            <Box component="div">
              <Typography variant="h4" component="h3">
                Data collection tool | Survey, interview, tracking and metrics?
                <small>
                  Data collection tools are what you will use to collect data. Tools can be surveys
                  or interview questionnaires, but they can also be data tracking.
                  <br />
                  <br />
                  The data collection tools in the dropdown menus below are specifically related to
                  the indicators you chose in the Plan stage. Select at least one tool for each
                  indicator.
                </small>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box className={styles['indicators-title']}>
                  <Typography variant="h5" component="h4" mb={2}>
                    Your indicators (from the Plan Stage)
                  </Typography>
                  <Typography variant="h5" component="h4" mb={2}>
                    Related data collection tools{' '}
                    <small>There should be at least 1 selected value</small>
                  </Typography>
                </Box>

                <Box className={styles['indicators-column']}>
                  <Box>
                    {indicators?.map((item, index) => {
                      return (
                        <FormControl key={index} fullWidth size="small" sx={{ mt: 2 }}>
                          <TextField
                            size="small"
                            name="Indicators"
                            value={item.Value}
                            placeholder="Please provide details"
                          />
                        </FormControl>
                      );
                    })}
                  </Box>
                  <Box>
                    {mappedInstruments !== null &&
                      mappedInstruments?.map((item, index) => {
                        return (
                          <Controller
                            key={index}
                            name="Instruments"
                            control={control}
                            type="text"
                            defaultValue={[]}
                            rules={{ required: 'Data collection tool is required.' }}
                            render={({ field, fieldState: { error } }) => (
                              <FormControl fullWidth size="small" sx={{ mt: 2 }} error={!!error}>
                                <InputLabel id="instruments-label">
                                  Select a data collection tool *
                                </InputLabel>
                                <Select
                                  {...field}
                                  labelId="instruments-label"
                                  label="Select a data collection tool *"
                                  multiple
                                  onChange={(e) => {
                                    handleInstrumentsChange(e, field);
                                    field.onChange(e);
                                  }}
                                  input={
                                    <OutlinedInput
                                      label="Select a data collection tool"
                                      className={styles['selected-values']}
                                    />
                                  }>
                                  {item.map((value, i) => {
                                    const description = value.Description;
                                    return (
                                      <MenuItem
                                        key={i}
                                        value={value.Value}
                                        className="multi-checkboxes">
                                        <Checkbox checked={instruments.indexOf(value.Value) > -1} />
                                        <ListItemText primary={value.Value} />
                                        {description !== undefined && (
                                          <Typography pl={3} className="checkbox-description">
                                            {description?.substr(0, 200) + '...'}{' '}
                                            <a
                                              href={`${process.env.PUBLIC_URL}/files/instruments/${value.Value}.pdf`}
                                              target="_blank"
                                              rel="noreferrer">
                                              Read more
                                            </a>
                                          </Typography>
                                        )}
                                      </MenuItem>
                                    );
                                  })}
                                  <MenuItem value="Other">
                                    <Checkbox checked={instruments.indexOf('Other') > -1} />
                                    <ListItemText>
                                      Other - provide details in the box below
                                    </ListItemText>
                                  </MenuItem>
                                </Select>
                                {error && <FormHelperText error>{error?.message}</FormHelperText>}
                              </FormControl>
                            )}
                          />
                        );
                      })}
                  </Box>
                </Box>
              </Grid>

              <Grid item sm={12}>
                <Typography variant="h5" component="h4">
                  Please provide details and/or identify any additional data collection tool(s).
                </Typography>

                <Controller
                  control={control}
                  name="InstrumentDetails"
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        onChange={(e) => {
                          handleFieldChange(e, field);
                          field.onChange(e);
                        }}
                        multiline
                        rows={4}
                        placeholder="Please provide details (optional)"
                        size="small"
                        fullWidth
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Evaluation approach | Which approach will you use?
                <small>
                  You can carry out your evaluation and collect data in many ways. The five common
                  approaches are: posttest, pretest/posttest, time series, retrospective
                  pretest/posttest, and tracking and metrics.
                  <br />
                  <br />
                  Click the information icon to find out more about the five common approaches.{' '}
                  <Tooltip disableFocusListener title={approachTooltip}>
                    <IconButton onClick={(e) => e.preventDefault()}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </small>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name="EvaluationApproaches"
              control={control}
              type="text"
              defaultValue={[]}
              rules={{ required: 'Evaluation approach is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" error={!!error}>
                  <InputLabel id="evaluation-approaches">
                    Select an evaluation approach *
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="evaluation-approaches"
                    label="Select an evaluation approach *"
                    multiple
                    onChange={(e) => {
                      handleDesignApproachChange(e, field);
                      field.onChange(e);
                    }}
                    renderValue={(selected) => selected.join(', ')}>
                    {designApproachItems?.map((approach) => (
                      <MenuItem
                        key={approach.Code}
                        value={approach.Value}
                        className="multi-checkboxes">
                        <Checkbox checked={designApproaches.indexOf(approach.Value) > -1} />
                        <ListItemText primary={approach.Value} />
                      </MenuItem>
                    ))}
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="EvaluationApproachDetails"
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleFieldChange(e, field);
                      field.onChange(e);
                    }}
                    multiline
                    rows={4}
                    placeholder="Please provide details (optional)"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Data collection | What data collection method will you use?
                <small>
                  There are three main types of data and ways to collect them: quantitative,
                  qualitative, and mixed methods.
                  <br />
                  <br />
                  Click the information icon to learn more about the three types of data collection.{' '}
                  <Tooltip disableFocusListener title={collectionTooltip}>
                    <IconButton onClick={(e) => e.preventDefault()}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </small>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name="DataCollectionMethods"
              control={control}
              type="text"
              defaultValue={[]}
              rules={{ required: 'Data collection is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" error={!!error}>
                  <InputLabel id="data-collection">Select a data collection *</InputLabel>
                  <Select
                    {...field}
                    labelId="data-collection"
                    label="Select a data collection *"
                    multiple
                    onChange={(e) => {
                      handleCollectionMethodsChange(e, field);
                      field.onChange(e);
                    }}
                    renderValue={(selected) => selected.join(', ')}>
                    {dataCollectionMethodItems?.map((collection) => (
                      <MenuItem
                        key={collection.Code}
                        value={collection.Value}
                        className="multi-checkboxes">
                        <Checkbox checked={collectionMethods.indexOf(collection.Value) > -1} />
                        <ListItemText primary={collection.Value} />
                      </MenuItem>
                    ))}
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="DataCollection"
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleFieldChange(e, field);
                      field.onChange(e);
                    }}
                    multiline
                    rows={4}
                    placeholder="Please provide details (optional)"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Data management plan | Where and how will you safeguard your data?
                <small>
                  Consult the{' '}
                  <a
                    href="https://www.oaic.gov.au/privacy/the-privacy-act"
                    target="_blank"
                    rel="noreferrer">
                    Australian Privacy Act
                  </a>
                  .
                </small>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {dataType.dataType1 === true && (
              <Box className={styles['plan-items']}>
                <Box className={styles['plan-item']}>
                  <Typography variant="h5" component="h4">
                    Data Type 1
                  </Typography>

                  <Controller
                    name="DataType1"
                    control={control}
                    type="text"
                    defaultValue=""
                    rules={{ required: 'Data type is required.' }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl
                        size="small"
                        fullWidth
                        className={styles['plan-field']}
                        error={!!error}>
                        <InputLabel id="data-type-label-1">Select a data type *</InputLabel>
                        <NativeSelect
                          {...field}
                          onChange={(e) => {
                            handleDataTypeChange(e, field);
                            field.onChange(e);
                          }}
                          id="data-type-1"
                          name="DataType1"
                          label="Select a data type *"
                          input={
                            <OutlinedInput
                              label="Select a data type"
                              inputProps={{ 'data-id': 0 }}
                            />
                          }>
                          <option aria-label="Select a data type" value="" />
                          <option value="Hard Copies">Hard Copies</option>
                          <option value="Electronic Copies">Electronic Copies</option>
                          <option value="Audio/Visual">Audio/Visual</option>
                        </NativeSelect>
                        {error && <FormHelperText error>{error?.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Box>

                <Box className={styles['plan-item']}>
                  <Typography variant="h5" component="h4">
                    How are data stored securely?
                  </Typography>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <FormControl fullWidth size="small" className={styles['plan-field']}>
                        <TextField
                          {...field}
                          onChange={(e) => {
                            handleDataTypeChange(e, field);
                            field.onChange(e);
                          }}
                          multiline
                          rows={4}
                          placeholder="Please provide details *"
                          size="small"
                          fullWidth
                          inputProps={{ 'data-id': 0 }}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      </FormControl>
                    )}
                    name="DataStored1"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'This field is required' }}
                  />
                </Box>

                <Box className={styles['plan-item']}>
                  <Typography variant="h5" component="h4">
                    How are data access restricted?
                  </Typography>

                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <FormControl
                        fullWidth
                        size="small"
                        className={styles['plan-field']}
                        error={!!error}>
                        <TextField
                          {...field}
                          onChange={(e) => {
                            handleDataTypeChange(e, field);
                            field.onChange(e);
                          }}
                          multiline
                          rows={4}
                          placeholder="Please provide details *"
                          size="small"
                          fullWidth
                          inputProps={{ 'data-id': 0 }}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      </FormControl>
                    )}
                    name="DataAccess1"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'This field is required' }}
                  />
                </Box>

                <Box className={styles['plan-item']}>
                  <Typography variant="h5" component="h4">
                    How long will data be kept?
                  </Typography>

                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <FormControl
                        fullWidth
                        size="small"
                        className={styles['plan-field']}
                        error={!!error}>
                        <TextField
                          {...field}
                          onChange={(e) => {
                            handleDataTypeChange(e, field);
                            field.onChange(e);
                          }}
                          multiline
                          rows={4}
                          placeholder="Please provide details *"
                          size="small"
                          fullWidth
                          inputProps={{ 'data-id': 0 }}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      </FormControl>
                    )}
                    name="DataDuration1"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'This field is required' }}
                  />
                </Box>
              </Box>
            )}

            {dataType.dataType2 === true && (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<CloseIcon />}
                  onClick={(e) => hideDataType(e, 'dataType2')}
                  size="small"
                  className={styles['remove-btn']}>
                  Remove
                </Button>
                <Box className={styles['plan-items']}>
                  <Box className={styles['plan-item']}>
                    <Typography variant="h5" component="h4">
                      Data Type 2
                    </Typography>

                    <Controller
                      name="DataType2"
                      control={control}
                      type="text"
                      defaultValue=""
                      rules={{ required: 'Data type is required.' }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          size="small"
                          fullWidth
                          className={styles['plan-field']}
                          error={!!error}>
                          <InputLabel id="data-type-label-1">Select a data type *</InputLabel>
                          <NativeSelect
                            {...field}
                            onChange={(e) => {
                              handleDataTypeChange(e, field);
                              field.onChange(e);
                            }}
                            id="data-type-2"
                            name="DataType2"
                            label="Select a data type *"
                            input={
                              <OutlinedInput
                                label="Select a data type"
                                inputProps={{ 'data-id': 1 }}
                              />
                            }>
                            <option aria-label="Select a data type" value="" />
                            <option
                              value="Hard Copies"
                              disabled={formData?.DataType1 === 'Hard Copies'}>
                              Hard Copies
                            </option>
                            <option
                              value="Electronic Copies"
                              disabled={formData?.DataType1 === 'Electronic Copies'}>
                              Electronic Copies
                            </option>
                            <option
                              value="Audio/Visual"
                              disabled={formData?.DataType1 === 'Audio/Visual'}>
                              Audio/Visual
                            </option>
                          </NativeSelect>
                          {error && <FormHelperText error>{error?.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Box>

                  <Box className={styles['plan-item']}>
                    <Typography variant="h5" component="h4">
                      How are data stored securely?
                    </Typography>

                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          fullWidth
                          size="small"
                          className={styles['plan-field']}
                          error={!!error}>
                          <TextField
                            {...field}
                            onChange={(e) => {
                              handleDataTypeChange(e, field);
                              field.onChange(e);
                            }}
                            multiline
                            rows={4}
                            placeholder="Please provide details *"
                            size="small"
                            fullWidth
                            inputProps={{ 'data-id': 1 }}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                      name="DataStored2"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'This field is required' }}
                    />
                  </Box>
                  <Box className={styles['plan-item']}>
                    <Typography variant="h5" component="h4">
                      How are data access restricted?
                    </Typography>

                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          fullWidth
                          size="small"
                          className={styles['plan-field']}
                          error={!!error}>
                          <TextField
                            {...field}
                            onChange={(e) => {
                              handleDataTypeChange(e, field);
                              field.onChange(e);
                            }}
                            multiline
                            rows={4}
                            placeholder="Please provide details *"
                            size="small"
                            fullWidth
                            inputProps={{ 'data-id': 1 }}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                      name="DataAccess2"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'This field is required' }}
                    />
                  </Box>
                  <Box className={styles['plan-item']}>
                    <Typography variant="h5" component="h4">
                      How long will data be kept?
                    </Typography>

                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          fullWidth
                          size="small"
                          className={styles['plan-field']}
                          error={!!error}>
                          <TextField
                            {...field}
                            onChange={(e) => {
                              handleDataTypeChange(e, field);
                              field.onChange(e);
                            }}
                            multiline
                            rows={4}
                            placeholder="Please provide details *"
                            size="small"
                            fullWidth
                            inputProps={{ 'data-id': 1 }}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                      name="DataDuration2"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'This field is required' }}
                    />
                  </Box>
                </Box>
              </>
            )}

            {dataType.dataType3 === true && (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<CloseIcon />}
                  onClick={(e) => hideDataType(e, 'dataType3')}
                  size="small"
                  className={styles['remove-btn']}>
                  Remove
                </Button>
                <Box className={styles['plan-items']}>
                  <Box className={styles['plan-item']}>
                    <Typography variant="h5" component="h4">
                      Data Type 3
                    </Typography>
                    <Controller
                      name="DataType3"
                      control={control}
                      type="text"
                      defaultValue=""
                      rules={{ required: 'Data type is required.' }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          size="small"
                          fullWidth
                          className={styles['plan-field']}
                          error={!!error}>
                          <InputLabel id="data-type-label-1">Select a data type *</InputLabel>
                          <NativeSelect
                            {...field}
                            id="data-type-3"
                            name="DataType3"
                            label="Select a data type *"
                            onChange={(e) => {
                              handleDataTypeChange(e, field);
                              field.onChange(e);
                            }}
                            input={
                              <OutlinedInput
                                label="Select a data type"
                                inputProps={{ 'data-id': 2 }}
                              />
                            }>
                            <option aria-label="Select a data type" value="" />
                            <option
                              value="Hard Copies"
                              disabled={
                                formData?.DataType1 === 'Hard Copies' ||
                                formData?.DataType2 === 'Hard Copies'
                              }>
                              Hard Copies
                            </option>
                            <option
                              value="Electronic Copies"
                              disabled={
                                formData?.DataType1 === 'Electronic Copies' ||
                                formData?.DataType2 === 'Electronic Copies'
                              }>
                              Electronic Copies
                            </option>
                            <option
                              value="Audio/Visual"
                              disabled={
                                formData?.DataType1 === 'Audio/Visual' ||
                                formData?.DataType2 === 'Audio/Visual'
                              }>
                              Audio/Visual
                            </option>
                          </NativeSelect>
                          {error && <FormHelperText error>{error?.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Box>
                  <Box className={styles['plan-item']}>
                    <Typography variant="h5" component="h4">
                      How are data stored securely?
                    </Typography>

                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          fullWidth
                          size="small"
                          className={styles['plan-field']}
                          error={!!error}>
                          <TextField
                            {...field}
                            multiline
                            rows={4}
                            placeholder="Please provide details *"
                            size="small"
                            fullWidth
                            onChange={(e) => {
                              handleDataTypeChange(e, field);
                              field.onChange(e);
                            }}
                            inputProps={{ 'data-id': 2 }}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                      name="DataStored3"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'This field is required' }}
                    />
                  </Box>
                  <Box className={styles['plan-item']}>
                    <Typography variant="h5" component="h4">
                      How are data access restricted?
                    </Typography>

                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          fullWidth
                          size="small"
                          className={styles['plan-field']}
                          error={!!error}>
                          <TextField
                            {...field}
                            onChange={(e) => {
                              handleDataTypeChange(e, field);
                              field.onChange(e);
                            }}
                            multiline
                            rows={4}
                            placeholder="Please provide details *"
                            size="small"
                            fullWidth
                            inputProps={{ 'data-id': 2 }}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                      name="DataAccess3"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'This field is required' }}
                    />
                  </Box>
                  <Box className={styles['plan-item']}>
                    <Typography variant="h5" component="h4">
                      How long will data be kept?
                    </Typography>

                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          fullWidth
                          size="small"
                          className={styles['plan-field']}
                          error={!!error}>
                          <TextField
                            {...field}
                            onChange={(e) => {
                              handleDataTypeChange(e, field);
                              field.onChange(e);
                            }}
                            multiline
                            rows={4}
                            placeholder="Please provide details *"
                            size="small"
                            fullWidth
                            inputProps={{ 'data-id': 2 }}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                      name="DataDuration3"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'This field is required' }}
                    />
                  </Box>
                </Box>
              </>
            )}

            {count < 2 && (
              <Button
                variant="contained"
                component="button"
                className={styles['add-btn']}
                onClick={onClick}>
                Add data type
              </Button>
            )}
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper elevation={2} sx={{ p: 4, mb: 5 }}>
        <Resources dataManagementPlan={true} />
      </Paper>
    </>
  );
};

Design.propTypes = {
  control: PropTypes.object,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  onClick: PropTypes.func,
  dataType: PropTypes.object,
  setDataType: PropTypes.func,
  count: PropTypes.number,
  setCount: PropTypes.func,
  errors: PropTypes.object,
  indicatorCode: PropTypes.array,
  filteredIndicators: PropTypes.array,
  mappedInstruments: PropTypes.array,
  designApproachItems: PropTypes.array,
  dataCollectionMethodItems: PropTypes.array,
  selectedInstruments: PropTypes.array,
  chunkedInstruments: PropTypes.array,
  indicatorsCount: PropTypes.array,
  setChunkedInstruments: PropTypes.func,
  handleFieldChange: PropTypes.func
};

export default Design;
