import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import avatarImg from '../../assets/images/default-avatar.png';
import styles from './FileUpload.module.scss';

const ImageUpload = ({ uploadedImage, setAvatar, src }) => {
  const imageUploader = useRef(null);
  const [error, setError] = useState('');

  const handleImageUpload = (e) => {
    const [file] = e.target.files;

    if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|jfif|png|PNG)$/)) {
      setError('Please select a valid image.');
      const { current } = uploadedImage;
      current.src = avatarImg;
      return false;
    }

    if (file.size > 1e6) {
      setError('Please select file size less than 1MB.');
      return false;
    }

    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
        setAvatar(reader.result);
        setError('');
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Box className={styles['upload-profile']}>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={imageUploader}
        style={{
          display: 'none'
        }}
      />
      <Box className={styles['image-placeholder']} onClick={() => imageUploader.current.click()}>
        <img ref={uploadedImage} src={src || avatarImg} />
      </Box>
      <Typography variant="body2" component="p" color={error ? 'red' : 'grey'}>
        {error ? error : 'Upload profile image'}
      </Typography>
    </Box>
  );
};

ImageUpload.propTypes = {
  uploadedImage: PropTypes.object,
  setAvatar: PropTypes.func,
  src: PropTypes.string
};

export default ImageUpload;
