import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import styles from './About.module.scss';
import aboutBannerMobile from '../../assets/images/about-banner-mobile.jpg';

const About = () => {
  return (
    <>
      <img className={styles['mobile-banner']} src={aboutBannerMobile} alt="Evaluation portal" />
      <Box className={styles['banner-wrapper']}>
        <Container>
          <Grid container spacing={4} className={styles['main-content']}>
            <Grid item sm={12}>
              <Typography variant="h1" component="h1" mb={2}>
                About
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Grid container className={styles['main-content']}>
          <Grid item sm={12}>
            <Typography variant="body" component="p">
              The STEM Equity Evaluation Portal (the Portal) is a product of the Office of{' '}
              <a href="https://womeninstem.org.au/" target="_blank" rel="noreferrer">
                Australia’s Women in STEM Ambassador
              </a>{' '}
              (the Office). Led by Professor Lisa Harvey-Smith, the Office mobilises Australia’s
              business leaders, educators, parents and carers, and policymakers to remove barriers
              to the participation of girls, women, non-binary and other marginalised groups in
              STEM.
            </Typography>
            <Typography variant="body" component="p">
              The Office developed the Portal in response to recommendations from the{' '}
              <a
                href="https://www.science.org.au/support/analysis/decadal-plans-science/women-in-stem-decadal-plan"
                target="_blank"
                rel="noreferrer">
                Women in STEM Decadal Plan
              </a>{' '}
              for a standardised national evaluation framework that guides evaluation efforts across
              all existing and future STEM equity initiatives in Australia. In July 2021, the Office
              was awarded funding from the Department of Industry, Science and Resources to create
              the Portal as an integral part of the{' '}
              <a
                href="https://www.industry.gov.au/data-and-publications/advancing-women-in-stem-strategy/2020-action-plan"
                target="_blank"
                rel="noreferrer">
                Australian Government’s Advancing Women in STEM 2020 Action Plan
              </a>
              .
            </Typography>
            <Typography variant="body" component="p">
              The STEM Equity Evaluation Portal is an extension of the Office’s{' '}
              <a
                href="https://womeninstem.org.au/national-evaluation-guide/"
                target="_blank"
                rel="noreferrer">
                National Evaluation Guide for STEM Equity Programs
              </a>{' '}
              (the Guide). Created by Dr Isabelle Kingsley, the Portal and Guide are complementary,
              user-friendly, how-to resources that offer practical advice to help STEM equity
              program leaders evaluate their programs. The Portal is also a searchable repository to
              discover program evaluations and learn what works and doesn’t work to improve equity.
            </Typography>
            <Typography variant="body" component="p">
              The Portal aims to:
            </Typography>
            <ul>
              <li>
                Enable project-level evaluation and demonstrate what works to attract, retain and
                progress underrepresented groups in STEM
              </li>
              <li>
                Support a culture of evidence-based practice by enabling activities to be improved
                based on evaluation data
              </li>
              <li>Create consistency and comparability of evaluation data</li>
              <li>
                Publish and collate evaluation data in a national repository to improve awareness of
                existing programs and their efficacy, identify and/or address any gaps, and inform
                decision-making about what works and what should be scaled up and/or funded across
                sectors
              </li>
              <li>
                Support and incentivise collaboration between providers of programs within and
                across sectors to create stronger cohesion and consolidate efforts and resources.
              </li>
            </ul>
            <Typography variant="body" component="p">
              Program evaluation need not be daunting, complex, expensive or time-consuming. The
              Portal breaks it down into a simple, 5-step approach that’s easy to apply. It guides
              program leaders on how to embed evaluation within programs from the very beginning.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default About;
