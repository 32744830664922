import React, { useEffect } from 'react';
import AppRouter from './routes';

const App = () => {
  useEffect(() => {
    if (window.location.pathname !== '/evaluations') {
      localStorage.removeItem('evalId');
    }
  }, []);

  return <AppRouter />;
};

export default App;
