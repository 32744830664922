import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from './ModalPortal';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  //   Fade,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  //   Slide,
  TextField
  //   Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../services/context/AuthProvider';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './Modal.module.scss';

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const ModalTwo = ({ closeFn = () => null, open = false, setModal }) => {
  const navigate = useNavigate();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [checked, setChecked] = useState(true);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const { authenticate } = useContext(AuthContext);
  const {
    control,
    handleSubmit,
    reset
    // watch,
    // formState: { errors }
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      Email: '',
      Password: ''
    }
  });

  closeFn = () => {
    reset();
    setModal('');
  };

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  //   const handleClose = () => {
  //     navigate('/');
  //   };

  const onSubmit = (data) => {
    // event.preventDefault();
    // console.log(data);
    authenticate(data.Email, data.Password)
      .then((data) => {
        console.log('Logged in!', data);
        navigate('/dashboard');
      })
      .catch((err) => {
        console.error('Failed to login', err.name);
        if (err.name === 'InvalidParameterException') {
          setError('Please fill out all required fields');
        }
        if (err.name === 'NotAuthorizedException') {
          setError('Incorrect email or password.');
        }
        if (err.name === 'UserNotFoundException') {
          setError('User account does not exist.');
        }
        if (err.name === 'UserNotConfirmedException') {
          setError('User is not confirmed.');
        }
        // setError(err.message);
      });
  };
  return (
    <Modal open={open} onClose={closeFn}>
      <Dialog
        maxWidth="md"
        onClose={closeFn}
        open={open}
        aria-labelledby="modal-wrapper"
        className={styles['modal-wrapper']}
        // TransitionComponent={Transition}
        transitionDuration={500}>
        <DialogTitle className={styles['header-title']} onClose={closeFn}>
          Login
          <IconButton
            aria-label="close"
            onClick={closeFn}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={`${styles['modal-content']} ${styles['login-wrapper']}`}
          sx={{ p: 5 }}>
          {error && (
            <Alert
              variant="filled"
              severity="error"
              className={styles['error-message']}
              sx={{ mb: 4 }}>
              {error}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container maxWidth="xs">
              <Grid item xs={12} mb={2}>
                <Controller
                  name="Email"
                  control={control}
                  rules={{
                    required: 'Email is required.',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email format.'
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Email"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} mb={4}>
                <Controller
                  name="Password"
                  control={control}
                  rules={{ required: 'Password is required.' }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" error={!!error}>
                      <TextField
                        {...field}
                        label="Password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}>
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sx={{ textAlign: 'left' }} mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    label={'Keep me logged in'}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Keep me logged in"
              />
            </Grid> */}
              <Grid item xs={12}>
                <Button type="submit" variant="contained" fullWidth className={styles['btn']}>
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
          <Box className={styles['forgot-password-btn']}>
            <Button type="button" data-modal="forgot-password">
              Forgot your password?
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Modal>
  );
};

ModalTwo.propTypes = {
  closeFn: PropTypes.func,
  open: PropTypes.bool,
  setModal: PropTypes.func
};

export default ModalTwo;
