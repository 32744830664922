import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const PROBLEMS_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=Problems`;
const GOALS_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=Goals`;
const PROGRAM_AUDIENCES_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=ProgramAudiences`;
const EVALUATION_AUDIENCES_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=EvaluationAudiences`;

const initialState = {
  define: [],
  problems: [],
  goals: [],
  programAudiences: [],
  evaluationAudiences: [],
  status: 'idle',
  error: null
};

export const fetchProblems = createAsyncThunk('problems/fetchProblems', async () => {
  try {
    const response = await axios.get(PROBLEMS_URL);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchGoals = createAsyncThunk('goals/fetchGoals', async () => {
  try {
    const response = await axios.get(GOALS_URL);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchEvaluationAudiences = createAsyncThunk(
  'evaluationAudiences/fetchEvaluationAudiences',
  async () => {
    try {
      const response = await axios.get(EVALUATION_AUDIENCES_URL);
      return response.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const fetchProgramAudiences = createAsyncThunk(
  'programAudiences/fetchProgramAudiences',
  async () => {
    try {
      const response = await axios.get(PROGRAM_AUDIENCES_URL);
      // console.log('program audience', response.data);
      return response.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const defineSlice = createSlice({
  name: 'define',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProblems.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(fetchProblems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.problems = action.payload;
        // return action.payload;
      })

      .addCase(fetchProblems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchGoals.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(fetchGoals.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.goals = action.payload;
        // return action.payload;
      })

      .addCase(fetchGoals.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchEvaluationAudiences.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.evaluationAudiences = action.payload;
        // return action.payload;
      })

      .addCase(fetchProgramAudiences.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.programAudiences = action.payload;
        // return action.payload;
      });
  }
});

export const selectAllProblems = (state) => state.define.problems;
export const selectAllGoals = (state) => state.define.goals;
export const selectAllProgramAudiences = (state) => state.define.programAudiences;
export const selectAllEvaluationAudiences = (state) => state.define.evaluationAudiences;
export const getDefineStatus = (state) => state.define.status;
export const getError = (state) => state.define.error;

export default defineSlice.reducer;
