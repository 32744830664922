import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import StarIcon from '@mui/icons-material/Star';
// import RemoveIcon from '@mui/icons-material/Remove';
import PropTypes from 'prop-types';
import styles from './Card.module.scss';
// import { getFilteredEvaluations } from '../../services/features/evaluations/evaluationSlice';
// import { useSelector } from 'react-redux';
// import CollaboratorsModal from '../Modal/CollaboratorsModal';

const EvaluationCard = ({
  id,
  title,
  datePublished,
  status,
  ssp,
  organisation,
  outcomes,
  content,
  description,
  className,
  collaborators,
  editor,
  bookmark,
  readmore,
  onClick,
  handleBookmark,
  color,
  btnText,
  published,
  unpublishEvaluation
  // handleCollaborators
}) => {
  // const evaluations = useSelector(getFilteredEvaluations);
  // console.log('evaluations', evaluations);
  // const [bookmarks, setBookmarks] = useState([]);

  // const filterByReference = (arr1, arr2) => {
  //   let res = [];
  //   res = arr1.filter((el) => {
  //     return arr2.find((element) => {
  //       return element.id === el.id;
  //     });
  //   });
  //   return res;
  // };

  useEffect(() => {
    // setBookmarks(JSON.parse(localStorage.getItem('bookmarks')));
    // const savedItems = filterByReference(bookmarks, evaluations);
    // console.log('savedItems', savedItems);
  }, []);

  // console.log('bookmarks', bookmarks);

  // const [open, setOpen] = useState(false);

  // console.log('eval id', id);

  // const handleCollaborators = (evalId) => {
  //   console.log('evalId', evalId);
  //   setOpen(true);
  // };

  // console.log('id', id);

  return (
    <>
      <Card className={className}>
        <CardContent>
          <Box className={styles['card-header']}>
            <Typography
              variant="h3"
              component="h2"
              color="text.primary"
              className={styles['title']}>
              {title}
            </Typography>

            {status === 'In Progress' && (
              <Typography variant="body1" component="p" className={styles['incomplete']}>
                <span className={styles.indicator}></span> In Progress
              </Typography>
            )}

            {status === 'Complete' && (
              <Typography variant="body1" component="p" className={styles['complete']}>
                <span className={styles.indicator}></span> Complete
              </Typography>
            )}
            {ssp == true && (
              <Typography variant="body1" component="p" className={styles['ssp']}>
                <span className={styles.indicator}></span> <span>SSP</span>
              </Typography>
            )}

            <Typography variant="body1" component="p" className={styles['date-published']}>
              {datePublished}
            </Typography>
          </Box>
          <Typography variant="body1" component="h2">
            {organisation}
          </Typography>
          {outcomes && (
            <Typography variant="body1" component="h3" className={styles['goals']}>
              {outcomes}
            </Typography>
          )}
          {description && (
            <Typography
              variant="body1"
              component="strong"
              className={styles['description']}
              sx={{ display: 'block', mt: 2 }}>
              {description}
            </Typography>
          )}
          {content && (
            <Typography
              variant="body2"
              component="p"
              color="text.secondary"
              className={styles['content']}
              sx={{ mt: 2 }}>
              {content}
            </Typography>
          )}
        </CardContent>
        <CardActions classes={{ root: styles['card-actions'] }}>
          {published === true && (
            <Button
              data-id={id}
              variant="outlined"
              color="error"
              // component={Link}
              size="md"
              // endIcon={<RemoveIcon />}
              className={styles['outlined-error-btn']}
              onClick={unpublishEvaluation}
              // data-modal="unpublish"
              sx={{ mb: 2 }}>
              Unpublish
            </Button>
          )}

          {collaborators && (
            <Button
              data-id={id}
              variant="outlined"
              // component={Link}
              size="md"
              // endIcon={<AddIcon />}
              className={styles['outlined-btn']}
              onClick={onClick}
              sx={{ mb: 2 }}>
              Add colloborators
            </Button>
          )}
          {editor && (
            <Button
              size="md"
              variant="contained"
              component={Link}
              to={`/evaluation/edit/${id}`}
              // endIcon={<ArrowForwardIcon />}
              className={styles['editor-btn']}
              reloadDocument>
              Open in Editor
            </Button>
          )}

          {bookmark && (
            <Button
              data-id={id}
              className={styles['outlined-btn']}
              size="md"
              variant="outlined"
              color={color}
              endIcon={<StarIcon />}
              onClick={handleBookmark}>
              {btnText}
            </Button>
          )}

          {readmore && (
            <Button
              className={styles['primary-btn']}
              size="md"
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={onClick}>
              Read more
            </Button>
          )}
        </CardActions>
      </Card>
      {/* <CollaboratorsModal open={open} setOpen={setOpen} /> */}
    </>
  );
};

EvaluationCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  status: PropTypes.string,
  ssp: PropTypes.bool,
  datePublished: PropTypes.string,
  organisation: PropTypes.string,
  outcomes: PropTypes.string,
  content: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  collaborators: PropTypes.bool,
  editor: PropTypes.bool,
  bookmark: PropTypes.bool,
  readmore: PropTypes.bool,
  onClick: PropTypes.func,
  handleBookmark: PropTypes.func,
  color: PropTypes.string,
  btnText: PropTypes.string,
  published: PropTypes.bool,
  unpublishEvaluation: PropTypes.func
  // handleCollaborators: PropTypes.func
};

export default EvaluationCard;
