import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ModalManager from '../components/Modal/ModalManager';

const MainLayout = () => {
  const [modalOpen, setModal] = useState(false);
  const [formData, setFormData] = useState({});

  const openModal = (event) => {
    const {
      target: {
        dataset: { modal }
      }
    } = event;
    if (modal) setModal(modal);
  };

  const closeModal = () => {
    setModal('');
  };
  return (
    <>
      <div className="app--shell" onClick={openModal}>
        <Header setModal={setModal} />
        <main id="main-wrapper" className="main-wrapper">
          <Outlet />
        </main>
        <Footer />
        <ModalManager
          closeFn={closeModal}
          modal={modalOpen}
          setModal={setModal}
          formData={formData}
          setFormData={setFormData}
        />
      </div>
    </>
  );
};

export default MainLayout;
