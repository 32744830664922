import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Checkbox,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  // InputLabel,
  // ListItemText,
  // MenuItem,
  // Select,
  TextField,
  Typography
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Modal from '.';
import styles from './Modal.module.scss';
import { getUserStatus, getAllUsers, fetchUsers } from '../../services/features/users/usersSlice';
import {
  getEvaluationStatus,
  getEvaluation,
  singleEvaluation
} from '../../services/features/evaluation/evaluationSlice';
// import { updateEvaluation } from '../../services/features/evaluations/evaluationsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { AuthContext } from '../../services/context/AuthProvider';
// import { getSelectedCollaborators } from '../../services/features/collaborators/collaboratorsSlice';
import utils from './../../helpers/utils';

const CollaboratorsModal = ({
  id,
  open,
  setOpen,
  formData,
  // setFormData,
  collaborators,
  setCollaborators,
  setSuccessMessage
}) => {
  const dispatch = useDispatch();
  const { getSession } = useContext(AuthContext);

  const userStatus = useSelector(getUserStatus);
  const evaluationStatus = useSelector(getEvaluationStatus);
  const users = useSelector(getAllUsers);
  const evaluation = useSelector(getEvaluation);
  const [author, setAuthor] = useState([{}]);

  const { handleSubmit, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: collaborators
  });

  useEffect(() => {
    if (userStatus === 'idle') {
      dispatch(fetchUsers());
    }
  }, [userStatus, dispatch]);

  useEffect(() => {
    if (evaluationStatus === 'idle') {
      dispatch(singleEvaluation(id));
    }
  }, [dispatch]);

  useEffect(() => {
    getSession().then((data) => {
      setAuthor([{ Username: data.sub, Name: data.given_name + ' ' + data.family_name }]);
    });
  }, []);

  useEffect(() => {}, [author, formData]);

  const closeModal = () => {
    setOpen(false);
  };

  // console.log('id', id);

  const newUsers = users.map((item) => {
    const authorNames = item.Attributes.filter((attribute) => {
      return attribute.Name.includes('given_name') || attribute.Name.includes('family_name');
    });
    return {
      Username: item.Username,
      Name: authorNames[0].Value + ' ' + authorNames[1].Value
    };
  });

  const selectedUsers = newUsers.filter((item) => {
    return collaborators?.includes(item.Username);
  });

  useEffect(() => {
    setCollaborators(selectedUsers);
  }, []);

  const selectedCollaborators = evaluation[0]?.Collaborators?.map((collaborator) => {
    const collab = collaborator;
    return collab;
  });

  const handleAddCollaborators = async () => {
    // console.log('collaborators', collaborators);
    try {
      const accessToken = await utils.getValidAccessToken();
      if (localStorage.getItem('evalId') !== null) {
        const evalId = localStorage.getItem('evalId');
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/evaluations/${evalId}`,
          {
            Collaborators: collaborators,
            Stage: 'AddCollaborators'
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        setOpen(false);
        setSuccessMessage({ status: true, message: 'Collaborators is updated.' });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        return res.data;
      }
    } catch (err) {
      console.log('error', err.message);
      return err.message;
    }
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <DialogContent sx={{ p: 5 }}>
        <form onSubmit={handleSubmit(handleAddCollaborators)}>
          <Grid container maxWidth="xs">
            <Grid item xs={12} mb={2}>
              <Typography variant="h3" component="h2" mb={3}>
                Add collaborators
              </Typography>

              <Controller
                name={`Collaborators`}
                control={control}
                defaultValue={selectedCollaborators}
                render={({ props, fieldState: { error } }) => (
                  <FormControl fullWidth size="small" error={!!error}>
                    <Autocomplete
                      {...props}
                      multiple
                      id="collaborators"
                      value={collaborators || selectedCollaborators}
                      options={newUsers}
                      disableCloseOnSelect
                      isOptionEqualToValue={(option, value) => option.Username === value.Username}
                      getOptionLabel={(option) => option.Name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.Name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Collaborators" placeholder="Select" />
                      )}
                      onChange={(_, selectedOptions) => setCollaborators(selectedOptions)}
                    />
                    {error && <FormHelperText error>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} textAlign="right" mt={2}>
              <Button
                variant="contained"
                type="submit"
                className={styles['btn']}
                sx={{ color: '#FFFFFF' }}>
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Modal>
  );
};

CollaboratorsModal.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  collaborators: PropTypes.array,
  setCollaborators: PropTypes.func,
  setSuccessMessage: PropTypes.func
};

export default CollaboratorsModal;
