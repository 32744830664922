import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Avatar,
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  // Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Toolbar,
  Typography
} from '@mui/material';
import ExternalLink from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/images/logo.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './Header.module.scss';
import Modal from '../Modal';
import LoginModal from '../Modal/LoginModal';
import SearchIcon from '@mui/icons-material/Search';
import { AuthContext } from '../../services/context/AuthProvider';

const pages = [
  { name: 'Home', url: '/' },
  { name: 'Search all programs', url: '/search-programs?SearchKey' },
  { name: 'Evaluate your programs', url: '/evaluate-programs' },
  { name: 'About', url: '/about' }
];
const settings = [
  { name: 'Account details', url: '/profile' },
  { name: 'Manage my evaluations', url: '/dashboard' }
];

const Header = ({ setModal }) => {
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const tablet = useMediaQuery(theme.breakpoints.down('sm'));
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState('');

  const { isAuthenticated, getSession, logout } = useContext(AuthContext);

  useEffect(() => {
    getSession().then((data) => {
      // setIsAuthenticated(true);
      setUserInfo(data);
    });
    userInfo;
  }, []);

  const [openModal, setOpenModal] = useState(false);
  // const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [state, setState] = useState({
    left: false
  });

  const toggleDrawer = (anchor, open) => () => {
    setState({ ...state, [anchor]: open });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setModal('signin-option');
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <List>
        {pages.map((page, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton component={Link} to={page.url}>
              <ListItemText primary={page.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href="https://womeninstem.org.au/national-evaluation-guide/"
            target="_blank">
            <ListItemText primary="Resources" />
          </ListItemButton>
        </ListItem>
        {tablet && (
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/bookmarks">
              <ListItemText primary="Bookmarks" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" color="white" classes={{ root: styles.header }}>
      <Container maxWidth="xl">
        <Toolbar id="top" disableGutters sx={{ justifyContent: 'space-between' }}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            className={styles.logo}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}>
            <img src={logo} alt="Women in Stem Logo" />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer('left', true)}
              color="inherit"
              sx={{ padding: 0 }}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}>
              {pages.map((page, key) => (
                <MenuItem key={key} onClick={handleCloseNavMenu}>
                  <Link to={page.url} reloadDocument>
                    {page.name}
                  </Link>
                </MenuItem>
              ))}
              <MenuItem>
                <Link
                  to="https://womeninstem.org.au/national-evaluation-guide/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Resources
                </Link>
              </MenuItem>
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            className={styles.logo}
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexGrow: 9
            }}>
            <img src={logo} alt="Women in Stem Logo" className={styles.logo} />
          </Typography>

          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            <IconButton className={styles.bookmark} sx={{ mr: 1 }}>
              <Link to="/search">
                <SearchIcon color="primary" />
              </Link>
            </IconButton>
            <IconButton
              className={styles.bookmark}
              sx={{
                p: 0,
                mr: { xs: '0.5rem', sm: 1 },
                display: { xs: 'none', sm: 'inline-block' }
              }}>
              <Link to="/bookmarks" reloadDocument>
                <BookmarkIcon color="primary" fontSize="md" />
              </Link>
            </IconButton>

            {isAuthenticated ? (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={userInfo.given_name} src={userInfo.picture} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Log in">
                <IconButton onClick={handleLogin} sx={{ p: 0 }}>
                  {/* <Avatar alt={userInfo.given_name} src={userInfo.picture} /> */}
                  <AccountCircleIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            )}

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              className={styles['toggle-menu']}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              disableScrollLock={true}>
              {settings.map((setting, key) => (
                <MenuItem key={key} onClick={handleCloseUserMenu}>
                  <Link to={setting.url} reloadDocument>
                    {setting.name}
                  </Link>
                </MenuItem>
              ))}
              <MenuItem onClick={logout}>Sign out</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
        <Box
          sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
          className={styles['nav-links']}>
          {pages.map((page, key) => (
            <Link key={key} to={page.url} reloadDocument>
              {page.name}
            </Link>
          ))}
          <ExternalLink
            href="https://womeninstem.org.au/national-evaluation-guide"
            target="_blank"
            rel="noopener noreferrer">
            Resources
          </ExternalLink>
        </Box>
      </Container>
      <Drawer anchor="left" open={state['left']} onClose={toggleDrawer('left', false)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem'
          }}>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            className={styles.logo}
            sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img src={logo} alt="Women in Stem Logo" style={{ margin: '0 auto 0 0' }} />
          </Typography>
          <IconButton sx={{ justifyContent: 'flex-end' }} onClick={toggleDrawer('left', false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        {list('left')}
      </Drawer>
      <Modal size="md" open={openModal} onClose={handleClose} onClick={handleClose}>
        <LoginModal />
      </Modal>
    </AppBar>
  );
};

Header.propTypes = {
  closeFn: PropTypes.func,
  open: PropTypes.bool,
  setModal: PropTypes.func
};
export default Header;
