import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import EvaluatePrograms from '../pages/EvaluatePrograms';
import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import Bookmarks from '../pages/Bookmarks';
import DashboardLayout from '../layouts/DashboardLayout';
import MainLayout from '../layouts/MainLayout';
import { AuthProvider } from '../services/context/AuthProvider';
import Files from '../pages/Files';
import Contacts from '../pages/Contacts';
import ProtectedRoute from './ProtectedRoute';
import About from '../pages/About';
import Help from '../pages/Help';
import BasicSearch from '../pages/SearchProgram/BasicSearch';
import AdvancedSearch from '../pages/SearchProgram/AdvancedSearch';
import SearchProgram from '../pages/SearchProgram';
import EvaluationProgram from '../pages/EvaluationProgram';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import OnlineUsePolicy from '../pages/OnlineUsePolicy';
import TermsAndConditions from '../pages/TermsConditions';
import ScrollToTop from '../helpers/ScrollToTop';
import EvaluatingProgram from '../pages/EvaluatePrograms/EvaluatingProgram';
import PublishingProgram from '../pages/EvaluatePrograms/PublishingProgram';
import Evaluation from '../pages/Evaluation';
import UpdateEvaluation from '../pages/UpdateEvaluation';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

// initialize GA client
ReactGA.initialize(MEASUREMENT_ID);

const AppRouter = () => {
  useEffect(() => {
    // tracks page views for each route change
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }, []);

  return (
    <AuthProvider>
      <Router forceRefresh={true}>
        <ScrollToTop />
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route element={<DashboardLayout />}>
              <Route path="/dashboard" exact element={<Dashboard />} />
              <Route path="/profile" exact element={<Profile />} />
              {/* <Route path="/progress" exact element={<Progress />} /> */}
              <Route path="/evaluation" exact element={<Evaluation />} />
              <Route path="/evaluation/edit/:evalId" exact element={<UpdateEvaluation />} />
              <Route path="/files" exact element={<Files />} />
              <Route path="/contacts" exact element={<Contacts />} />
              <Route path="/help" exact element={<Help />} />
            </Route>
          </Route>

          <Route element={<MainLayout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/search" element={<BasicSearch />} />
            <Route exact path="/search-programs" element={<SearchProgram />} />
            <Route exact path="/advanced-search" element={<AdvancedSearch />} />
            <Route exact path="/program/:evalId" element={<EvaluationProgram />} />
            <Route exact path="/evaluate-programs" element={<EvaluatePrograms />} />
            <Route
              exact
              path="/evaluate-programs/benefits-of-evaluating-programs"
              element={<EvaluatingProgram />}
            />
            <Route
              exact
              path="/evaluate-programs/benefits-of-publishing-programs"
              element={<PublishingProgram />}
            />
            <Route exact path="/bookmarks" element={<Bookmarks />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/online-use-policy" element={<OnlineUsePolicy />} />
            <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default AppRouter;
