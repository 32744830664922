import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import styles from './EvaluatePrograms.module.scss';

const EvaluatingProgram = () => {
  return (
    <Container>
      <Grid container spacing={4} className={styles['main-content']}>
        <Grid item sm={12}>
          <Typography variant="h1" component="h1" mb={2}>
            Benefits of evaluating equity, diversity and inclusion programs
          </Typography>
          <Typography variant="body" component="p">
            Australia has hundreds of STEM equity programs that address the
            underrepresentation of girls, women, non-binary and other marginalised 
            groups in STEM, but do they work?
          </Typography>
          <Typography variant="body" component="p">
            To help answer this question, the Office of the Women in STEM Ambassador has designed a
            STEM Equity Evaluation Portal (the Portal) to measure the effectiveness of these
            programs.
          </Typography>
          <Typography variant="body" component="p">
            Building upon the{' '}
            <a
              href="https://womeninstem.org.au/national-evaluation-guide/"
              target="_blank"
              rel="noreferrer">
              National Evaluation Guide for STEM
            </a>{' '}
            equity programs (the Guide), the Portal is an extension that makes evaluation
            simple and easy for program leaders. It is a planning and reporting tool and a database
            of existing STEM equity programs and their efficacy.
          </Typography>
          <Typography variant="body" component="p">
            “Program evaluation helps us know if we’re being effective in creating change,” says Dr
            Isabelle Kingsley, Research Associate from the Office of the Women in STEM Ambassador
            and creator of the Portal.
          </Typography>
          <Typography variant="body" component="p">
            So WHY should organisations evaluate their programs? There’s always room for
            improvement, and the benefits of evaluation can help your organisation thrive in an
            increasingly competitive world.
          </Typography>
          <Typography variant="body" component="p">
            Here are just six reasons to evaluate.
          </Typography>
          <ol>
            <li>
              <strong>Measure project outcomes</strong>. Evaluation is essential to gauge the
              effectiveness of your program. It helps you know whether your program is doing what it
              is meant to do.
            </li>
            <li>
              <strong>Use resources efficiently</strong>. Evaluation allows you to justify and
              account for your program resources and investment. Knowing what works and does not
              work helps you use resources efficiently.
            </li>
            <li>
              <strong>Attract and keep funding and partners</strong>. Transparently sharing your
              evaluation can help you attract and keep sponsors and partners. Results show
              accountability and demonstrate value and worth.
            </li>
            <li>
              <strong>Improve and scale-up</strong>. Evaluation allows you to refine your program to
              improve it. For Government and sponsors, it informs decisions on what programs should
              be extended or scaled up.
            </li>
            <li>
              <strong>Generate useful data</strong>. Evaluations can help us understand the issues
              that affect underrepresented groups in STEM. Evaluation data over time allows us to learn what
              programs work, for whom and why.
            </li>
            <li>
              <strong>Promote best practice and collaboration</strong>. Evaluation can inform and
              guide best practice for STEM equity programs.
            </li>
          </ol>
          <Typography variant="body" component="p">
            If your organisation is serious about creating change, you need to know if your actions
            are working. The best way to achieve this is through data-driven accountability. The
            Portal will help you assess a program’s strengths, weaknesses and effectiveness, leading
            to a greater understanding of what works or does not, how, for whom, and why.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EvaluatingProgram;
