import jwt_decode from 'jwt-decode';
import Pool from '../authentication/UserPool';

/**
 * Gets a valid, non-expiring access token. If expired, gets fresh token from Cognito and replaces to browser storage
 * @returns A token from browser storage if it hasn't expired yet, else fresh token from Cognito
 */
const getValidAccessToken = async () => {
  // Get existing token from browser storage
  const currentAccessToken = localStorage.getItem('token');

  // Decode token JWT and check if it has expired
  const currentAccessTokenDecoded = jwt_decode(currentAccessToken);
  const isTokenExpired =
    currentAccessTokenDecoded && currentAccessTokenDecoded.exp * 1000 < Date.now();

  // Return existing token if not yet expired
  if (!isTokenExpired) return currentAccessToken;

  // Else get a fresh token from Cognito and replace to browser storage
  return await new Promise((resolve, reject) => {
    const currentUser = Pool.getCurrentUser();
    if (!currentUser) reject();
    currentUser.getSession(async (err, session) => {
      if (err) {
        reject();
      } else {
        const newAccessToken = session.getAccessToken().jwtToken;
        localStorage.setItem('token', newAccessToken);
        resolve(newAccessToken);
      }
    });
  });
};

export default { getValidAccessToken };
