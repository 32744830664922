import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
// import UserPool from '../../authentication/UserPool';
// import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography
} from '@mui/material';
import ResponsiveDrawer from '../../components/Drawer';
import styles from './Profile.module.scss';
import ImageUpload from '../../components/FileUpload/ImageUpload';
import { AuthContext } from '../../services/context/AuthProvider';
import ChangePassword from './ChangePassword';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import utils from '../../helpers/utils';

const Profile = () => {
  // const initialData = {
  //   Email: '',
  //   FirstName: '',
  //   FamilyName: '',
  //   OrganisationName: '',
  //   OrganisationType: '',
  //   OrganisationABN: '',
  //   OrganisationLocation: '',
  //   Avatar: '',
  //   Declaration: false
  // };
  const { getSession } = useContext(AuthContext);
  const [formData, setFormData] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const pictureRef = useRef(null);
  const [avatar, setAvatar] = useState('');

  const {
    control,
    handleSubmit,
    setValue,
    reset
    // register,
    // ...props
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: formData
  });

  const fetchData = useCallback(async () => {
    await getSession().then((data) => {
      setFormData({
        Username: data.sub,
        FirstName: data.given_name,
        LastName: data.family_name,
        Email: data.email,
        Avatar: data.picture,
        OrganisationType: data['custom:organisation_type'],
        OrganisationABN: data['custom:organisation_abn'],
        OrganisationLocation: data['custom:location'],
        OrganisationName: data['custom:organisation_name'],
        CustomLocation: data['custom:other_location'],
        Website: data.website,
        FacebookUrl: data['custom:facebook_url'],
        TwitterUrl: data['custom:twitter_url'],
        LinkedinUrl: data['custom:linkedin_url'],
        InstagramUrl: data['custom:instagram_url']
      });
    });
  }, [formData]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    reset(formData);
  }, [reset]);

  const handleLocationChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = () => {
    setSuccessMessage(false);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const accessToken = await utils.getValidAccessToken();
    setValue('Avatar', avatar);
    try {
      setFormData({
        ...data,
        CustomLocation: data.OrganisationLocation === 'Others' ? data.CustomLocation : ''
      });
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/${formData?.Username}`,
        {
          ...data,
          Avatar: avatar,
          CustomLocation: data.OrganisationLocation === 'Others' ? data.CustomLocation : ''
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      setSuccessMessage(true);
      setError(null);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return res.data;
    } catch (err) {
      setError(err.response?.data?.Message);
      // console.log('error', err.response.Message);
      return err.response.data.Message;
    }
  };

  return (
    <>
      <ResponsiveDrawer />
      <Box component="main" className="dashboard-container">
        <Typography variant="h1" component="h1">
          Profile Settings
        </Typography>
        <Paper elevation={2} sx={{ p: 4, my: 4 }}>
          <Typography variant="body" component="h2">
            Account details
          </Typography>
          {formData && (
            <Box className={styles['profile-wrapper']}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box className={styles['profile-photo']}>
                  <ImageUpload
                    uploadedImage={pictureRef}
                    setAvatar={setAvatar}
                    src={formData?.Avatar ? formData?.Avatar : avatar}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} mb={2}>
                    {error && (
                      <Alert variant="filled" severity="error">
                        {error}
                      </Alert>
                    )}
                    {successMessage && (
                      <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={successMessage}
                        autoHideDuration={6000}
                        onClose={handleClose}>
                        <Alert
                          elevation={2}
                          onClose={handleClose}
                          severity="success"
                          sx={{ width: '100%' }}>
                          Profile is successfully updated!
                        </Alert>
                      </Snackbar>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="FirstName"
                      control={control}
                      defaultValue={formData?.FirstName}
                      rules={{
                        required: 'First name name is required.'
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small" error={!!error}>
                          <TextField
                            {...field}
                            label="First name"
                            value={field.value || ''}
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="LastName"
                      control={control}
                      defaultValue={formData?.LastName}
                      rules={{
                        required: 'Last name is required.'
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small" error={!!error}>
                          <TextField
                            {...field}
                            label="Last name"
                            fullWidth
                            value={field.value || ''}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="OrganisationType"
                      control={control}
                      type="text"
                      defaultValue={formData?.OrganisationType}
                      rules={{ required: 'Organisation type is required.' }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <InputLabel id="evaluation-approaches">Organisation Type</InputLabel>
                          <Select {...field} value={field.value || ''} label="Organisation Type">
                            <MenuItem value="Business">Business</MenuItem>
                            <MenuItem value="Research">Research</MenuItem>
                            <MenuItem value="Education">Education</MenuItem>
                            <MenuItem value="Government">Government</MenuItem>
                            <MenuItem value="Community Group">Community Group</MenuItem>
                            <MenuItem value="Philanthropy">Philanthropy</MenuItem>
                            <MenuItem value="Not for Profir">Not for Profit</MenuItem>
                          </Select>
                          {error && <FormHelperText error>{error?.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="OrganisationABN"
                      control={control}
                      defaultValue={formData?.OrganisationABN}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small" error={!!error}>
                          <TextField
                            {...field}
                            label="Organisation ABN (optional)"
                            value={field.value || ''}
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="OrganisationLocation"
                      control={control}
                      type="text"
                      defaultValue={formData?.OrganisationLocation}
                      rules={{ required: 'Organisation location is required.' }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <InputLabel id="org-location">Organisation location</InputLabel>
                          <Select
                            {...field}
                            value={field.value || ''}
                            onChange={(e) => {
                              handleLocationChange(e, field);
                              field.onChange(e);
                            }}
                            label="Organisation location">
                            <MenuItem value="NSW">NSW</MenuItem>
                            <MenuItem value="QLD">QLD</MenuItem>
                            <MenuItem value="SA">SA</MenuItem>
                            <MenuItem value="TAS">TAS</MenuItem>
                            <MenuItem value="VIC">VIC</MenuItem>
                            <MenuItem value="WA">WA</MenuItem>
                            <MenuItem value="ACT">ACT</MenuItem>
                            <MenuItem value="NT">NT</MenuItem>
                            <MenuItem value="Others">Outside Australia</MenuItem>
                          </Select>
                          {error && <FormHelperText error>{error?.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  {formData?.OrganisationLocation === 'Others' && (
                    <Grid item xs={6}>
                      <Controller
                        name="CustomLocation"
                        control={control}
                        defaultValue={formData?.CustomLocation}
                        rules={{
                          required: 'Location is required.'
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl fullWidth size="small" error={!!error}>
                            <TextField
                              {...field}
                              label="Enter location"
                              value={field.value || ''}
                              fullWidth
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Controller
                      name="OrganisationName"
                      control={control}
                      defaultValue={formData?.OrganisationName}
                      rules={{
                        required: 'Organisation name is required.'
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small" error={!!error}>
                          <TextField
                            {...field}
                            label="Organisation name"
                            value={field.value || ''}
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="Email"
                      control={control}
                      defaultValue={formData?.Email}
                      rules={{
                        required: 'Email is required.',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email format.'
                        }
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small" error={!!error}>
                          <TextField
                            {...field}
                            label="Email address"
                            value={field.value || ''}
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : null}
                            disabled={true}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="Website"
                      control={control}
                      defaultValue={formData?.Website}
                      rules={{
                        pattern: {
                          value:
                            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi, // eslint-disable-line
                          message: 'Invalid website URL format.'
                        }
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small" error={!!error}>
                          <TextField
                            {...field}
                            label="Website URL"
                            value={field.value || ''}
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="FacebookUrl"
                      control={control}
                      defaultValue={formData?.FacebookUrl}
                      rules={{
                        pattern: {
                          value:
                            /(?:https?:\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i, // eslint-disable-line
                          message: 'Invalid Facebook URL format.'
                        }
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small" error={!!error}>
                          <TextField
                            {...field}
                            label="Facebook URL"
                            value={field.value || ''}
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="TwitterUrl"
                      control={control}
                      defaultValue={formData?.TwitterUrl}
                      rules={{
                        pattern: {
                          value:
                            /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/, // eslint-disable-line
                          message: 'Invalid Twitter URL format.'
                        }
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small" error={!!error}>
                          <TextField
                            {...field}
                            label="Twitter URL"
                            value={field.value || ''}
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="LinkedinUrl"
                      control={control}
                      defaultValue={formData?.LinkedinUrl}
                      rules={{
                        pattern: {
                          value:
                            /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm, // eslint-disable-line
                          message: 'Invalid LinkedIn URL format.'
                        }
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small" error={!!error}>
                          <TextField
                            {...field}
                            label="Linkedin"
                            value={field.value || ''}
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="InstagramUrl"
                      control={control}
                      defaultValue={formData?.InstagramUrl}
                      rules={{
                        pattern: {
                          value: /(?:http:\/\/)?(?:www\.)?instagram\.com\/[a-z\d-_]{1,255}\s*$/i, // eslint-disable-line
                          message: 'Invalid Instagram URL format.'
                        }
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small" error={!!error}>
                          <TextField
                            {...field}
                            label="Instagram URL"
                            value={field.value || ''}
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} mb={2}>
                    <Button type="submit" fullWidth variant="contained" className={styles['btn']}>
                      Update account
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          )}
        </Paper>

        <ChangePassword />
      </Box>
    </>
  );
};

export default Profile;
