import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import utils from '../../../helpers/utils'

const EVALUATIONS_URL = `${process.env.REACT_APP_API_URL}/evaluations`;

const initialState = {
  evaluations: {},
  status: 'idle',
  updateStatus: 'idle',
  error: null
};

export const createEvaluation = createAsyncThunk('evaluations/createEvaluation', async (data) => {
  try {
    const accessToken = await utils.getValidAccessToken();
    const res = await axios.post(EVALUATIONS_URL, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    const evalId = res.data.UID;
    if (localStorage.getItem('evalId') === null) {
      localStorage.setItem('evalId', evalId.toString());
    }
    return res.data;
  } catch (err) {
    console.log('error', err.response.data.message);
  }
});

export const updateEvaluation = createAsyncThunk('evaluations/updateEvaluation', async (data) => {
  try {
    const accessToken = await utils.getValidAccessToken();
    if (localStorage.getItem('evalId') !== null) {
      const evalId = localStorage.getItem('evalId');
      const res = await axios.post(`${EVALUATIONS_URL}/${evalId}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return res.data;
    }
  } catch (err) {
    console.log('error', err.message);
    return err.message;
  }
});

export const fetchSearchEvaluations = createAsyncThunk(
  'filteredListings/fetchSearchEvaluations',
  async (query) => {
    try {
      const res = await axios.get(`${EVALUATIONS_URL}`, { params: query });
      // console.log('search res', res);
      return res.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const fetchEvaluations = createAsyncThunk(
  'evaluationListings/fetchEvaluations',
  async () => {
    try {
      const res = await axios.get(`${EVALUATIONS_URL}?SearchKey=`);
      // console.log('res', res);
      return res.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const evaluationSlice = createSlice({
  name: 'evaluations',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createEvaluation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createEvaluation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.evaluations = action.payload;
      })
      .addCase(createEvaluation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateEvaluation.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateEvaluation.fulfilled, (state, action) => {
        state.updateStatus = 'succeeded';
        state.evaluationListings = action.payload;
      })
      .addCase(updateEvaluation.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchEvaluations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEvaluations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.evaluationListings = action.payload;
      })
      .addCase(fetchEvaluations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchSearchEvaluations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSearchEvaluations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.filteredListings = action.payload;
      });
  }
});

// export const postEvaluation = (state) => state.evalaution.programOverview;
export const getAllEvaluations = (state) => state.evaluations.evaluationListings;
export const getFilteredEvaluations = (state) => state.evaluations.filteredListings;
export const getEvaluation = (state) => state.evaluations.evaluations;
export const getStatus = (state) => state.evaluations.status;
export const getUpdateStatus = (state) => state.evaluations.updateStatus;
export const getError = (state) => state.evaluations.error;

export default evaluationSlice.reducer;
