import { configureStore } from '@reduxjs/toolkit';
import evaluationsReducer from './features/evaluations/evaluationsSlice';
import evaluationReducer from './features/evaluation/evaluationSlice';
import programOverviewReducer from './features/program-overview/programOverviewSlice';
import defineReducer from './features/define/defineSlice';
import planReducer from './features/plan/planSlice';
import designReducer from './features/design/designSlice';
import executeReducer from './features/execute/executeSlice';
import usersReducer from './features/users/usersSlice';

export const store = configureStore({
  reducer: {
    evaluations: evaluationsReducer,
    evaluation: evaluationReducer,
    programOverview: programOverviewReducer,
    define: defineReducer,
    plan: planReducer,
    design: designReducer,
    execute: executeReducer,
    users: usersReducer
  }
});
