import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Resources from '../../components/Resources';
import styles from './Evaluation.module.scss';
import { fetchIndicators, getPlanStatus } from '../../services/features/plan/planSlice';

const Plan = ({
  control,
  activityItems,
  inputItems,
  outputItems,
  filteredGoals,
  goalIds,
  mappedIndicators,
  setChunkedInstruments,
  setChunkedSummary,
  setFormData,
  formData,
  setValue,
  getValues,
  errors,
  handleFieldChange
}) => {
  const dispatch = useDispatch();

  const [activities, setActivities] = useState(getValues('Activities') || []);
  const [inputs, setInputs] = useState(getValues('Inputs') || []);
  const [outputs, setOutputs] = useState(getValues('Outputs') || []);
  const [indicators, setIndicators] = useState(getValues('Indicators') || []);

  const planStatus = useSelector(getPlanStatus);

  const handleActivitiesChange = (e) => {
    const { value, name } = e.target;
    setActivities(value);

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleInputsChange = (e) => {
    const { value, name } = e.target;
    setInputs(value);

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleOutputsChange = (e) => {
    const { value, name } = e.target;
    setOutputs(value);

    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    if (planStatus === 'succeeded') {
      dispatch(fetchIndicators(goalIds?.toString()));
    }
  }, [dispatch]);

  const handleIndicatorsChange = (e) => {
    const { name, value } = e.target;
    setIndicators(value);
    setValue('Indicators', value);
    setValue('Instruments', []);
    for (let i = 0; i < getValues('Indicators')?.length; i++) {
      setValue(`Summary${i}`, '');
    }

    setFormData({
      ...formData,
      [name]: value,
      Instruments: []
    });
    setChunkedInstruments([]);
    setChunkedSummary([]);
  };

  return (
    <>
      <Paper elevation={2} sx={{ p: { xs: 2, sm: 4 }, mb: 5 }}>
        <Typography variant="h3" component="h2" color="primary" mb={4}>
          Plan
        </Typography>
        {Object.values(errors).length !== 0 && (
          <Alert variant="filled" severity="error" sx={{ mb: 3 }}>
            Please fill out all required fields.
          </Alert>
        )}
        <Typography variant="subtitle" component="p" mb={2} sx={{ fontSize: '14px' }}>
          * Required information
        </Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ mb: 0 }}>
            <Box component="div">
              <Typography variant="h4" component="h3">
                Activities | What will you do to achieve your goal(s)?
              </Typography>
              <Typography variant="body2" component="p">
                Maximum of 500 words.
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name="Activities"
              control={control}
              defaultValue={[]}
              rules={{ required: 'Activities is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" error={!!error}>
                  <InputLabel id="activities">Select an activity *</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      handleActivitiesChange(e, field);
                      field.onChange(e);
                    }}
                    labelId="activities"
                    label="Select an activity *"
                    multiple
                    renderValue={(selected) => selected.join(', ')}>
                    {activityItems &&
                      activityItems?.map((activity) => (
                        <MenuItem
                          key={activity.Code}
                          value={activity.Value}
                          className="multi-checkboxes">
                          <Checkbox
                            checked={activities && activities?.indexOf(activity.Value) > -1}
                          />
                          <ListItemText primary={activity.Value} />
                        </MenuItem>
                      ))}
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="ActivitiesDetails"
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleFieldChange(e, field);
                      field.onChange(e);
                    }}
                    multiline
                    rows={4}
                    placeholder="Please provide details (optional)"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Inputs | What is needed?
                <small>Identify the resources your program needs.</small>
              </Typography>
              <Typography variant="body2" component="p" sx={{ fontSize: '13px' }}>
                Maximum of 500 words.
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name="Inputs"
              control={control}
              defaultValue={[]}
              rules={{ required: 'Inputs is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" error={!!error} sx={{ mb: 2 }}>
                  <InputLabel id="inputs">Select inputs *</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      handleInputsChange(e, field);
                      field.onChange(e);
                    }}
                    labelId="inputs"
                    label="Select inputs *"
                    multiple
                    renderValue={(selected) => selected.join(', ')}>
                    {inputItems &&
                      inputItems?.map((input) => (
                        <MenuItem key={input.Code} value={input.Value} className="multi-checkboxes">
                          <Checkbox checked={inputs && inputs?.indexOf(input.Value) > -1} />
                          <ListItemText primary={input.Value} />
                        </MenuItem>
                      ))}
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="InputDetails"
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth size="small">
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleFieldChange(e, field);
                      field.onChange(e);
                    }}
                    multiline
                    rows={4}
                    placeholder="Please provide details (optional)"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Outputs | What is delivered?
                <small>
                  Outline the direct services, products and deliverables that your program will
                  generate.
                </small>
              </Typography>
              <Typography variant="body2" component="p" sx={{ fontSize: '13px' }}>
                Maximum of 500 words.
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name="Outputs"
              control={control}
              defaultValue={[]}
              rules={{ required: 'Outputs is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" error={!!error} sx={{ mb: 2 }}>
                  <InputLabel id="outputs">Select outputs *</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      handleOutputsChange(e, field);
                      field.onChange(e);
                    }}
                    labelId="outputs"
                    label="Select outputs *"
                    multiple
                    renderValue={(selected) => selected.join(', ')}>
                    {outputItems &&
                      outputItems?.map((output) => (
                        <MenuItem
                          key={output.Code}
                          value={output.Value}
                          className="multi-checkboxes">
                          <Checkbox checked={outputs && outputs?.indexOf(output.Value) > -1} />
                          <ListItemText primary={output.Value} />
                        </MenuItem>
                      ))}
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="OutputDetails"
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth size="small">
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleFieldChange(e, field);
                      field.onChange(e);
                    }}
                    multiline
                    rows={4}
                    placeholder="Please provide details (optional)"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Evaluation Priorities | What is most important to evaluate?
              </Typography>
              <Typography variant="body2" component="p">
                1 to 3 priorities for the evaluation.
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              control={control}
              name="EvaluationPriorities"
              defaultValue=""
              rules={{ required: 'Evaluation priorities is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small">
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleFieldChange(e, field);
                      field.onChange(e);
                    }}
                    multiline
                    rows={4}
                    placeholder="Please provide details"
                    size="small"
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                    required
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Key Questions | What do you want to know?
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" component="h4">
              Identify the key questions that you want the evaluation to answer based on the
              priorities listed above.
            </Typography>
            <Controller
              control={control}
              name="KeyQuestions"
              defaultValue=""
              rules={{ required: 'Key questions is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleFieldChange(e, field);
                      field.onChange(e);
                    }}
                    multiline
                    rows={4}
                    placeholder="Please provide details"
                    size="small"
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Indicators | What demonstrates change is happening?
                <small>
                  Indicators are what you will measure. They are markers that demonstrate if and
                  what change is happening.
                  <br />
                  <br />
                  The indicators in the dropdown menus below are specifically related to the goals
                  you chose in the Define stage. Select at least one indicator for each goal.
                </small>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0}>
              <Grid item xs={12} pt={0}>
                <Box className={styles['indicators-title']}>
                  <Typography variant="h5" component="h4" mb={2}>
                    Your goals (from the Define Stage)
                  </Typography>
                  <Typography variant="h5" component="h4" mb={2}>
                    Related Indicators <small>There should be at least 1 selected value</small>
                  </Typography>
                </Box>

                <Box className={styles['indicators-column']}>
                  <Box>
                    {filteredGoals?.map((goal, index) => {
                      return (
                        <FormControl key={index} fullWidth size="small" sx={{ mb: 2 }}>
                          <TextField
                            size="small"
                            name="Goals"
                            value={goal.Value}
                            placeholder="Please provide details"
                          />
                        </FormControl>
                      );
                    })}
                  </Box>

                  <Box>
                    {mappedIndicators != null &&
                      mappedIndicators.map((item, index) => {
                        return (
                          <Controller
                            key={index}
                            name="Indicators"
                            control={control}
                            type="text"
                            defaultValue={[]}
                            rules={{ required: 'Indicators is required.' }}
                            render={({ field, fieldState: { error } }) => (
                              <FormControl
                                fullWidth
                                size="small"
                                sx={{ mb: 2 }}
                                key={index}
                                error={!!error}>
                                <InputLabel id="indicators-label">Select indicators *</InputLabel>
                                <Select
                                  {...field}
                                  onChange={(e) => {
                                    handleIndicatorsChange(e, field);
                                    field.onChange(e);
                                  }}
                                  labelId="indicators-label"
                                  label="Select indicators"
                                  multiple
                                  input={
                                    <OutlinedInput
                                      label="Select indicators *"
                                      className={styles['selected-values']}
                                    />
                                  }>
                                  {item.map((value, i) => {
                                    return (
                                      <MenuItem
                                        key={i}
                                        value={value.Value}
                                        className="multi-checkboxes">
                                        <Checkbox
                                          checked={
                                            indicators && indicators.indexOf(value.Value) > -1
                                          }
                                          className={styles['checkbox']}
                                        />
                                        <ListItemText primary={value.Value} />
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                {<FormHelperText error>{error?.message}</FormHelperText>}
                              </FormControl>
                            )}
                          />
                        );
                      })}
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={12}>
                <Typography variant="h5" component="h4">
                  Please provide details and/or identify any additional indicators.
                </Typography>
                <Controller
                  control={control}
                  name="IndicatorDetails"
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        onChange={(e) => {
                          handleFieldChange(e, field);
                          field.onChange(e);
                        }}
                        multiline
                        rows={4}
                        placeholder="Please provide details (optional)"
                        size="small"
                        fullWidth
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper elevation={2} sx={{ p: 4, mb: 5 }}>
        <Resources />
      </Paper>
    </>
  );
};

Plan.propTypes = {
  control: PropTypes.object,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  errors: PropTypes.object,
  handleWordChange: PropTypes.func,
  word: PropTypes.string,
  activityItems: PropTypes.array,
  inputItems: PropTypes.array,
  outputItems: PropTypes.array,
  filteredGoals: PropTypes.array,
  goalIds: PropTypes.array,
  indicatorCode: PropTypes.array,
  mappedIndicators: PropTypes.array,
  selectedInstruments: PropTypes.array,
  chunkedInstruments: PropTypes.array,
  setChunkedInstruments: PropTypes.func,
  setChunkedSummary: PropTypes.func,
  handleFieldChange: PropTypes.func
};

export default Plan;
