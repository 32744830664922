import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Switch,
  Select,
  Snackbar,
  TextField,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Evaluation.module.scss';
import PDFFile from '../../components/PDF';
import ReactToPrint from 'react-to-print';
import FileUpload from '../../components/FileUpload';
import FileList from '../../components/FileUpload/FileList';
import chunk from '../../helpers/arrayChunk';
import utils from '../../helpers/utils';

const Share = ({
  control,
  formData,
  setFormData,
  errors,
  filteredIndicators,
  indicatorsCount,
  summaryFindings,
  setChunkedSummary,
  downloadBtn,
  setDownloadBtn,
  handlePublishEvaluation,
  submittedData
}) => {
  const [pdfDownload, setPdfDownload] = useState({
    define: true,
    plan: true,
    design: true,
    execute: true,
    share: true
  });
  const [publishEvaluation, setPublishEvaluation] = useState({
    define: true,
    plan: true,
    design: true,
    execute: true,
    share: true
  });
  const [indicators, setIndicators] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const evaluationRef = useRef();
  const evalId = localStorage.getItem('evalId');
  const [successMessage, setSuccessMessage] = useState(false);
  const [summary, setSummary] = useState([]);

  const mergedIndicators = [].concat.apply([], filteredIndicators);

  useEffect(() => {
    setIndicators(mergedIndicators);
  }, [mergedIndicators, indicators]);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData({ ...formData, [name]: value });
    setPdfDownload({ ...pdfDownload, [name]: checked });
    setPublishEvaluation({ ...publishEvaluation, [name]: checked });
  };

  const handleBooleanChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSummaryFindingsChange = (e) => {
    const { value, name } = e.target;
    setSummary(value);
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const chunkSummary = useCallback(() => {
    const summaryItems = chunk(summaryFindings, indicatorsCount);
    setChunkedSummary(summaryItems);
  }, [summary]);

  useEffect(() => {
    chunkSummary();
  }, [chunkSummary]);

  const handlePublishChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const removeFile = (filename) => {
    setSuccessMessage(true);
    setFiles(files.filter((file) => file.name !== filename));
  };

  const loadFiles = useCallback(async () => {
    const accessToken = await utils.getValidAccessToken();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'multipart/form-data'
      }
    };
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/evaluations/${evalId}`, config);
      setUploadedFiles(res.data[0].Attachments);
      return res.data;
    } catch (err) {
      return err.message;
    }
  }, [evalId]);

  useEffect(() => {
    loadFiles();
  }, [loadFiles, files]);

  const handleClose = () => {
    setSuccessMessage(false);
  };

  return (
    <Box>
      <Paper elevation={2} sx={{ p: 4, mb: 5 }}>
        {successMessage && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={successMessage}
            autoHideDuration={6000}
            onClose={handleClose}>
            <Alert elevation={2} onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              File is successfully deleted!
            </Alert>
          </Snackbar>
        )}
        <Typography variant="h3" component="h2" color="primary" mb={4}>
          Share
        </Typography>
        {Object.values(errors).length > 0 && (
          <Alert variant="filled" severity="error" sx={{ mb: 3 }}>
            Please fill out all required fields.
          </Alert>
        )}
        <Typography variant="subtitle" component="p" mb={2} sx={{ fontSize: '14px' }}>
          * Required information
        </Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ mb: 0 }}>
            <Box component="div">
              <Typography variant="h4" component="h3">
                Do you have evaluation findings?
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" component="h4">
              Have you completed your evaluation? Do you have results?
              <small>
                If you select ‘Yes’, you will be prompted to provide information about your
                evaluation findings.
                <br />
                If you select ‘No’, return to this point later when you have completed your
                evaluation. At that time, change your response to ‘Yes’ to provide information about
                your evaluation findings.
              </small>
            </Typography>
            <Controller
              name="EvaluationFindings"
              control={control}
              type="text"
              rules={{ required: 'This field is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" sx={{ mt: 2 }} error={!!error}>
                  <InputLabel id="goal">Please choose *</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      handleBooleanChange(e, field);
                      field.onChange(e);
                    }}
                    labelId="evaluation-findings-label"
                    label="Please choose *">
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </AccordionDetails>
        </Accordion>

        {formData.EvaluationFindings === 'Yes' && (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ mb: 0 }}>
                <Box component="div">
                  <Typography variant="h4" component="h3">
                    Executive Summary
                  </Typography>
                  <Typography variant="body2" component="p">
                    Maximum of 300 words.
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h5" component="h4">
                  Summarise the program background, goals, evaluation design and findings.
                </Typography>

                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        multiline
                        rows={4}
                        placeholder="Enter your summary..."
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="ExecutiveSummary"
                  control={control}
                  defaultValue=""
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Box component="div">
                  <Typography variant="h4" component="h3">
                    Findings summary by indicator
                  </Typography>
                  <Typography variant="body2" component="p">
                    Maximum of 500 words
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h5" component="h4">
                  Enter your findings for each indicator that you identified in the Plan Stage.
                </Typography>
                {indicators.map((item, index) => {
                  return (
                    <>
                      <Typography
                        key={index}
                        variant="body"
                        component="p"
                        sx={{ mt: 2 }}
                        name="Indicator"
                        className={styles['indicator']}>
                        {item.Value}
                      </Typography>

                      <Controller
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                              <TextField
                                {...field}
                                onChange={(e) => {
                                  handleSummaryFindingsChange(e, field);
                                  field.onChange(e);
                                }}
                                multiline
                                rows={6}
                                placeholder="Enter your summary..."
                                size="small"
                                fullWidth
                                inputProps={{ 'data-id': index }}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            </FormControl>
                          );
                        }}
                        name={`Summary${index}`}
                        control={control}
                        defaultValue=""
                      />
                    </>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Box component="div">
                  <Typography variant="h4" component="h3">
                    Evaluation detail
                  </Typography>
                  <Typography variant="body1" component="p">
                    Provide an answer for every of the questions below.
                  </Typography>
                  <Typography variant="body1" component="p">
                    Maximum of 500 words
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h5" component="h4">
                  Outline the program outputs (deliverables) achieved by the program [so far/end
                  date]. Do the achieved outcomes align with those you intended and specified in the
                  Plan stage? If yes, please describe how. If no, explain why.
                </Typography>

                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        multiline
                        rows={4}
                        placeholder="Enter your response..."
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="ProgramOutputs"
                  control={control}
                  defaultValue=""
                />

                <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
                  Outline the program outcomes (changes) achieved by the program [so far/end date].
                  Do the achieved outcomes align with those you intended and specified in the Plan
                  stage? If yes, please describe how. If no, explain why.
                </Typography>

                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        multiline
                        rows={4}
                        placeholder="Enter your response..."
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="ProgramOutcomes"
                  control={control}
                  defaultValue=""
                />

                <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
                  Has your program addressed the problem(s) you intended to address? If so, describe
                  how. If no, explain why.
                </Typography>

                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        multiline
                        rows={4}
                        placeholder="Enter your response..."
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="ProgramProblems"
                  control={control}
                  defaultValue=""
                />

                <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
                  What ongoing impact will the program have? Describe how your program will continue
                  to achieve the intended outcomes/impact?
                </Typography>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        multiline
                        rows={4}
                        placeholder="Enter your response..."
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="ProgramImpact"
                  control={control}
                  defaultValue=""
                />

                <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
                  Did the program result in any unexpected benefits? If yes, explain what and how.
                </Typography>

                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        multiline
                        rows={4}
                        placeholder="Enter your response..."
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="ProgramBenefits"
                  control={control}
                  defaultValue=""
                />

                <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
                  Did the program result in any unintended consequences? If yes, explain what and
                  how.
                </Typography>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        multiline
                        rows={4}
                        placeholder="Enter your response..."
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="ProgramResults"
                  control={control}
                  defaultValue=""
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Box component="div">
                  <Typography variant="h4" component="h3">
                    Lessons Learnt
                  </Typography>
                  <Typography variant="body1" component="p">
                    Maximum of 500 words
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h5" component="h4">
                  What lessons can you draw with the insights from the findings? What can be
                  improved, and how?
                </Typography>

                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        multiline
                        rows={4}
                        placeholder="Enter your response..."
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="LessonsLearnt"
                  control={control}
                  defaultValue=""
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Box component="div">
                  <Typography variant="h4" component="h3">
                    Conclusions
                  </Typography>
                  <Typography variant="body1" component="p">
                    Maximum of 500 words
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h5" component="h4">
                  What conclusions can you draw from the evaluation findings? What can we learn from
                  them?
                </Typography>

                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        multiline
                        rows={4}
                        placeholder="Enter your response..."
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="Conclusions"
                  control={control}
                  defaultValue=""
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Box component="div">
                  <Typography variant="h4" component="h3">
                    Recommendations
                  </Typography>
                  <Typography variant="body1" component="p">
                    Maximum of 500 words
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h5" component="h4">
                  Make recommendations based on your evaluation. What should we do with the insights
                  from the findings? Make your recommendations action-oriented and feasible. Arrange
                  them in order of importance.
                </Typography>

                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        multiline
                        rows={4}
                        placeholder="Enter your response..."
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="Recommendations"
                  control={control}
                  defaultValue=""
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Supplementary materials
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
              Upload supplementary material, if applicable (e.g., reports, presentation,
              deliverables).
            </Typography>
            <Typography variant="body1" component="p" sx={{ mt: 2 }}>
              Only upload aggregate data. Do not upload data that may identify individuals or
              contain sensitive information.
            </Typography>
            <Typography variant="body1" component="p">
              Read the <a href="/terms-of-use">Terms of Use</a> and{' '}
              <a href="/privacy-policy">Privacy Policy</a>.
            </Typography>
            <Box className={styles['upload-documents']}>
              <FileUpload
                url={`${process.env.REACT_APP_API_URL}/evaluations/${evalId}/attachments`}
                files={files}
                setFiles={setFiles}
                removeFile={removeFile}
              />
              <FileList files={uploadedFiles} fileType="Attachment" removeFile={removeFile} />
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Download evaluation report PDF
                <small>Save the form to download the report.</small>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup className={styles['toggle-switch']}>
              <Typography variant="body" component="p">
                Define
              </Typography>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch name="define" checked={pdfDownload.define} onChange={handleChange} />
                }
                label={pdfDownload.define ? 'Visible' : 'Not visible'}
              />
            </FormGroup>
            <FormGroup className={styles['toggle-switch']}>
              <Typography variant="body" component="p">
                Plan
              </Typography>
              <FormControlLabel
                labelPlacement="start"
                control={<Switch name="plan" checked={pdfDownload.plan} onChange={handleChange} />}
                label={pdfDownload.plan ? 'Visible' : 'Not visible'}
              />
            </FormGroup>
            <FormGroup className={styles['toggle-switch']}>
              <Typography variant="body" component="p">
                Design
              </Typography>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch name="design" checked={pdfDownload.design} onChange={handleChange} />
                }
                label={pdfDownload.design ? 'Visible' : 'Not visible'}
              />
            </FormGroup>
            <FormGroup className={styles['toggle-switch']}>
              <Typography variant="body" component="p">
                Execute
              </Typography>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch name="execute" checked={pdfDownload.execute} onChange={handleChange} />
                }
                label={pdfDownload.execute ? 'Visible' : 'Not visible'}
              />
            </FormGroup>
            <FormGroup className={styles['toggle-switch']}>
              <Typography variant="body" component="p">
                Share
              </Typography>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch name="share" checked={pdfDownload.share} onChange={handleChange} />
                }
                label={pdfDownload.share ? 'Visible' : 'Not visible'}
              />
            </FormGroup>
            <Box className={styles['download-report']}>
              <div ref={evaluationRef} className={styles['pdf-wrapper']}>
                <PDFFile
                  submittedData={submittedData}
                  formData={formData}
                  pdfDownload={pdfDownload}
                />
              </div>
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" component="button" disabled={downloadBtn}>
                    Download PDF
                  </Button>
                )}
                content={() => evaluationRef.current}
                documentTitle={`${formData?.ProgramName} Evaluation`}
                onAfterPrint={() => setDownloadBtn(true)}
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Publish evaluation details to the public repository
                <small>
                  By publishing your evaluation to the repository, you contribute evidence of what
                  works to improve equity in STEM for others to learn from. It also brings
                  accountability, transparency and demonstrable impact to your efforts. Read more
                  about the{' '}
                  <a href="/evaluate-programs/benefits-of-publishing-programs">
                    benefits of publishing your evaluation
                  </a>
                  .
                </small>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup className={styles['toggle-switch']}>
              <Typography variant="body" component="p">
                Define
              </Typography>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    name="DefineVisibility"
                    checked={formData.DefineVisibility}
                    onChange={handlePublishChange}
                  />
                }
                label={formData.DefineVisibility ? 'Visible' : 'Not visible'}
              />
            </FormGroup>
            <FormGroup className={styles['toggle-switch']}>
              <Typography variant="body" component="p">
                Plan
              </Typography>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    name="PlanVisibility"
                    checked={formData.PlanVisibility}
                    onChange={handlePublishChange}
                  />
                }
                label={formData.PlanVisibility ? 'Visible' : 'Not visible'}
              />
            </FormGroup>
            <FormGroup className={styles['toggle-switch']}>
              <Typography variant="body" component="p">
                Design
              </Typography>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    name="DesignVisibility"
                    checked={formData.DesignVisibility}
                    onChange={handlePublishChange}
                  />
                }
                label={formData.DesignVisibility ? 'Visible' : 'Not visible'}
              />
            </FormGroup>
            <FormGroup className={styles['toggle-switch']}>
              <Typography variant="body" component="p">
                Execute
              </Typography>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    name="ExecuteVisibility"
                    checked={formData.ExecuteVisibility}
                    onChange={handlePublishChange}
                  />
                }
                label={formData.ExecuteVisibility ? 'Visible' : 'Not visible'}
              />
            </FormGroup>
            <FormGroup className={styles['toggle-switch']}>
              <Typography variant="body" component="p">
                Share
              </Typography>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    name="ShareVisibility"
                    checked={formData.ShareVisibility}
                    onChange={handlePublishChange}
                  />
                }
                label={formData.ShareVisibility ? 'Visible' : 'Not visible'}
              />
            </FormGroup>
            <Box className={styles['download-report']}>
              <Button variant="contained" component="button" onClick={handlePublishEvaluation}>
                Publish Evaluation
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Box>
  );
};

Share.propTypes = {
  control: PropTypes.object,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  getValues: PropTypes.func,
  errors: PropTypes.object,
  handlePublishEvaluation: PropTypes.func,
  downloadBtn: PropTypes.bool,
  setDownloadBtn: PropTypes.func,
  filteredIndicators: PropTypes.array,
  indicatorsCount: PropTypes.array,
  summaryFindings: PropTypes.array,
  setChunkedSummary: PropTypes.func,
  submittedData: PropTypes.object
};

export default Share;
