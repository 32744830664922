const chunk = (items, chunksizes) => {
  const chunkArray = [];
  let count = 0,
    i = 0;
  // [0,0,1,1]
  while (i < items?.length) {
    const itemSize = chunksizes?.[count];
    const itemsSlice = items?.slice(i, i + itemSize);
    if (itemsSlice.length != 0 && itemSize != 0) {
      chunkArray?.push(itemsSlice);
    }
    // console.log('chunkArray', chunkArray);

    count = (count + 1) % chunksizes?.length;
    i += itemSize;
  }
  return chunkArray;
};

// const chunk = (arr, chunksizes) => {
//   let tempArray = [];
//   for (var i = 0; i < arr?.length; i += chunksizes) {
//     tempArray = arr?.slice(i, i + chunksizes);
//     console.log(tempArray);
//   }
//   return tempArray;
// };

export default chunk;
