import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from '../../authentication/UserPool';
import jwt_decode from 'jwt-decode';
import SessionExpiredModal from '../../components/Modal/SessionExpiredModal';

const AuthContext = createContext();

const AuthProvider = (props) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [sessionOpen, setSessionOpen] = useState(false);

  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const accessToken = session.getAccessToken().jwtToken;
            localStorage.setItem('token', accessToken);
            setAuthenticated(true);
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                }
              });
            });

            resolve({
              user,
              ...session,
              ...attributes,
              ...isAuthenticated
            });
          }
        });
      } else {
        reject();
      }
    });

  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log('onSuccess:', data);
          resolve(data);
          setAuthenticated(true);
        },

        onFailure: (err) => {
          console.error('onFailure:', err);
          reject(err);
        },

        newPasswordRequired: (data) => {
          console.log('newPasswordRequired:', data);
          resolve(data);
        }
      });
    });

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      window.location.href = '/';
      setAuthenticated(false);
    }
    localStorage.removeItem('token');
  };

  useEffect(() => {
    const token = localStorage.getItem('token', accessToken);
    if (token !== null) {
      setAccessToken(token);
      const decodedJwt = jwt_decode(token);
      if (decodedJwt && decodedJwt.exp * 1000 < Date.now()) {
        setSessionOpen(true);
      }
    }
  }, [accessToken, sessionOpen]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        getSession,
        logout
      }}>
      {props.children}
      {sessionOpen && <SessionExpiredModal />}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};

export { AuthProvider, AuthContext };
