import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import Resources from '../../components/Resources';
import styles from './Evaluation.module.scss';

const Execute = ({
  control,
  formData,
  setFormData,
  errors,
  analysisApproaches,
  handleFieldChange,
  getValues,
  setValue
}) => {
  const approachTooltip = (
    <p className={styles['tooltip']}>
      <strong>DESCRIPTIVE STATISTICS:</strong> Descriptive statistics describe your numerical data.
      At the most basic level, they include counts and percentages. <br />
      <strong>INFERENTIAL STATISTICS:</strong> : Inferential statistics make inferences, predictions
      or conclusions about numerical data rather than describing the data. Inferential statistics
      are more complex and are usually done by academic researchers and professional statisticians.
      <br />
      <strong>SENTIMENT ANALYSIS:</strong> Software that uses natural language processing and
      machine learning to summarise the feelings behind written responses (qualitative data).
      <br />
      <strong>TAGGING:</strong> Going through qualitative data and &apos;tagging&apos; (attaching a
      label) data based on themes, topics, ideas or keywords.
      <br />
      <strong>WORD CLOUDS:</strong> Visually displays most frequently used words in textual
      qualitative data, like written responses.
      <br />
      Read more about{' '}
      <a
        href="/static/media/National-Evaluation-Guide.1d2e6cf1fa612edd8156.pdf"
        target="_blank"
        rel="noreferrer">
        analysis approaches
      </a>
    </p>
  );

  const [approaches, setApproaches] = useState(getValues('AnalysisData') || []);

  const handleBooleanChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (formData.Recruit === 'No') {
      setValue('RecruitmentMethod', '');
      setValue('RecruitmentConsent', '');
      setValue('ConsentRequirementsFollowed', '');
    }
    if (formData.AcademicJournal === 'No') {
      setValue('HumanEthicsGuidelinesFollowed', '');
      setValue('HumanEthicsApprovalObtained', '');
      setValue('HumanEthicsApprovalNumber', '');
    }
    if (formData.HumanEthicsApprovalObtained === 'No') {
      setValue('HumanEthicsApprovalNumber', '');
    }
  };

  const approachesChange = (e) => {
    const { value, name } = e.target;
    setApproaches(value);
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <>
      <Paper elevation={2} sx={{ p: 4, mb: 5 }}>
        <Typography variant="h3" component="h2" color="primary" mb={4}>
          Execute
        </Typography>

        {Object.values(errors).length > 0 && (
          <Alert variant="filled" severity="error" sx={{ mb: 3 }}>
            Please fill out all required fields.
          </Alert>
        )}
        <Typography variant="subtitle" component="p" mb={2} sx={{ fontSize: '14px' }}>
          * Required information
        </Typography>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ mb: 0 }}>
            <Box component="div">
              <Typography variant="h4" component="h3">
                Consent
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" component="h4">
              Do you plan to recruit evaluation participants?
            </Typography>
            <Controller
              name="Recruit"
              control={control}
              defaultValue=""
              label="Do you plan to recruit evalaution participants?"
              rules={{ required: 'This field is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" sx={{ mt: 2, mb: 2 }} error={!!error}>
                  <InputLabel id="goal">Please choose *</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      handleBooleanChange(e, field);
                      field.onChange(e);
                    }}
                    labelId="recruit-label"
                    label="Please choose *">
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            {formData.Recruit === 'Yes' && (
              <>
                <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
                  How will you invite people to take part in your evaluation?
                </Typography>

                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        onChange={(e) => {
                          handleFieldChange(e, field);
                          field.onChange(e);
                        }}
                        multiline
                        rows={4}
                        placeholder="Please provide details *"
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="RecruitmentMethod"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'This field is required' }}
                />

                <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
                  How will you get informed consent from participants?
                </Typography>

                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                      <TextField
                        {...field}
                        onChange={(e) => {
                          handleFieldChange(e, field);
                          field.onChange(e);
                        }}
                        multiline
                        rows={4}
                        placeholder="Please provide details *"
                        size="small"
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </FormControl>
                  )}
                  name="RecruitmentConsent"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'This field is required' }}
                />

                <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
                  Are you following the consent requirements outlined in the{' '}
                  <a
                    href="https://www.nhmrc.gov.au/about-us/publications/national-statement-ethical-conduct-human-research-2007-updated-2018#toc__235"
                    target="_blank"
                    rel="noreferrer">
                    National Statement on Ethical Conduct in Human Research?
                  </a>
                </Typography>
                <Controller
                  name="ConsentRequirementsFollowed"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'This field is required.' }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2, mb: 2 }} error={!!error}>
                      <InputLabel id="goal">Please choose *</InputLabel>
                      <Select
                        {...field}
                        onChange={(e) => {
                          handleBooleanChange(e, field);
                          field.onChange(e);
                        }}
                        labelId="national-requirements-label"
                        label="Please choose *">
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      {error && <FormHelperText error>{error?.message}</FormHelperText>}
                      {formData.ConsentRequirementsFollowed === 'No' && (
                        <FormHelperText error>
                          <strong>Note:</strong> It is highly recommended that you follow the
                          consent requirements outlined in the National Statement on Ethical Conduct
                          in Human Research to ensure your evaluation is conducted ethically.
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Ethics
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" component="h4">
              Do you plan to publish in an academic journal or use the data for academic research?
            </Typography>
            <Controller
              name="AcademicJournal"
              control={control}
              defaultValue=""
              rules={{ required: 'This field is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" sx={{ mt: 2, mb: 2 }} error={!!error}>
                  <InputLabel id="goal">Please choose *</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      handleBooleanChange(e, field);
                      field.onChange(e);
                    }}
                    labelId="national-requirements-label"
                    label="Please choose *">
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            {formData.AcademicJournal === 'Yes' && (
              <>
                <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
                  Are you following the human ethics guidelines outlined in the{' '}
                  <a
                    href="https://www.nhmrc.gov.au/about-us/publications/national-statement-ethical-conduct-human-research-2007-updated-2018#toc__235"
                    target="_blank"
                    rel="noreferrer">
                    National Statement on Ethical Conduct in Human Research?
                  </a>
                </Typography>
                <Controller
                  name="HumanEthicsGuidelinesFollowed"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'This field is required.' }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2 }} error={!!error}>
                      <InputLabel id="goal">Please choose *</InputLabel>
                      <Select
                        {...field}
                        onChange={(e) => {
                          handleBooleanChange(e, field);
                          field.onChange(e);
                        }}
                        labelId="national-requirements-label"
                        label="Please choose *">
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      {error && <FormHelperText error>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />

                {formData.HumanEthicsGuidelinesFollowed === 'No' && (
                  <FormHelperText error>
                    <strong>Note:</strong> It is highly recommended that you follow the consent
                    requirements outlined in the National Statement on Ethical Conduct in Human
                    Research to ensure your evaluation is conducted ethically.
                  </FormHelperText>
                )}

                <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
                  Have you obtained human ethics approval through a registered{' '}
                  <a
                    href="https://www.nhmrc.gov.au/research-policy/ethics/human-research-ethics-committees"
                    target="_blank"
                    rel="noreferrer">
                    Human Research Ethics Committee (HREC)?
                  </a>
                </Typography>

                <Controller
                  name="HumanEthicsApprovalObtained"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'This field is required.' }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth size="small" sx={{ mt: 2, mb: 2 }} error={!!error}>
                      <InputLabel id="goal">Please choose *</InputLabel>
                      <Select
                        {...field}
                        onChange={(e) => {
                          handleBooleanChange(e, field);
                          field.onChange(e);
                        }}
                        labelId="national-requirements-label"
                        label="Please choose *">
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      {error && <FormHelperText error>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />

                {formData.HumanEthicsApprovalObtained === 'Yes' && (
                  <>
                    <Typography variant="h5" component="h4" sx={{ mt: 2 }}>
                      Please enter the HREC approval number
                    </Typography>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                          <TextField
                            {...field}
                            onChange={(e) => {
                              handleFieldChange(e, field);
                              field.onChange(e);
                            }}
                            placeholder="HREC approval number *"
                            size="small"
                            fullWidth
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        </FormControl>
                      )}
                      name="HumanEthicsApprovalNumber"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'HREC approval number is required' }}
                    />
                  </>
                )}
                {formData.HumanEthicsApprovalObtained === false && (
                  <FormHelperText error>
                    <strong>Note:</strong> You must obtain human ethics approval through a
                    registered HREC if you plan to publish in an academic journal or use the data
                    for academic research.
                  </FormHelperText>
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Box component="div">
              <Typography variant="h4" component="h3">
                Analysis approach | How will you analyse your data?
                <small>
                  There are many ways to examine, summarise and explain your data. Five common
                  approaches to analyse data are: descriptive statistics, inferential statistics,
                  sentiment analysis, tagging, and word clouds.
                  <br />
                  <br />
                  Click the information icon to find out more about the five common analysis
                  approaches.
                  <Tooltip disableFocusListener title={approachTooltip}>
                    <IconButton onClick={(e) => e.preventDefault()}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </small>
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" component="h4" sx={{ mb: 2 }}>
              Select from analysis approaches below
            </Typography>
            <Controller
              name="AnalysisData"
              control={control}
              type="text"
              defaultValue={[]}
              rules={{ required: 'Analysis Approaches is required.' }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth size="small" error={!!error}>
                  <InputLabel id="analysis-data-label">Select an analysis approach *</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      approachesChange(e, field);
                      field.onChange(e);
                    }}
                    labelId="analysis-data-label"
                    label="Select an analysis approach *"
                    multiple
                    renderValue={(selected) => selected.join(', ')}>
                    {analysisApproaches?.map((value) => {
                      return (
                        <MenuItem key={value.Code} value={value.Value} className="multi-checkboxes">
                          <Checkbox checked={approaches.indexOf(value.Value) > -1} />
                          <ListItemText primary={value.Value} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {error && <FormHelperText error>{error?.message}</FormHelperText>}
                </FormControl>
              )}
            />

            <Typography variant="h5" component="h4" mt={2}>
              Please provide details and/or identify any other data analysis approach(es).
            </Typography>

            <Controller
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleFieldChange(e, field);
                      field.onChange(e);
                    }}
                    multiline
                    rows={4}
                    placeholder="Please provide details"
                    size="small"
                    fullWidth
                  />
                </FormControl>
              )}
              name="AnalysisDataDetails"
              control={control}
              defaultValue=""
            />
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper elevation={2} sx={{ p: 4, mb: 5 }}>
        <Resources participantConsentForm={true} />
      </Paper>
    </>
  );
};

Execute.propTypes = {
  control: PropTypes.object,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  handleWordChange: PropTypes.func,
  word: PropTypes.string,
  analysisApproaches: PropTypes.array,
  handleFieldChange: PropTypes.func
};

export default Execute;
