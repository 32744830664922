import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styles from './PDF.module.scss';
import logo from '../../assets/images/logo.png';

const PDFFile = ({ submittedData, formData, pdfDownload, ref }) => {
  const { evalId } = useParams();
  const uniqueInstruments = [...new Set(formData?.Instruments)];

  const indicatorsData =
    evalId !== undefined || evalId !== null
      ? submittedData?.Goals?.map((goals) =>
          goals?.Indicators.map((indicators) => indicators?.Indicator)
        )
      : null;

  const mergedIndicatorsData = [].concat.apply([], indicatorsData);

  const SummaryFindingsData =
    evalId !== undefined || evalId !== null
      ? submittedData?.Goals?.map((goals) =>
          goals?.Indicators?.map((indicators) => indicators)
        ).flat(3)
      : null;

  return (
    <div id="pdf" ref={ref} className={styles['pdf-wrapper']}>
      <img className={styles['logo']} src={logo} alt="Women in STEM" width="150" />
      <div className={styles['eval-stages']}>
        <h2>{formData?.ProgramName}</h2>
        <h3>Program scale</h3>
        <p>{formData?.ProgramScale}</p>
        <h3>Sponsors</h3>
        <p>{formData?.Sponsors}</p>
        <h3>Program description</h3>
        <p>{formData?.ProgramDescription}</p>
        <h3>Smoothing STEM Pathways initiative</h3>
        <p>{formData?.SSP ? 'Yes' : 'No'}</p>
      </div>
      <div className={styles['eval-stages']}>
        <h2>Define</h2>
        {pdfDownload.define ? (
          <>
            <h3>Goals | What do you want to achieve?</h3>
            <ul className={styles['list-items']}>
              {formData &&
                formData?.Goals.map((goal, index) => {
                  return <li key={index}>{goal}</li>;
                })}
            </ul>
            <p>{formData?.GoalDetails}</p>
            <h3>Problems | What problem does the program address?</h3>
            <ul className={styles['list-items']}>
              {formData &&
                formData?.Problems.map((problem, index) => {
                  return <li key={index}>{problem}</li>;
                })}
            </ul>
            <p>{formData?.ProblemDetails}</p>
            <h3>Audience | Who are the program and evaluation for?</h3>
            <h4>Program Audiences | Who is the program for?</h4>
            <ul className={styles['list-items']}>
              {formData &&
                formData?.ProgramAudiences.map((audience, index) => {
                  return <li key={index}>{audience}</li>;
                })}
            </ul>
            <h4>Evaluation Audiences | Who is interested in the evaluation?</h4>
            <ul className={styles['list-items']}>
              {formData &&
                formData?.EvaluationAudiences.map((audience, index) => {
                  return <li key={index}>{audience}</li>;
                })}
            </ul>
            <p>{formData?.AudienceDetails}</p>
          </>
        ) : (
          <p>Define stage is not publicly available.</p>
        )}
      </div>
      <div className={styles['eval-stages']}>
        <h2>Plan</h2>
        {pdfDownload.plan ? (
          <>
            <h3>Activities | What will you do to achieve your goal(s)?</h3>
            <ul className={styles['list-items']}>
              {formData &&
                formData?.Activities.map((activity, index) => {
                  return <li key={index}>{activity}</li>;
                })}
            </ul>
            <p>{formData?.ActivitiesDetails}</p>
            <h3>Inputs | What is needed?</h3>
            <ul className={styles['list-items']}>
              {formData &&
                formData?.Inputs.map((input, index) => {
                  return <li key={index}>{input}</li>;
                })}
            </ul>
            <p>{formData?.InputDetails}</p>
            <h3>Outputs | What is delivered?</h3>
            <ul className={styles['list-items']}>
              {formData &&
                formData?.Outputs.map((outputs, index) => {
                  return <li key={index}>{outputs}</li>;
                })}
            </ul>
            <p>{formData?.OutputDetails}</p>
            <h3>Evaluation Priorities | What is the most important to evaluate?</h3>
            <p>{formData?.EvaluationPriorities}</p>
            <h3>Key Questions | What do you want to know?</h3>
            <h4>
              Identify the key questions that you want the evaluation to answer based on the
              priorities listed above.{' '}
            </h4>
            <p>{formData?.KeyQuestions}</p>
            <h3>Indicators | What demonstrates change is happening?</h3>
            <ul className={styles['list-items']}>
              {mergedIndicatorsData &&
                mergedIndicatorsData.map((indicator, index) => {
                  return <li key={index}>{indicator}</li>;
                })}
            </ul>
            <p>{formData?.IndicatorDetails}</p>
          </>
        ) : (
          <p>Plan stage is not publicly available.</p>
        )}
      </div>
      <div className={styles['eval-stages']}>
        <h2>Design</h2>
        {pdfDownload.design ? (
          <>
            <h3>Data collection tool | Survey, interview, tracking and metrics?</h3>
            <ul className={styles['list-items']}>
              {uniqueInstruments &&
                uniqueInstruments?.map((indicator, index) => {
                  return <li key={index}>{indicator}</li>;
                })}
            </ul>
            <p>{formData?.InstrumentDetails}</p>
            <h3>Evaluation Approach | Which approach will you use?</h3>
            <ul className={styles['list-items']}>
              {formData &&
                formData?.EvaluationApproaches.map((approach, index) => {
                  return <li key={index}>{approach}</li>;
                })}
            </ul>
            <p>{formData?.EvaluationApproachDetails}</p>
            <h3>Data collection | What data collection method will you use?</h3>
            <ul className={styles['list-items']}>
              {formData &&
                formData?.DataCollectionMethods.map((collectionMethod, index) => {
                  return <li key={index}>{collectionMethod}</li>;
                })}
            </ul>
            <p>{formData?.DataCollection}</p>
            <h3>Data Management Plan | Where and how will you safeguard your data?</h3>
            <table>
              <tbody>
                <tr>
                  <th scope="col">Data Type</th>
                  <th scope="col">How is data stored securely?</th>
                  <th scope="col">How is access restricted?</th>
                  <th scope="col">How long will data be kept?</th>
                </tr>
                <tr>
                  <td>{formData?.DataType1}</td>
                  <td>{formData?.DataStored1}</td>
                  <td>{formData?.DataAccess1}</td>
                  <td>{formData?.DataDuration1}</td>
                </tr>
                {formData?.DataType2 && (
                  <tr>
                    <td>{formData?.DataType2}</td>
                    <td>{formData?.DataStored2}</td>
                    <td>{formData?.DataAccess2}</td>
                    <td>{formData?.DataDuration2}</td>
                  </tr>
                )}

                {formData?.DataType3 && (
                  <tr>
                    <td>{formData?.DataType3}</td>
                    <td>{formData?.DataStored3}</td>
                    <td>{formData?.DataAccess3}</td>
                    <td>{formData?.DataDuration3}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        ) : (
          <p>Design stage is not publicly available.</p>
        )}
      </div>
      <div className={styles['eval-stages']}>
        <h2>Execute</h2>
        {pdfDownload.execute ? (
          <>
            <h3>Consent</h3>
            <p>Do you plan to recruit evaluation participants?</p>
            <p>- {formData?.Recruit}</p>
            {formData?.Recruit === 'Yes' && (
              <>
                <p>How will you invite people to take part in your evaluation?</p>
                <p>- {formData?.RecruitmentMethod}</p>
                <p>How will you get informed consent from participants?</p>
                <p>- {formData?.RecruitmentConsent}</p>
                <p>
                  Are you following the consent requirements outlined in the National Statement on
                  Ethical Conduct in Human Research?
                </p>
                <p>- {formData?.ConsentRequirementsFollowed}</p>
              </>
            )}

            <h3>Ethics</h3>
            <p>
              Do you plan to publish in an academic journal or use the data for academic research?
            </p>
            <p>- {formData?.AcademicJournal}</p>
            {formData?.AcademicJournal === 'Yes' && (
              <>
                <p>
                  Are you following the human ethics guidelines outlined in the National Statement
                  on Ethical Conduct in Human Research?
                </p>
                <p>- {formData?.HumanEthicsGuidelinesFollowed}</p>
                <p>
                  Have you obtained human ethics approval through a registered Human Research Ethics
                  Committee (HREC)?
                </p>
                <p>{formData?.HumanEthicsApprovalObtained}</p>
                {formData?.HumanEthicsApprovalObtained === 'Yes' && (
                  <>
                    <p>HREC approval number</p>
                    <p>- {formData?.HumanEthicsApprovalNumber}</p>
                  </>
                )}
              </>
            )}

            <h3>Analysis Approach | How will you analyse your data?</h3>
            <ul className={styles['list-items']}>
              {formData &&
                formData?.AnalysisData.map((analysis, index) => {
                  return <li key={index}>{analysis}</li>;
                })}
            </ul>
            {formData?.AnalysisDataDetails && (
              <>
                <h3>
                  Please provide details and/or identify any other data analysis approach(es).
                </h3>
                <p>{formData && formData?.AnalysisDataDetails}</p>
              </>
            )}
          </>
        ) : (
          <p>Execute stage is not publicly available.</p>
        )}
      </div>

      <div className={styles['eval-stages']}>
        <h2>Share</h2>
        {pdfDownload.share ? (
          <>
            <h3>Do you have evaluation findings?</h3>
            <p>
              Have you completed your evaluation? Do you have results?:{' '}
              {submittedData?.EvaluationFindings}
            </p>
            <h3>Executive Summary</h3>
            <h4>Summarise the program background, goals, evaluation design and findings.</h4>
            <p>
              {evalId !== undefined || evalId !== null
                ? submittedData?.EvaluationFindings === 'Yes'
                  ? submittedData?.ExecutiveSummary
                    ? submittedData?.ExecutiveSummary
                    : 'Not yet available.'
                  : 'Not yet available.'
                : 'Not yet available.'}
            </p>
            <h3>Findings summary by indicator</h3>
            <ul className={styles['list-items']}>
              {SummaryFindingsData?.map((element, index) => {
                return (
                  <li key={index}>
                    {element?.Indicator}
                    <p>
                      -
                      {evalId !== undefined || evalId !== null
                        ? submittedData?.EvaluationFindings === 'Yes'
                          ? element?.Summary
                            ? element?.Summary
                            : 'Not yet available.'
                          : 'Not yet available.'
                        : 'Not yet available.'}
                    </p>
                  </li>
                );
              })}
            </ul>
            <h3>Evaluation detail</h3>
            <h4>
              Outline the program outputs (deliverables) achieved by the program [so far/end date].
              Do the achieved outcomes align with those you intended and specified in the Plan
              stage? If yes, please describe how. If no, explain why.
            </h4>
            <p>
              {evalId !== undefined || evalId !== null
                ? submittedData?.EvaluationFindings === 'Yes'
                  ? submittedData?.ProgramOutputs
                    ? submittedData?.ProgramOutputs
                    : 'Not yet available.'
                  : 'Not yet available.'
                : 'Not yet available.'}
            </p>
            <br />
            <h4>
              Outline the program outcomes (changes) achieved by the program [so far/end date]. Do
              the achieved outcomes align with those you intended and specified in the Plan stage?
              If yes, please describe how. If no, explain why.
            </h4>
            <p>
              {evalId !== undefined || evalId !== null
                ? submittedData?.EvaluationFindings === 'Yes'
                  ? submittedData?.ProgramOutcomes
                    ? submittedData?.ProgramOutcomes
                    : 'Not yet available.'
                  : 'Not yet available.'
                : 'Not yet available.'}
            </p>
            <br />
            <h4>
              Has your program addressed the problem(s) you intended to address? If so, describe
              how. If no, explain why.
            </h4>
            <p>
              {evalId !== undefined || evalId !== null
                ? submittedData?.EvaluationFindings === 'Yes'
                  ? submittedData?.ProgramProblems
                    ? submittedData?.ProgramProblems
                    : 'Not yet available.'
                  : 'Not yet available.'
                : 'Not yet available.'}
            </p>
            <br />
            <h4>
              What ongoing impact will the program have? Describe how your program will continue to
              achieve the intended outcomes/impact?
            </h4>
            <p>
              {evalId !== undefined || evalId !== null
                ? submittedData?.EvaluationFindings === 'Yes'
                  ? submittedData?.ProgramImpact
                    ? submittedData?.ProgramImpact
                    : 'Not yet available.'
                  : 'Not yet available.'
                : 'Not yet available.'}
            </p>
            <br />
            <h4>
              Did the program result in any unexpected benefits? If yes, explain what and how.
            </h4>
            <p>
              {evalId !== undefined || evalId !== null
                ? submittedData?.EvaluationFindings === 'Yes'
                  ? submittedData?.ProgramBenefits
                    ? submittedData?.ProgramBenefits
                    : 'Not yet available.'
                  : 'Not yet available.'
                : 'Not yet available.'}
            </p>
            <br />
            <h4>
              Did the program result in any unintended consequences? If yes, explain what and how.
            </h4>
            <p>
              {evalId !== undefined || evalId !== null
                ? submittedData?.EvaluationFindings === 'Yes'
                  ? submittedData?.ProgramResults
                    ? submittedData?.ProgramResults
                    : 'Not yet available.'
                  : 'Not yet available.'
                : 'Not yet available.'}
            </p>
            <br />
            <h3>Lesson Learnt</h3>
            <h4>
              What lessons can you draw with the insights from the findings? What can be improved,
              and how?
            </h4>
            <p>
              {evalId !== undefined || evalId !== null
                ? submittedData?.EvaluationFindings === 'Yes'
                  ? submittedData?.LessonsLearnt
                    ? submittedData?.LessonsLearnt
                    : 'Not yet available.'
                  : 'Not yet available.'
                : 'Not yet available.'}
            </p>
            <br />
            <h3>Conclusions</h3>
            <h4>
              What conclusions can you draw from the evaluation findings? What can we learn from
              them?
            </h4>
            <p>
              {evalId !== undefined || evalId !== null
                ? submittedData?.EvaluationFindings === 'Yes'
                  ? submittedData?.Conclusions
                    ? submittedData?.Conclusions
                    : 'Not yet available.'
                  : 'Not yet available.'
                : 'Not yet available.'}
            </p>
            <br />
            <h3>Recommendations</h3>
            <h4>
              Make recommendations based on your evaluation. What should we do with the insights
              from the findings? Make your recommendations action-oriented and feasible. Arrange
              them in order of importance.
            </h4>
            <p>
              {evalId !== undefined || evalId !== null
                ? submittedData?.EvaluationFindings === 'Yes'
                  ? submittedData?.Recommendations
                    ? submittedData?.Recommendations
                    : 'Not yet available.'
                  : 'Not yet available.'
                : 'Not yet available.'}
            </p>
          </>
        ) : (
          <p>Share stage is not publicly available.</p>
        )}
      </div>

      <p className={styles['disclaimer']}>
        <strong>Disclaimer:</strong> The STEM Equity Evaluation Portal is a resource and database
        offered to the public by the Office of the Women in STEM Ambassador (the Office) with
        support from the Australian Government. The Office is not a publisher, but rather collects,
        indexes and archives equity program evaluations produced and published by other
        organisations. Evaluations on this database are not peer reviewed and the presence of any
        evaluation does not imply an endorsement of, or concurrence with, the contents by the Office
        or the Australian Government.
      </p>
    </div>
  );
};

PDFFile.propTypes = {
  id: PropTypes.string,
  formData: PropTypes.object,
  submittedData: PropTypes.object,
  pdfDownload: PropTypes.object,
  ref: PropTypes.func,
  getValues: PropTypes.func,
  setFormData: PropTypes.func
};

export default PDFFile;
