import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  // TextField,
  Typography
} from '@mui/material';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import styles from './Search.module.scss';
import {
  getProgramScales,
  fetchProgramScales,
  getStatus
} from '../../services/features/program-overview/programOverviewSlice';
import {
  fetchGoals,
  fetchProgramAudiences,
  selectAllGoals,
  selectAllProgramAudiences
} from '../../services/features/define/defineSlice';
import {
  fetchDesignApproaches,
  selectAllDesignApproaches,
  fetchDataCollectionMethods,
  selectAllDataCollectionMethods
} from '../../services/features/design/designSlice';

function AdvancedSearch({
  query,
  setQuery,
  programAudiences,
  setProgramAudiences,
  goals,
  setGoals,
  programScales,
  setProgramScales,
  progressLevel,
  setProgressLevel,
  designApproaches,
  setDesignApproaches,
  methodology,
  setMethodology
}) {
  const [filterData, setFilterData] = useState({
    ProgramAudiences: [],
    Goals: [],
    ProgramScales: [],
    ProgressLevel: [],
    EvaluationApproaches: [],
    DesignCollection: []
  });
  const dispatch = useDispatch();
  const filterStatus = useSelector(getStatus);

  const programScaleItems = useSelector(getProgramScales);
  // const [programScales, setProgramScales] = useState([]);
  const goalItems = useSelector(selectAllGoals);
  // const [goals, setGoals] = useState([]);
  const programAudienceItems = useSelector(selectAllProgramAudiences);
  // const [programAudiences, setProgramAudiences] = useState([]);
  // const [progressLevel, setProgressLevel] = useState([]);
  const designApproachItems = useSelector(selectAllDesignApproaches);
  // const [designApproaches, setDesignApproaches] = useState([]);
  const dataCollectionMethodItems = useSelector(selectAllDataCollectionMethods);
  // const [methodology, setCollectionMethods] = useState([]);

  useEffect(() => {
    if (filterStatus === 'idle') {
      dispatch(fetchProgramScales());
      dispatch(fetchGoals());
      dispatch(fetchProgramAudiences());
      dispatch(fetchDesignApproaches());
      dispatch(fetchDataCollectionMethods());
    }
  }, [dispatch]);

  const handleProgramAudienceChange = (event) => {
    const { value, name } = event.target;
    setProgramAudiences(typeof value === 'string' ? value.split(',') : value);
    setFilterData({
      ...filterData,
      [name]: value
    });
  };

  const handleGoalsChange = (event) => {
    const { value, name } = event.target;
    const selectedGoals = typeof value === 'string' ? value.split(',') : value;
    setGoals(selectedGoals);

    setFilterData({
      ...filterData,
      [name]: value
    });
  };

  const handleProgramScalesChange = (event) => {
    const { value, name } = event.target;
    setProgramScales(typeof value === 'string' ? value.split(',') : value);
    setFilterData({
      ...filterData,
      [name]: value
    });
  };

  const handleProgressLevelChange = (event) => {
    const { value, name } = event.target;
    setProgressLevel(typeof value === 'string' ? value.split(',') : value);
    setFilterData({
      ...filterData,
      [name]: value
    });
  };

  const handleDesignApproachChange = (event) => {
    const { value, name } = event.target;
    setDesignApproaches(typeof value === 'string' ? value.split(',') : value);
    setFilterData({
      ...filterData,
      [name]: value
    });
  };

  const handleDesignCollectionChange = (event) => {
    const { value, name } = event.target;
    setMethodology(typeof value === 'string' ? value.split(',') : value);
    setFilterData({
      ...filterData,
      [name]: value
    });
  };

  console.log('filter', filterData);
  console.log('audoence', programAudiences);

  return (
    <>
      <Typography variant="h2" component="h2">
        Advanced Search
      </Typography>
      <Box className={styles['search-form-wrapper']}>
        <form className={styles['search-form']} method="GET" action="/search-programs">
          <InputBase
            id="search-bar"
            className={styles['search-field']}
            label="Search programs"
            variant="outlined"
            placeholder="Search program type or organisation"
            size="small"
            fullWidth
            name="SearchKey"
            value={query}
            onInput={(e) => setQuery(e.target.value)}
          />
          <IconButton type="submit" aria-label="search" className={styles['search-btn']}>
            <SearchIcon color="primary" />
          </IconButton>
          <FormControlLabel control={<Checkbox defaultChecked />} label="Include similar results" />
          <Box mt={2} className={styles['search-filters']}>
            <Typography variant="body1" component="h3">
              Filter Search
            </Typography>
            <FormControl className={styles['search-filter']}>
              <InputLabel id="program-audiences-label">Program Audiences</InputLabel>
              <Select
                labelId="program-audiences-label"
                id="program-audiences"
                name="ProgramAudiences"
                multiple
                value={filterData?.ProgramAudiences || programAudiences}
                onChange={handleProgramAudienceChange}
                input={<OutlinedInput label="Program Audiences" />}
                renderValue={(selected) => selected.join(', ')}>
                {programAudienceItems &&
                  programAudienceItems?.map((audience) => (
                    <MenuItem
                      key={audience.Code}
                      value={audience.Value}
                      className="multi-checkboxes">
                      <Checkbox checked={programAudiences.indexOf(audience.Value) > -1} />
                      <ListItemText primary={audience.Value} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className={styles['search-filter']}>
              <InputLabel id="goals-label">Intended Outcomes</InputLabel>
              <Select
                labelId="goals-label"
                id="goals"
                name="Goals"
                multiple
                value={filterData?.Goals || goals}
                onChange={handleGoalsChange}
                input={<OutlinedInput label="Intended Outcomes" />}
                renderValue={(selected) => selected.join(', ')}>
                {goalItems &&
                  goalItems?.map((goal) => (
                    <MenuItem key={goal.Code} value={goal.Value} className="multi-checkboxes">
                      <Checkbox checked={goals.indexOf(goal.Value) > -1} />
                      <ListItemText primary={goal.Value} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className={styles['search-filter']}>
              <InputLabel id="program-scale-label">Program Scale</InputLabel>
              <Select
                labelId="program-scale-label"
                id="program-scale"
                name="ProgramScales"
                multiple
                value={filterData?.ProgramScales || programScales}
                onChange={handleProgramScalesChange}
                input={<OutlinedInput label="Program Scale" />}
                renderValue={(selected) => selected.join(', ')}>
                {programScaleItems?.map((item) => (
                  <MenuItem key={item.Code} value={item.Value === "Regional (state/territory/province)" ? "Regional" : item.Value}>
                    <Checkbox checked={programScales.indexOf(item.Value === "Regional (state/territory/province)" ? "Regional" : item.Value) > -1} />
                    <ListItemText primary={item.Value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={styles['search-filter']}>
              <InputLabel id="progress-level-label">Progress Level</InputLabel>
              <Select
                labelId="progress-level-label"
                id="progress-level"
                name="ProgressLevel"
                multiple
                value={filterData?.ProgressLevel || progressLevel}
                onChange={handleProgressLevelChange}
                input={<OutlinedInput label="Program Level" />}
                renderValue={(selected) => selected.join(', ')}>
                <MenuItem value="In Progress">
                  <Checkbox checked={progressLevel.indexOf('In Progress') > -1} />
                  <ListItemText primary="In Progress" />
                </MenuItem>
                <MenuItem value="Complete">
                  <Checkbox checked={progressLevel.indexOf('Complete') > -1} />
                  <ListItemText primary="Complete" />
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl className={styles['search-filter']}>
              <InputLabel id="design-approach-label">Design Approach</InputLabel>
              <Select
                labelId="design-approach-label"
                id="design-approach"
                name="EvaluationApproaches"
                multiple
                value={filterData?.DesignApproaches || designApproaches}
                onChange={handleDesignApproachChange}
                input={<OutlinedInput label="Design Approach" />}
                renderValue={(selected) => selected.join(', ')}>
                {designApproachItems?.map((item) => (
                  <MenuItem key={item.Code} value={item.Value}>
                    <Checkbox checked={designApproaches.indexOf(item.Value) > -1} />
                    <ListItemText primary={item.Value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={styles['search-filter']}>
              <InputLabel id="design-collection-label">Methodology</InputLabel>
              <Select
                labelId="design-collection-label"
                id="design-collection"
                name="DesignCollection"
                multiple
                value={filterData?.DesignCollection || methodology}
                onChange={handleDesignCollectionChange}
                input={<OutlinedInput label="Methodology" />}
                renderValue={(selected) => selected.join(', ')}>
                {dataCollectionMethodItems?.map((item) => (
                  <MenuItem key={item.Code} value={item.Value}>
                    <Checkbox checked={methodology.indexOf(item.Value) > -1} />
                    <ListItemText primary={item.Value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* <Box className={styles['date-filters']}>
            <Typography variant="body1" component="h3">
              Filter by date range
            </Typography>
            <Box className={styles['date-filter']}>
              <Box className={styles['date-range']}>
                <Typography variant="body2" component="h4">
                  From:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="From"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
              <Box className={styles['date-range']}>
                <Typography variant="body2" component="h4">
                  To:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="To"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box> */}

          <Box className={styles['advanced-search-btn']}>
            <Button type="submit" variant="contained">
              Search
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}

AdvancedSearch.propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func,
  programAudiences: PropTypes.array,
  setProgramAudiences: PropTypes.func,
  goals: PropTypes.array,
  setGoals: PropTypes.func,
  programScales: PropTypes.array,
  setProgramScales: PropTypes.func,
  progressLevel: PropTypes.array,
  setProgressLevel: PropTypes.func,
  designApproaches: PropTypes.array,
  setDesignApproaches: PropTypes.func,
  methodology: PropTypes.array,
  setMethodology: PropTypes.func
};

export default AdvancedSearch;
