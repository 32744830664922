import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Typography
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './EvaluationProgram.module.scss';
// import bannerImage from '../../assets/images/ProfLisaHarvey-Smith.jpg';
import defaultAvatar from '../../assets/images/default-avatar.png';
import EvaluationCard from '../../components/Card/EvaluationCard';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  getEvaluationStatus,
  singleEvaluation,
  getEvaluation
} from '../../services/features/evaluation/evaluationSlice';
import {
  getAllEvaluations,
  fetchEvaluations
} from '../../services/features/evaluations/evaluationsSlice';
import { useSelector, useDispatch } from 'react-redux';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const EvaluationProgram = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const evaluationStatus = useSelector(getEvaluationStatus);
  const evaluation = useSelector(getEvaluation);
  const evaluations = useSelector(getAllEvaluations);
  const { evalId } = useParams();
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const [bookmarks, setBookmarks] = useState(JSON.parse(localStorage.getItem('bookmarks')) || []);
  const [count, setCount] = useState(0);

  useEffect(() => {
    localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
  }, [bookmarks]);

  const handleBookmark = (evalId) => () => {
    const selectedEvaluation = evaluation?.find((item) => {
      return item?.UID === evalId;
    });

    setBookmarks([...bookmarks, selectedEvaluation]);
  };

  const isFound = bookmarks.some((element) => {
    if (element.UID === evalId) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    dispatch(singleEvaluation(evalId));
  }, [evalId, evaluationStatus, dispatch]);

  useEffect(() => {
    dispatch(fetchEvaluations());
  }, [dispatch]);

  const filteredEvaluations = evaluations?.filter((item) => item.Published === true);

  const goToNextProgram = (e) => {
    e.preventDefault();
    setCount((count + 1) % filteredEvaluations.length);
    navigate(`/program/${filteredEvaluations[count].UID}`);
  };

  const goals =
    evaluation[0]?.Goals &&
    evaluation[0]?.Goals.map((item, index) => <li key={index}>{item?.Goal}</li>);
  const problems =
    evaluation[0]?.Problems &&
    evaluation[0]?.Problems.map((item, index) => <li key={index}>{item}</li>);
  const programAudiences =
    evaluation[0]?.ProgramAudiences &&
    evaluation[0]?.ProgramAudiences.map((item, index) => <li key={index}>{item}</li>);
  const evaluationAudiences =
    evaluation[0]?.EvaluationAudiences &&
    evaluation[0]?.EvaluationAudiences.map((item, index) => <li key={index}>{item}</li>);
  const activities =
    evaluation[0]?.Activities &&
    evaluation[0]?.Activities.map((item, index) => <li key={index}>{item}</li>);
  const inputs =
    evaluation[0]?.Inputs &&
    evaluation[0]?.Inputs.map((item, index) => <li key={index}>{item}</li>);
  const outputs =
    evaluation[0]?.Outputs &&
    evaluation[0]?.Outputs.map((item, index) => <li key={index}>{item}</li>);
  const evaluationApproaches =
    evaluation[0]?.EvaluationApproaches &&
    evaluation[0]?.EvaluationApproaches.map((item, index) => <li key={index}>{item}</li>);
  const collectionMethods =
    evaluation[0]?.DataCollectionMethods &&
    evaluation[0]?.DataCollectionMethods.map((item, index) => <li key={index}>{item}</li>);
  const indicators =
    evaluation[0]?.Goals &&
    evaluation[0]?.Goals.map((item) =>
      item?.Indicators.map((indicators, index) => <li key={index}>{indicators.Indicator}</li>)
    );
  const indicatorsSummary =
    evaluation[0]?.Goals &&
    evaluation[0]?.Goals.map((item) =>
      item?.Indicators.map((indicators, index) => (
        <li key={index}>
          {indicators.Indicator} <br /> —{' '}
          {indicators.Summary ? indicators.Summary : 'Not yet available.'}
        </li>
      ))
    );
  const analysisData =
    evaluation[0]?.AnalysisData &&
    evaluation[0]?.AnalysisData?.map((item, index) => <li key={index}>{item}</li>);

  const instruments =
    evaluation[0]?.Goals &&
    evaluation[0]?.Goals.map((item) =>
      item?.Indicators?.map((indicators) => {
        return indicators.Instruments.map((instrument) =>
          instrument.Instruments !== 'Tracking and metrics' && instrument.Instruments !== 'Other'
            ? instrument?.Instruments
            : instrument?.Instruments
        );
      })
    );

  const mergedInstruments = instruments?.flat(2);
  const uniqueInstruments = [...new Set(mergedInstruments)];

  const createdDate = new Date(evaluation[0]?.DateCreated);
  const updatedDate = new Date(evaluation[0]?.DateUpdated);

  const relatedPrograms = filteredEvaluations?.filter((item) => {
    const goalIds = item.Goals.map((goals) => goals.Code);
    const selectedGoals = evaluation[0]?.Goals.map((goals) => goals.Code);

    return item.UID !== evalId && goalIds?.some((value) => selectedGoals?.indexOf(value) >= 0);
  });

  const attachments = evaluation[0]?.Attachments?.map((attachment) => attachment);

  return (
    <Box className={styles['evaluation-program']}>
      <Container maxWidth="xl" className={styles.container}>
        <Grid container maxWidth="xl" sx={{ margin: 'auto', px: '16px' }}>
          <Grid item xs={12} className={styles['evaluation-header']}>
            <Typography variant="h1" component="h1">
              {evaluation[0]?.ProgramName}
            </Typography>
            {evaluation[0]?.SSP === true && (
              <Typography variant="body1" component="p" color="primary">
                Smoothing STEM Pathways initiative
              </Typography>
            )}

            <Typography variant="body2" component="p" mt={3}>
              <strong>Program Scale:</strong> {evaluation[0]?.ProgramScale}
            </Typography>
            <Typography variant="body2" component="p">
              <strong>Sponsors:</strong> {evaluation[0]?.Sponsors}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" component="p">
              <strong>First published:</strong>{' '}
              {createdDate.toLocaleDateString('en-GB', dateOptions)}
            </Typography>
            <Typography variant="body2" component="p">
              <strong>Last modified:</strong> {updatedDate.toLocaleDateString('en-GB', dateOptions)}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8} className={styles['evaluation-content']}>
            <Box className={styles['sticky-btn']}>
              <Button
                href="/search-programs?SearchKey"
                variant="contained"
                className={styles['btn']}>
                <ArrowBackIcon /> Back to repository
              </Button>
            </Box>
            <Typography variant="h2" component="h2" mb={1}>
              Abstract
            </Typography>
            <Typography variant="body1" component="p" mb={4}>
              {evaluation[0]?.ProgramDescription}
            </Typography>
            <Typography variant="h2" component="h2" mb={1}>
              Define
            </Typography>
            {evaluation[0]?.DefineVisibility === true ? (
              <>
                <Typography variant="h3" component="h3" mb={1}>
                  Goals | What do you want to achieve?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.GoalDetails}
                </Typography>

                {goals?.length !== 0 ? (
                  <ul>{goals}</ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}

                <Typography variant="h3" component="h3" mb={1}>
                  Problems | What problem does the program address?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.ProblemDetails}
                </Typography>
                {problems?.length !== 0 ? (
                  <ul>{problems}</ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}
                <Typography variant="h3" component="h3" mb={1}>
                  Audience | Who are the program and evaluation for?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.AudienceDetails}
                </Typography>
                <Typography variant="h4" component="h4" mb={1}>
                  Program Audiences
                </Typography>
                {programAudiences?.length !== 0 ? (
                  <ul>{programAudiences}</ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}
                <Typography variant="h4" component="h4" mb={1}>
                  Evaluation Audiences
                </Typography>
                {evaluationAudiences?.length !== 0 ? (
                  <ul>{evaluationAudiences}</ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="body1" component="p" mb={4}>
                Define stage are not publicly available.
              </Typography>
            )}

            <Typography variant="h2" component="h2" mb={1} mt={4}>
              Plan
            </Typography>
            {evaluation[0]?.PlanVisibility === true ? (
              <>
                <Typography variant="h3" component="h3" mb={1}>
                  Activities | What will you do to achieve your goal(s)?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.ActivitiesDetails}
                </Typography>
                {activities?.length !== 0 ? (
                  <ul>{activities}</ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}
                <Typography variant="h3" component="h3" mb={1}>
                  Inputs | What is needed?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.InputDetails}
                </Typography>
                {inputs?.length !== 0 ? (
                  <ul>{inputs}</ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}
                <Typography variant="h3" component="h3" mb={1}>
                  Outputs | What is delivered?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.OutputDetails}
                </Typography>
                {outputs?.length !== 0 ? (
                  <ul>{outputs}</ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}
                <Typography variant="h3" component="h3" mb={1}>
                  Evaluation Priorities | What is the most important to evaluate?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.EvaluationPriorities}
                </Typography>
                <Typography variant="h3" component="h3" mb={1}>
                  Key Questions | What do you want to know?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.KeyQuestions}
                </Typography>
                <Typography variant="h3" component="h3" mb={1}>
                  Indicators | What demonstrates change is happening?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.IndicatorDetails}
                </Typography>

                {indicators[0]?.length !== 0 ? (
                  <ul>{indicators}</ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="body1" component="p" mb={4}>
                Plan stage are not publicly available.
              </Typography>
            )}

            <Typography variant="h2" component="h2" mb={1} mt={4}>
              Design
            </Typography>
            {evaluation[0]?.DesignVisibility === true ? (
              <>
                <Typography variant="h3" component="h3" mb={1}>
                  Data collection tool | Survey, interview, tracking and metrics?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.InstrumentDetails}
                </Typography>

                {instruments?.[0]?.[0]?.length !== 0 && instruments?.[0]?.length !== 0 ? (
                  <ul>
                    {uniqueInstruments.map((instrument, index) => (
                      <li key={index}>
                        {instrument !== 'Tracking and metrics' && instrument !== 'Other' ? (
                          <>
                            <a
                              href={`${process.env.PUBLIC_URL}/files/instruments/${instrument}.pdf`}
                              target="_blank"
                              rel="noreferrer">
                              {instrument}
                            </a>
                            <OpenInNewIcon fontSize="small" />
                          </>
                        ) : (
                          instrument
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}

                <Typography variant="h3" component="h3" mb={1}>
                  Evaluation Approach | Which approach will you use?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.EvaluationApproachDetails}
                </Typography>
                {evaluationApproaches?.length !== 0 ? (
                  <ul>{evaluationApproaches}</ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}
                <Typography variant="h3" component="h3" mb={1}>
                  Data collection | What data collection method will you use?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.DataCollection}
                </Typography>
                {collectionMethods?.length !== 0 ? (
                  <ul>{collectionMethods}</ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="body1" component="p" mb={4}>
                Design stage are not publicly available.
              </Typography>
            )}

            <Typography variant="h2" component="h2" mb={1} mt={4}>
              Execute
            </Typography>
            {evaluation[0]?.ExecuteVisibility === true ? (
              <>
                <Typography variant="h3" component="h3" mb={1}>
                  Consent
                </Typography>
                <Typography variant="h5" component="h5" mb={1}>
                  Do you plan to recruit evaluation participants?
                </Typography>
                <Typography variant="body" component="p" mb={1}>
                  — {evaluation[0]?.Consent?.Recruit === true ? 'Yes' : 'No'}
                </Typography>
                {evaluation[0]?.Consent?.Recruit === true && (
                  <>
                    <Typography variant="h5" component="h5" mb={1}>
                      How will you invite people to take part in your evaluation?
                    </Typography>
                    <Typography variant="body" component="p" mb={1}>
                      — {evaluation[0]?.Consent?.RecruitmentMethod}
                    </Typography>
                    <Typography variant="h5" component="h5" mb={1}>
                      How will you get informed consent from participants?
                    </Typography>
                    <Typography variant="body" component="p" mb={1}>
                      — {evaluation[0]?.Consent?.RecruitmentConsent}
                    </Typography>
                    <Typography variant="h5" component="h5" mb={1}>
                      Are you following the consent requirements outlined in the National Statement
                      on Ethical Conduct inHuman Research?
                    </Typography>
                    <Typography variant="body" component="p" mb={1}>
                      —{' '}
                      {evaluation[0]?.Consent?.ConsentRequirementsFollowed === true ? 'Yes' : 'No'}
                    </Typography>
                  </>
                )}

                <Typography variant="h3" component="h3" mb={1}>
                  Ethics
                </Typography>
                <Typography variant="h5" component="h5" mb={1}>
                  Do you plan to publish in an academic journal or use the data for academic
                  research?
                </Typography>
                <Typography variant="body" component="p" mb={1}>
                  — {evaluation[0]?.Ethics?.AcademicJournal === true ? 'Yes' : 'No'}
                </Typography>
                {evaluation[0]?.Ethics?.AcademicJournal === true && (
                  <>
                    <Typography variant="h5" component="h5" mb={1}>
                      Are you following the human ethics guidelines outlined in the National
                      Statement on Ethical Conduct inHuman Research?
                    </Typography>
                    <Typography variant="body" component="p" mb={1}>
                      —{' '}
                      {evaluation[0]?.Ethics?.HumanEthicsGuidelinesFollowed === true ? 'Yes' : 'No'}
                    </Typography>
                    <Typography variant="h5" component="h5" mb={1}>
                      Have you obtained human ethics approval through a registered Human Research
                      Ethics Committee(HREC)?
                    </Typography>
                    <Typography variant="body" component="p" mb={1}>
                      — {evaluation[0]?.Ethics?.HumanEthicsApprovalObtained === true ? 'Yes' : 'No'}
                    </Typography>

                    {evaluation[0]?.Ethics?.HumanEthicsApprovalObtained === true && (
                      <>
                        <Typography variant="h5" component="h5" mb={1}>
                          HREC approval number
                        </Typography>
                        <Typography variant="body" component="p" mb={1}>
                          — {evaluation[0]?.Ethics?.HumanEthicsApprovalNumber}
                        </Typography>
                      </>
                    )}
                  </>
                )}

                <Typography variant="h3" component="h3" mb={1}>
                  Analysis Approach | How will you analyse your data?
                </Typography>
                <Typography variant="body2" component="p" mb={1}>
                  {evaluation[0]?.AnalysisDataDetails}
                </Typography>
                {analysisData?.length !== 0 ? (
                  <ul>{analysisData}</ul>
                ) : (
                  <Typography variant="body1" component="p" mb={4}>
                    Not yet available.
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="body1" component="p" mb={4}>
                Execute stage are not publicly available.
              </Typography>
            )}
            <Typography variant="h2" component="h2" mb={2} mt={4}>
              Share
            </Typography>
            {evaluation[0]?.ShareVisibility === true ? (
              <>
                <Typography variant="h3" component="h3" mb={2}>
                  Do you have evaluation findings?
                </Typography>
                <Typography variant="body" component="p" mb={2}>
                  — {evaluation[0]?.EvaluationFindings}
                </Typography>

                {evaluation[0]?.EvaluationFindings === 'Yes' && (
                  <>
                    <Typography variant="h3" component="h3" mb={2}>
                      Executive Summary
                    </Typography>

                    <Typography variant="body1" component="p" mb={4}>
                      {evaluation[0]?.ExecutiveSummary
                        ? evaluation[0]?.ExecutiveSummary
                        : 'Not yet available.'}
                    </Typography>

                    <Typography variant="h3" component="h3" mb={2}>
                      Findings summary by indicator
                    </Typography>
                    {indicatorsSummary?.length !== 0 ? (
                      <ul>{indicatorsSummary}</ul>
                    ) : (
                      <Typography variant="body1" component="p" mb={4}>
                        Not yet available.
                      </Typography>
                    )}

                    <Typography variant="h3" component="h3" mb={2}>
                      Evaluation detail
                    </Typography>
                    <Typography variant="h4" component="h4" mb={2}>
                      Outline the program outputs (deliverables) achieved by the program [so far/end
                      date]. Do the achieved outcomes align with those you intended and specified in
                      the Plan stage? If yes, pleasedescribe how. If no, explain why.
                    </Typography>
                    <Typography variant="body" component="p" mb={2}>
                      —{' '}
                      {evaluation[0]?.ProgramOutputs
                        ? evaluation[0]?.ProgramOutputs
                        : 'Not yet available.'}
                    </Typography>
                    <Typography variant="h4" component="h4" mb={2}>
                      Outline the program outcomes (changes) achieved by the program [so far/end
                      date]. Do theachieved outcomes align with those you intended and specified in
                      the Plan stage? If yes, please describe how. If no, explain why.
                    </Typography>
                    <Typography variant="body" component="p" mb={2}>
                      —{' '}
                      {evaluation[0]?.ProgramOutcomes
                        ? evaluation[0]?.ProgramOutcomes
                        : 'Not yet available.'}
                    </Typography>
                    <Typography variant="h4" component="h4" mb={2}>
                      Has your program addressed the problem(s) you intended to address? If so,
                      describe how. If no,explain why.
                    </Typography>
                    <Typography variant="body" component="p" mb={2}>
                      —{' '}
                      {evaluation[0]?.ProgramProblems
                        ? evaluation[0]?.ProgramProblems
                        : 'Not yet available.'}
                    </Typography>
                    <Typography variant="h4" component="h4" mb={2}>
                      What ongoing impact will the program have? Describe how your program will
                      continue to achievethe intended outcomes/impact?
                    </Typography>
                    <Typography variant="body" component="p" mb={2}>
                      —{' '}
                      {evaluation[0]?.ProgramImpact
                        ? evaluation[0]?.ProgramImpact
                        : 'Not yet available.'}
                    </Typography>
                    <Typography variant="h4" component="h4" mb={2}>
                      Did the program result in any unexpected benefits? If yes, explain what and
                      how.
                    </Typography>
                    <Typography variant="body" component="p" mb={2}>
                      —{' '}
                      {evaluation[0]?.ProgramBenefits
                        ? evaluation[0]?.ProgramBenefits
                        : 'Not yet available.'}
                    </Typography>
                    <Typography variant="h4" component="h4" mb={2}>
                      Did the program result in any unintended consequences? If yes, explain what
                      and how.
                    </Typography>
                    <Typography variant="body" component="p" mb={2}>
                      —{' '}
                      {evaluation[0]?.ProgramResults
                        ? evaluation[0]?.ProgramResults
                        : 'Not yet available.'}
                    </Typography>

                    <Typography variant="h3" component="h3" mb={2}>
                      Lesson Learnt
                    </Typography>
                    <Typography variant="h4" component="h4" mb={2}>
                      What lessons can you draw with the insights from the findings? What can be
                      improved, and how?
                    </Typography>
                    <Typography variant="body" component="p" mb={2}>
                      —{' '}
                      {evaluation[0]?.LessonsLearnt
                        ? evaluation[0]?.LessonsLearnt
                        : 'Not yet available.'}
                    </Typography>

                    <Typography variant="h3" component="h3" mb={2}>
                      Conclusions
                    </Typography>
                    <Typography variant="h4" component="h4" mb={2}>
                      What conclusions can you draw from the evaluation findings? What can we learn
                      from them?
                    </Typography>
                    <Typography variant="body" component="p" mb={2}>
                      —{' '}
                      {evaluation[0]?.Conclusions
                        ? evaluation[0]?.Conclusions
                        : 'Not yet available.'}
                    </Typography>

                    <Typography variant="h3" component="h3" mb={2}>
                      Recommendations
                    </Typography>
                    <Typography variant="h4" component="h4" mb={2}>
                      Make recommendations based on your evaluation. What should we do with the
                      insights from thefindings? Make your recommendations action-oriented and
                      feasible. Arrange them in order ofimportance.
                    </Typography>
                    <Typography variant="body" component="p" mb={2}>
                      —{' '}
                      {evaluation[0]?.Recommendations
                        ? evaluation[0]?.Recommendations
                        : 'Not yet available.'}
                    </Typography>
                  </>
                )}
              </>
            ) : (
              <Typography variant="body1" component="p" mb={4}>
                Share stage is not publicly available.
              </Typography>
            )}

            <Typography variant="h3" component="h3" mb={2}>
              Download Reports
            </Typography>
            <List>
              {attachments &&
                attachments?.map((file, index) => {
                  return (
                    <ListItem key={index}>
                      <Link
                        href={file.Link}
                        download={file.FileName}
                        target="_blank"
                        rel="noreferrer">
                        {file.FileName}
                      </Link>
                    </ListItem>
                  );
                })}

              {attachments?.length === 0 && (
                <Typography variant="body1" component="p" mb={4}>
                  There are no available reports.
                </Typography>
              )}
            </List>
          </Grid>
          <Grid item xs={12} sm={4} className={styles['evaluation-sidebar']}>
            <img src={evaluation[0]?.Avatar ? evaluation[0]?.Avatar : defaultAvatar} />
            <Typography variant="h3" component="h3">
              {evaluation[0]?.Author}
            </Typography>
            <Box className={styles['social-media-accounts']}>
              {evaluation[0]?.Website && (
                <Button
                  variant="link"
                  color="primary"
                  startIcon={<LanguageIcon />}
                  href={evaluation[0]?.Website}
                  size="large"
                  target="_blank"
                />
              )}
              {evaluation[0]?.FacebookUrl && (
                <Button
                  variant="link"
                  color="primary"
                  startIcon={<FacebookRoundedIcon />}
                  href={evaluation[0]?.FacebookUrl}
                  size="large"
                  target="_blank"
                />
              )}
              {evaluation[0]?.TwitterUrl && (
                <Button
                  variant="link"
                  color="primary"
                  startIcon={<TwitterIcon />}
                  href={evaluation[0]?.TwitterUrl}
                  size="large"
                  target="_blank"
                />
              )}
              {evaluation[0]?.InstagramUrl && (
                <Button
                  variant="link"
                  color="primary"
                  startIcon={<InstagramIcon />}
                  href={evaluation[0]?.InstagramUrl}
                  size="large"
                  target="_blank"
                />
              )}
              {evaluation[0]?.LinkedinUrl && (
                <Button
                  variant="link"
                  color="primary"
                  startIcon={<LinkedInIcon />}
                  href={evaluation[0]?.LinkedinUrl}
                  size="large"
                  target="_blank"
                />
              )}
            </Box>

            <Button
              variant="contained"
              component="a"
              rel="noopener noreferrer"
              href={`mailto:${evaluation[0]?.Email}`}>
              Contact us
            </Button>
            <Typography variant="body" component="p" mt={4}>
              Disclaimer: The STEM Equity Evaluation Portal is a resource and database offered to
              the public by the Office of the Women in STEM Ambassador (the <strong>Office</strong>)
              with support from the Australian Government. The Office is not a publisher, but rather
              collects, indexes and archives equity program evaluations produced and published by
              other organisations. Evaluations on this database are not peer reviewed and the
              presence of any evaluation does not imply an endorsement of, or concurrence with, the
              contents by the Office or the Australian Government.
            </Typography>
            <Box className={styles['related-program']}>
              <Typography variant="h3" component="h3">
                Related programs
              </Typography>
              {relatedPrograms?.length !== 0 ? (
                relatedPrograms?.slice(0, 3).map((related, index) => {
                  const goals = related.Goals.map((item) => item.Goal);
                  return (
                    <EvaluationCard
                      key={index}
                      organisation={related.Organisation}
                      title={related?.ProgramName}
                      outcomes={goals.join('; ')}
                      description={related?.ProgramDescription}
                      readmore
                      onClick={() => navigate(`/program/${related.UID}`)}
                    />
                  );
                })
              ) : (
                <Typography variant="body" component="p" mt={-3}>
                  There are no related programs.
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={styles['btn-actions']}>
              <Box className={styles['btn-group']}>
                {/* {evaluation[0]?.Attachments !== undefined && (
                  <Button
                    variant="contained"
                    // onClick={downloadDocuments('evaluation-reports.zip', attachmentUrls)}
                    onClick={downloadAll}>
                    Download all documents
                  </Button>
                )} */}

                {/* <Button
                  variant="contained"
                  onClick={downloadDocuments('evaluation-reports.zip', attachmentUrls)}>
                  Download text documents
                </Button> */}
                <Button
                  variant="contained"
                  onClick={handleBookmark(evaluation[0]?.UID)}
                  disabled={isFound}>
                  {isFound ? 'Added to Bookmarks' : 'Bookmark Evaluation'}
                </Button>
                <Button variant="contained" href="/search-programs?SearchKey">
                  Find similar programs
                </Button>
              </Box>

              <Button variant="contained" onClick={goToNextProgram}>
                Next program <ArrowForwardIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default EvaluationProgram;
