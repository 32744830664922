import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = () => {
  return (
    <Container>
      <Grid container spacing={4} className={styles['main-content']}>
        <Grid item sm={12}>
          <Typography variant="h1" component="h1" mb={2}>
            Terms and Conditions
          </Typography>
          <Typography variant="body" component="p">
            Please read these Terms and Conditions completely prior to using the STEM Equity
            Evaluation Portal (the Portal), a website operated and managed by the Office of the
            Women in STEM Ambassador (the Office). The Office is funded by an Australian Government
            Grant and is operated at the University of New South Wales, Sydney, Australia (
            <strong>UNSW</strong>).
          </Typography>
          <Typography variant="body" component="p">
            The Office addresses gender inequities in science, technology, engineering and
            mathematics (STEM) by mobilising Australia&apos;s business leaders, educators, parents
            and carers, and policymakers to remove barriers to the participation of underrepresented 
            groups in STEM. The Office created the Portal in response to recommendations from
            the Women in STEM Decadal Plan for a standardised national evaluation framework that
            guides evaluation efforts across all existing and future STEM equity initiatives
            in Australia.
          </Typography>
          <Typography variant="body" component="p">
            In these Terms and Conditions, &apos;we&apos; and &apos;our&apos; means the Office and
            University of New South Wales.
          </Typography>
          <Typography variant="body" component="p">
            By accessing this Portal, and content made available through it, you agree to these
            Terms and Conditions.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            Conduct
          </Typography>
          <Typography variant="body" component="p">
            You must not do any act that we would deem to be inappropriate, is unlawful or is
            prohibited by any laws applicable to our Portal, including but not limited to:
          </Typography>
          <ul className={styles['alphabet-bullets']}>
            <li>
              any act that would constitute a breach of either the privacy (including uploading
              private or personal information without an individual&apos;s consent) or any other of
              the legal rights of individuals;
            </li>
            <li>using this Portal to defame or libel us, our employees or other individuals;</li>
            <li>
              uploading files that contain viruses that may cause damage to our property or the
              property of other individuals;
            </li>
            <li>
              posting or transmitting to this Portal any non-authorised material including, but not
              limited to, material that is, in our opinion, likely to cause annoyance, or which is
              defamatory, racist, obscene, threatening, pornographic or otherwise or which is
              detrimental to or in violation of our systems or a third party&apos;s systems or
              network security;
            </li>
            <li>using the Portal for private or commercial gain. </li>
          </ul>
          <Typography variant="body" component="p">
            If we allow you to post any information to our Portal, we have the right to take down
            this information at our sole discretion and without notice.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            Disclaimer
          </Typography>
          <Typography variant="body" component="p">
            The information on our Portal is not comprehensive and is intended to provide a summary
            of the subject matter covered and does not constitute professional advice of any kind.
          </Typography>
          <Typography variant="body" component="p">
            Before acting on any information provided, obtain advice from a suitably qualified
            professional.
          </Typography>
          <Typography variant="body" component="p">
            While we use all reasonable attempts to ensure the accuracy and completeness of the
            information on our Portal, to the extent permitted by law, including the Australian
            Consumer Law, we make no warranty regarding the information on this Portal. You should
            monitor any changes to the information contained on this Portal.
          </Typography>
          <Typography variant="body" component="p">
            We are not liable to you or anyone else if interference with or damage to your computer
            systems occurs in connection with the use of this Portal or a linked website. You must
            take your own precautions to ensure that whatever you select for your use from our
            Portal is free of viruses or anything else that may interfere with or damage the
            operations of your computer systems.
          </Typography>
          <Typography variant="body" component="p">
            We may, from time to time and without notice, change or add to the Portal (including the
            Terms) or the information, products or services described in it. However, we do not
            undertake to keep the Portal updated. We are not liable to you or anyone else if errors
            occur in the information on the Portal or if that information is not up-to-date.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            Copyright
          </Typography>
          <Typography variant="body" component="p">
            Unless otherwise indicated, we own or license from third parties all rights, title and
            interest (including copyright, trademarks and other intellectual property rights) in
            this Portal and in all of the material (including all text, graphics, logos, audio and
            software) made available on this Portal (<strong>Content</strong>).
          </Typography>
          <Typography variant="body" component="p">
            Your use of this Portal and use of and access to any Content does not grant or transfer
            any rights, title or interest to you in relation to this Portal or the Content. However,
            we do grant you a licence to access the Portal, view the Content and provide links to
            the Portal (on the condition that the source is attributed) on the terms set out in
            these Terms and Conditions and, where applicable, as expressly authorised by The Office
            and/or our third-party licensors.
          </Typography>
          <Typography variant="body" component="p">
            Any reproduction or redistribution of this Portal or the Content is prohibited and may
            result in civil and criminal penalties. In addition, you must not copy the Content to
            any other server, location or support for publication, reproduction or distribution is
            expressly prohibited.
          </Typography>
          <Typography variant="body" component="p">
            All other use, copying or reproduction of this Portal, the Content or any part of it is
            prohibited, except to the extent permitted by law.
          </Typography>
          <Typography variant="body" component="p">
            The names of actual companies and products mentioned on this Portal may be the
            trademarks of their respective owners.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            Information disclosure
          </Typography>
          <Typography variant="body" component="p">
            The <a href="/privacy-policy">Privacy Statement</a> applies to your use of the Portal.
            As stated, we reserve the right at all times to disclose any information as necessary to
            satisfy any applicable law, regulation, legal process or governmental request, or to
            edit, refuse to post or to remove any information or materials, in whole or in part, at
            our sole discretion.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            Links to other websites
          </Typography>
          <Typography variant="body" component="p">
            Our Portal may contain links to sites and online resources operated by third parties.
            Those links are provided for convenience and may not remain current or be maintained. We
            do not endorse and are not responsible for the content of those linked sites and have no
            control over those websites and therefore assume no liability for your use of those
            sites.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            No commercial use
          </Typography>
          <Typography variant="body" component="p">
            This Portal is for your personal, non-commercial use only. You may not modify, copy,
            distribute, transmit, display, perform, reproduce, publish, license, commercially
            exploit, create derivative works from, transfer, or sell any Content, software,
            products, or services contained within this Portal. You may not use this Portal, or any
            of its Content, to further any commercial purpose, including any advertising or
            advertising revenue generation activity on your own website.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            Warranties and disclaimers
          </Typography>
          <Typography variant="body" component="p">
            To the maximum extent permitted by law, including the Australian Consumer Law, we make
            no warranties or representations about this Portal or the content, including but not
            limited to warranties or representations that they will be complete, accurate or
            up-to-date, that access will be uninterrupted or error-free or free from viruses, or
            that this Portal will be secure.
          </Typography>
          <Typography variant="body" component="p">
            We reserve the right to restrict, suspend or terminate without notice your access to
            this Portal, any content, or any feature of this Portal at any time without notice and
            we will not be responsible for any loss, cost, damage, or liability that may arise as a
            result.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            Liability
          </Typography>
          <Typography variant="body" component="p">
            To the maximum extent permitted by law, including the Australian Consumer Law, in no
            event shall we be liable for any direct and indirect loss, damage or expense –
            irrespective of the manner in which it occurs – which may be suffered due to your use of
            our Portal and/or the information or materials contained on it, or as a result of the
            inaccessibility of this Portal and/or the fact that certain information or materials
            contained on it are incorrect, incomplete or not up-to-date.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            Termination
          </Typography>
          <Typography variant="body" component="p">
            We reserve the right to terminate your access to the Portal and any related services, at
            any time and without any advance notice.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            Governing law
          </Typography>
          <Typography variant="body" component="p">
            Your use of the Portal and these Terms and Conditions are governed by the laws of the
            Australian Capital Territory, Australia (<strong>ACT</strong>) and you submit to the
            non-exclusive jurisdiction of courts exercising jurisdiction in the ACT.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            Changes to these Terms and Conditions
          </Typography>
          <Typography variant="body" component="p">
            The Office reserves the right to modify these Terms and Conditions at any time. We do so
            by posting and drawing attention to the updated terms on this site. Your decision to
            continue to visit and make use of the site after such changes have been made constitutes
            your formal acceptance of the new Terms of Use.
          </Typography>
          <Typography variant="body" component="p">
            Any rights not expressly granted in these terms are reserved.
          </Typography>
          <Typography variant="h3" component="h2" mb={2}>
            Contact us
          </Typography>
          <Typography variant="body" component="p">
            If you have any questions about these Terms and Conditions, please feel free to contact
            the Office during business hours Monday to Friday by one of the following methods:
          </Typography>
          <ul className={styles['no-bullets']}>
            <li>
              <span>Email:</span>
              <a href="mailto:eval.womeninstem@unsw.edu.au">eval.womeninstem@unsw.edu.au</a>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TermsAndConditions;
