import React, { useContext, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material/';
import Search from '../../components/Search';
import Discover from '../../layouts/Discover';
import profLisa from '../../assets/images/ProfLisaHarvey-Smith.jpg';
import annaMaria from '../../assets/images/Anna-Maria-Arabia.jpeg';
import cathyFoley from '../../assets/images/Cathy-Foley.png';
import jenineBeekhuyzen from '../../assets/images/Jenine-Beekhuyzen.jpeg';
import wafaElAdhami from '../../assets/images/Wafa-El-Adhami.jpg';
import homepageBannerMobile from '../../assets/images/homepage-banner-mobile.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import classes from './Home.module.scss';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { AuthContext } from '../../services/context/AuthProvider';
import Modal from '../../components/Modal';
import LoginModal from '../../components/Modal/LoginModal';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const Home = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [query, setQuery] = useState('');

  const [openModal, setOpenModal] = useState(false);
  // const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <>
      <Box className={classes['banner-wrapper']}>
        <img
          className={classes['mobile-banner']}
          src={homepageBannerMobile}
          alt="Evaluation portal"
        />
        <Container maxWidth="lg">
          <Grid container spacing={4} className={classes['banner-container']}>
            <Grid item sm={12} md={6}>
              <Typography variant="h2" component="h1">
                STEM Equity Evaluation Portal
              </Typography>
              <Typography variant="body2" component="p">
                Understanding what works to improve equity in science, technology, engineering, and
                mathematics (STEM).
              </Typography>
            </Grid>
            {/* <Grid item sm={12} md={6}>
              <img src={homepageBanner} />
            </Grid> */}
          </Grid>
          <Grid container className={classes['search-wrapper']}>
            <Grid item sm={12}>
              <Search query={query} setQuery={setQuery} />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Grid container maxWidth="100%" className={classes['benefits-wrapper']}>
        <Grid item sm={12} md={6} className={classes['left-bg']}>
          <Typography variant="h3" component="h3">
            Search and discover evaluations
          </Typography>
          <Typography variant="body2" component="p">
            Search the repository of program evaluations to:
          </Typography>
          <List dense component="ul">
            <ListItem>
              <ListItemText primary="Identify equity programs and evaluations of interest" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Discover what works to improve equity in STEM" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Connect with people leading and evaluating equity programs" />
            </ListItem>
          </List>
          <Typography variant="body2" component="p">
            Use the search tool to find, filter and sort program evaluations and people of interest.
          </Typography>
          <Button
            variant="outlined"
            href="/search"
            color="primary"
            size="medium"
            endIcon={<ArrowForwardIcon />}
            className={classes['benefit-btn']}
            disableElevation
            sx={{
              mt: 3
            }}>
            Go to search tool
          </Button>
        </Grid>
        <Grid item sm={12} md={6} className={classes['right-bg']} sx={{ paddingLeft: '1rem' }}>
          <Typography variant="h3" component="h3">
            Evaluate your programs
          </Typography>
          <Typography variant="body2" component="p">
            Use the evaluation planning and reporting tool to:
          </Typography>
          <List dense component="ul">
            <ListItem>
              <ListItemText primary="Simply and easily evaluate your equity programs" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Share your evaluations publicly for others to learn from" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Contribute evidence of what works to improve equity in STEM" />
            </ListItem>
          </List>
          <Typography variant="body2" component="p">
            Use the evaluation planning and reporting tool to easily evaluate your programs.
          </Typography>
          {isAuthenticated ? (
            <Button
              variant="outlined"
              to="/evaluation"
              component={Link}
              color="secondary"
              size="medium"
              endIcon={<ArrowForwardIcon />}
              className={classes['benefit-btn']}
              disableElevation
              sx={{
                mt: 3
              }}>
              Evaluate now
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              endIcon={<ArrowForwardIcon />}
              className={classes['benefit-btn']}
              disableElevation
              data-modal="signin-option"
              // onClick={handleOpen}
              sx={{
                mt: 3
              }}>
              Evaluate now
            </Button>
          )}
        </Grid>
      </Grid>
      <Container>
        <Grid container className={classes['testimonials-wrapper']}>
          <Grid item sm={12}>
            <Swiper
              spaceBetween={30}
              loop={true}
              centeredSlides={true}
              autoplay={{
                delay: 15000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
              }}
              pagination={{
                clickable: true
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className={classes.carousel}>
              <SwiperSlide className={classes['swiper-slide']}>
                <Avatar
                  className={classes.avatar}
                  alt="Lisa"
                  src={profLisa}
                  sx={{ width: 80, height: 80 }}
                />
                <Typography variant="h3" component="h3" className={classes.title}>
                  The value of the STEM Equity Evaluation Portal to Professor Lisa Harvey-Smith,
                  Australian Government’s Women in STEM Ambassador
                </Typography>
                <Typography variant="body2" component="p" className={classes.quote}>
                  “The STEM Equity Evaluation Portal will drive a culture of evaluation and ensure
                  that efforts and investments in this area lead to tangible benefits for all
                  genders in the STEM sector.”
                </Typography>
              </SwiperSlide>
              <SwiperSlide className={classes['swiper-slide']}>
                <Avatar
                  className={classes.avatar}
                  alt="Lisa"
                  src={annaMaria}
                  sx={{ width: 80, height: 80 }}
                />
                <Typography variant="h3" component="h3" className={classes.title}>
                  The value of the STEM Equity Evaluation Portal to Anna-Maria Arabia, Chief
                  Executive, Australian Academy of Science
                </Typography>
                <Typography variant="body2" component="p" className={classes.quote}>
                  “The practical evaluation tools and repository of evaluations in the STEM Equity
                  Evaluation Portal are a source of invaluable evidence enabling us to build on
                  success and step closer to gender equity in STEM.”
                </Typography>
              </SwiperSlide>
              <SwiperSlide className={classes['swiper-slide']}>
                <Avatar
                  className={classes.avatar}
                  alt="Lisa"
                  src={jenineBeekhuyzen}
                  sx={{ width: 80, height: 80 }}
                />
                <Typography variant="h3" component="h3" className={classes.title}>
                  The value of the STEM Equity Evaluation Portal to Dr Jenine Beekhuyzen, Founder
                  and CEO, Tech Girls Movement Foundation
                </Typography>
                <Typography variant="body2" component="p" className={classes.quote}>
                  “The STEM Equity Evaluation Portal takes the guesswork out of a VIP task that is
                  often forgotten or not prioritised - evaluation.”
                </Typography>
              </SwiperSlide>
              <SwiperSlide className={classes['swiper-slide']}>
                <Avatar
                  className={classes.avatar}
                  alt="Lisa"
                  src={cathyFoley}
                  sx={{ width: 80, height: 80 }}
                />
                <Typography variant="h3" component="h3" className={classes.title}>
                  The value of the STEM Equity Evaluation Portal to Dr Cathy Foley, Australia’s
                  Chief Scientist
                </Typography>
                <Typography variant="body2" component="p" className={classes.quote}>
                  “Diverse participation in STEM is a question of equity, as well as being crucial
                  for Australia’s ambition and prosperity. The STEM Equity Evaluation Portal is a
                  welcome initiative that will allow us to measure outcomes and test what works.”
                </Typography>
              </SwiperSlide>
              <SwiperSlide className={classes['swiper-slide']}>
                <Avatar
                  className={classes.avatar}
                  alt="Lisa"
                  src={wafaElAdhami}
                  sx={{ width: 80, height: 80 }}
                />
                <Typography variant="h3" component="h3" className={classes.title}>
                  The value of the STEM Equity Evaluation Portal to Dr Wafa El-Adhami, Chief
                  Executive Officer, Science in Australia Gender Equity Ltd
                </Typography>
                <Typography variant="body2" component="p" className={classes.quote}>
                  “By making it easy to find examples of what does and doesn’t work, and why,
                  platforms like the STEM Equity Evaluation Portal help us make wiser,
                  evidence-informed investments for equity, diversity and inclusion.”
                </Typography>
              </SwiperSlide>
            </Swiper>
          </Grid>
        </Grid>
        <Discover />
        <Outlet />
      </Container>
      <Modal size="md" open={openModal} onClose={handleClose} onClick={handleClose}>
        <LoginModal />
      </Modal>
    </>
  );
};

export default Home;
