import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const EVALUATIONS_URL = `${process.env.REACT_APP_API_URL}/evaluations`;
const PROGRAM_SCALE_URL = `${process.env.REACT_APP_API_URL}/reference-data?dataSet=ProgramScales`;

const initialState = {
  programOverview: [],
  status: 'idle',
  error: null
};

export const addProgramOverview = createAsyncThunk(
  'programOverview/addProgramOverview',
  async (initialPost) => {
    try {
      const response = await axios.post(EVALUATIONS_URL, initialPost);
      return response.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const fetchProgramScales = createAsyncThunk(
  'programOverview/fetchProgramScales',
  async () => {
    try {
      const response = await axios.get(PROGRAM_SCALE_URL);
      return response.data;
    } catch (err) {
      return err.message;
    }
  }
);

export const programOverviewSlice = createSlice({
  name: 'programOverview',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addProgramOverview.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addProgramOverview.fulfilled, (state, action) => {
        action.payload.programOverview = {
          ProgramName: action.payload.programName,
          ProgramScale: action.payload.programScale,
          ProgramSponsors: action.payload.programSponsors,
          ProgramDescription: action.payload.programDescription
        };
        console.log(action.payload);
        state.programOverview.push(action.payload);

        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.problems = action.payload;
        // return action.payload;
      })
      .addCase(fetchProgramScales.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProgramScales.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.programScales = action.payload;
        // return action.payload;
      });
  }
});

// export const selectAllProblems = (state) => state.problems.problems;
// export const selectAllGoals = (state) => state.problems.goals;
// export const selectAllProgramAudiences = (state) => state.problems.programAudiences;
// export const selectAllEvaluationAudiences = (state) => state.problems.evaluationAudiences;
export const getProgramScales = (state) => state.programOverview.programScales;
export const getStatus = (state) => state.programOverview.status;
export const getError = (state) => state.programOverview.error;

export default programOverviewSlice.reducer;
