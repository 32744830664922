import React from 'react';
import { List, ListItemButton } from '@mui/material';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import classes from './Footer.module.scss';

function SocialMedia() {
  return (
    <List
      classes={{
        root: classes['social-media']
      }}>
      <ListItemButton
        component="a"
        alignItems="center"
        href="https://twitter.com/WomenInSTEMAu"
        target="_blank">
        <TwitterIcon color="primary" />
      </ListItemButton>
      <ListItemButton
        component="a"
        alignItems="center"
        href="https://www.linkedin.com/company/womeninstemau/"
        target="_blank">
        <LinkedInIcon color="primary" />
      </ListItemButton>
      <ListItemButton
        component="a"
        alignItems="center"
        href="https://www.facebook.com/WomenInSTEMAmbassador"
        target="_blank">
        <FacebookOutlinedIcon color="primary" />
      </ListItemButton>
      <ListItemButton
        component="a"
        alignItems="center"
        href="https://www.instagram.com/womeninstemau/"
        target="_blank">
        <InstagramIcon color="primary" />
      </ListItemButton>
      <ListItemButton
        component="a"
        alignItems="center"
        href="https://www.youtube.com/channel/UCvDQ_bjiQRCa4nBGycfunaA"
        target="_blank">
        <YouTubeIcon color="primary" />
      </ListItemButton>
    </List>
  );
}

export default SocialMedia;
