import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const CardItem = ({ title, subtitle, content, url, className }) => {
  return (
    <Card className={className}>
      <CardContent>
        <Typography variant="h3" component="h3" color="text.primary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" component="h4">
          {subtitle}
        </Typography>
        <Typography variant="body2" component="p" color="text.secondary" sx={{ mt: 2 }}>
          {content}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" component={Link} to={url} reloadDocument>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

CardItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string
};

export default CardItem;
