import React from 'react';
import PropTypes from 'prop-types';
import styles from './FileUpload.module.scss';
import { Box, CircularProgress, IconButton, Link, ListItem } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';

const FileItem = ({ key, fileType, file, deleteFile }) => {
  return (
    <>
      <ListItem className="file-item" key={key}>
        <ArticleIcon />
        <Link href={file.Link} download target="_blank" rel="noreferrer">
          {file.FileName}
        </Link>
        <Box className={styles['actions']}>
          {file.isUploading ? (
            <CircularProgress size={20} />
          ) : (
            <IconButton onClick={() => deleteFile(fileType, file.FileContext, file.ObjectId)}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </ListItem>
    </>
  );
};

FileItem.propTypes = {
  key: PropTypes.string,
  fileType: PropTypes.string,
  file: PropTypes.object,
  deleteFile: PropTypes.func
};

export default FileItem;
